import React from 'react';
import {MyModal, MyUI} from '../../../../Components'
import {CButton} from '../../../../../components/MyCore'
import {HAuth, HUtils, HText} from '../../../../Helpers'
import './styles/sc_card_address.css'

class SC_CartAddress extends React.Component{
    constructor(props){
        super(props);
        this.state = {            
            form: {
                CustomerName: null,
                CustomerAddress: null,
                CustomerEmail: null,
                CustomerPhone: null,
                CustomerDeliveryId: null,
                Note: null,
                AddDelivery: false,  
                DeliveryTimePlanId: null,
            }
        }
        // if(this.props.data){
        //     this.state.form = this.props.data;
        // }
    }
    componentDidMount(){
        const {data} = this.props
        if(data){
            this.setState({
                form: data
            })
        }
    }
    shouldComponentUpdate(nextProps){
        if(this.state.form !== nextProps.data){
            this.setState({
                form: nextProps.data
            })
        }
        return true
    }
    getListFromOptions = (key) =>{
        const {options} = this.props;
        return HUtils.Obj.get(options, key, null)
    }
    onChangeAddress=({item,isModal,fieldName, value}={})=>{
        let {form} = this.state;
        const {fnList} = this.props;
        if(item){
            form = {
                CustomerName: item.Receiver,
                CustomerAddress: item.Address,
                CustomerEmail: item.Email,
                CustomerPhone: item.Phone,
                CustomerDeliveryId: item.Id
            }
        }
        else if(fieldName && value){
            form[fieldName] = value
        }
        this.setState({
            form: {...form}  
        },()=>{
            if(fnList){
                if(item){
                    let _keys = Object.keys(form);
                    for(let i=0; i<_keys.length; i++){
                        fnList.onChangeForm(_keys[i], form[_keys[i]])
                    }
                }
                else if(fieldName && value!=null){
                    fnList.onChangeForm(fieldName, value)
                }
            }
            isModal && MyModal.Helpers.hide()
        })
    }
    renderAddressBook = () =>{
        let _ui = [];
        const addressBook = this.getListFromOptions("addressBook")
        if(addressBook && addressBook.length>0){
            for(let i=0; i<addressBook.length; i++){
                const _item = addressBook[i]
                _ui.push(
                    <div key={i} className="item-select" onClick={()=>this.onChangeAddress({item: _item, isModal: true})}>{_item.Receiver} - {_item.Phone} - {_item.Address}</div>
                )
            }
        }
        return (
            <div>
                {_ui}
            </div>
        );
    }
    renderHeader = () =>{
        const {renderHeader, config} = this.props;
        const {form} = this.state;
        const _isLoggedIn = HAuth.isLoggedIn();
        const _showSignIn = HUtils.Obj.get(config, "showSignIn", true);
        const _showAddNewAddress = HUtils.Obj.get(config, "showAddNewAddress", true);
        const _showSelectAvailableAddress = HUtils.Obj.get(config, "showSelectAvailableAddress", true);
        if(renderHeader){
            return(
                <div className="address-grid">{renderHeader()}</div>
            )
        }
        return(
            <div className="address-grid">
                {
                    _isLoggedIn == true &&
                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                        {
                            _showAddNewAddress &&
                            <div className="address-save" onClick={()=>this.onChangeAddress({fieldName: "AddDelivery", value: !form.AddDelivery})}>
                                <MyUI type="input_check" defaultValue={form.AddDelivery}/>
                                <div>{HText.get("text_save_new_address")}</div>
                            </div>
                        }
                        {
                            _showSelectAvailableAddress &&
                            <div className={_showAddNewAddress ? "dFaCjEw100" : "dFaCjCw100"}>
                                <CButton className="btn-select-address" onClick={()=>{
                                    MyModal.Helpers.showComponent(this.renderAddressBook(),{
                                        title: HText.get("text_modal_choose_address_title"),
                                        closeButton: true,
                                        size: "lg"
                                    })
                                }}>{HText.get("text_choose_address")}</CButton>
                            </div>
                        }
                    </div>
                }
                {
                    (_isLoggedIn == false && _showSignIn) &&
                    <div className="dFaCjCtaCw100">
                        <MyUI type="sc_login" uiType={2}/>
                    </div>
                }
            </div>
        )
    }
    renderFormShippingDelivery = () =>{
        const {form} = this.state;
        return(
            <div className="address-grid">
                <div className="inputs" style={{whiteSpace: 'normal'}}>
                    <label htmlFor="ShippingAddress_Name" style={{textAlign:"left"}}>{HText.get("text_form_address_name")}: <span className="required">*</span></label>
                    <input className="text-box single-line" type="text" value={form.CustomerName || ""} onChange={(e)=>this.onChangeAddress({fieldName: "CustomerName", value: e.currentTarget.value})}/>
                </div>
                <div className="inputs" style={{whiteSpace: 'normal'}}>
                    <label htmlFor="ShippingAddress_Address" style={{textAlign:"left"}}>{HText.get("text_form_address_address")}: <span className="required">*</span></label>
                    <input className="text-box single-line" type="text" value={form.CustomerAddress || ""} onChange={(e)=>this.onChangeAddress({fieldName: "CustomerAddress", value: e.currentTarget.value})}/>
                </div>
                <div className="inputs" style={{whiteSpace: 'normal'}}>
                    <label htmlFor="ShippingAddress_Email" style={{textAlign:"left"}}>{HText.get("text_form_address_email")}:</label>
                    <input className="text-box single-line" type="text" value={form.CustomerEmail || ""} onChange={(e)=>this.onChangeAddress({fieldName: "CustomerEmail", value: e.currentTarget.value})}/>
                </div>
                <div className="inputs" style={{whiteSpace: 'normal'}}>
                    <label htmlFor="ShippingAddress_Phone" style={{textAlign:"left"}}>{HText.get("text_form_address_phone")}: <span className="required">*</span></label>
                    <input className="text-box single-line"  type="text" value={form.CustomerPhone || ""} onChange={(e)=>this.onChangeAddress({fieldName: "CustomerPhone", value: e.currentTarget.value})}/>
                </div>
                <div className="inputs" style={{whiteSpace: 'normal'}}>
                    <label htmlFor="ShippingAddress_Note" style={{textAlign:"left"}}>{HText.get("text_form_address_note")}:</label>
                    <textarea style={{minHeight: 50, outline: 'none'}} className="text-box single-line"  type="text" value={form.Note || ""} onChange={(e)=>this.onChangeAddress({fieldName: "Note", value: e.currentTarget.value})}></textarea>
                </div>
                {this.renderExpectedDeliverTime()}
            </div>
        )
    }
    renderExpectedDeliverTime = () =>{
        const {form} = this.state;
        const expectedTimeDeliveryList = this.getListFromOptions("expectedTimeDeliveryList")
        if(expectedTimeDeliveryList && expectedTimeDeliveryList.length>0){
            return(
                <div className="inputs" style={{whiteSpace: 'normal'}}>                       
                    <label htmlFor="ShippingAddress_Time" style={{textAlign:"left"}}>{HText.get("text_form_address_time")}:</label>
                    <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                    {
                        expectedTimeDeliveryList.map((v, i)=>{
                            return (
                                <MyUI 
                                    type="sc_input_radio"
                                    label={v.Text}
                                    value={form.DeliveryTimePlanId === v.Value}
                                    onChange={()=>this.onChangeAddress({fieldName: "DeliveryTimePlanId", value: v.Value})}
                                />
                                // <div key={i} style={{display: "flex", alignItems: "center", justifyContent: "flex-start", width: "48%", border: "1px solid #e9e9e9", padding: "5px 2px", margin: "2px"}} onClick={()=>this.onChangeAddress({fieldName: "DeliveryTimePlanId", value: v.Value})}>
                                //     <MyUI type="input_radio"  style={{position: "relative", marginBottom: 5, cursor: "pointer"}} defaultValue={form.DeliveryTimePlanId === v.Value} onChange={(e)=>this.onChangeAddress({fieldName: "DeliveryTimePlanId", value: v.Value})}/>
                                //     <label style={{ marginLeft: 5, fontWeight: "normal", cursor: "pointer"}}>{v.Text}</label>
                                // </div>
                            )
                        })
                    }
                    </div>
                </div>
            )
        }
    }
    render(){
        const title = HUtils.Obj.get(this.props, "title", HText.get("text_shipping_address"))
        return(
            <div className="shipping-addresses" id="shipping-addresses-form">
                <div className="section select-shipping-address">
                    <div className="title">
                        <strong>{title}</strong>
                    </div>
                    {this.renderHeader()}
                </div>
                {this.renderFormShippingDelivery()}
            </div>
        )
    }
}
export default SC_CartAddress