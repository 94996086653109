import React from 'react'
import {MyUI, MyLoadingApp} from '../../../../Components'
import {HApiAxios, HUtils} from '../../../../Helpers'
import HGA from '../../../../../helpers/GA'

class HomeNewComing extends React.Component{
  constructor(props){
    super(props);
    this.state={
      tabIndex: 0,
      tabList: [],
      data: []
    }
  }
  componentDidMount(){
    this._getData()
    const _tabList = this._getConfigByKey("TabItem", []);
    if(_tabList.length > 0){
      let _tabActive = 0;
      for(let i=0; i<_tabList.length; i++){
        if(_tabList[i].IsActive == true){
          _tabActive = i
        }
      }
      this.setState({
        tabIndex: _tabActive,
        tabList: _tabList
      })
    }
  }
  _getConfigByKey=(key, df)=>{
    const {config} = this.props;
    if(config && config[key]){
      return config[key]
    }
    return df
  }
  _getConfig=()=>{
    const config = this.props.config || {};
    return config;
  }
  _getData=()=>{  
    const {data} = this.props;
    this.setState({
      data
    })
  }
  _onChangeTab=(tab, index)=>{
    HGA.trackingEventClick({
      action: 'click_item_home_tab_of_newcoming',
      label: 'Tab_'+tab.Title      
    })
    this.setState({
      tabIndex: index
    },()=>{
      if(tab?.ApiName && tab?.RequestData){
        this.setState({
          isLoading: true
        },()=>{
          HApiAxios.generic({
            request: {
              method: 'POST',
              path: 'DashboardForWebsite',
              name: tab.ApiName
            },
            data: {
              PageSize: 5,
              ...tab.RequestData
            },
            successCallBack: (res)=>{
              let _data = []
              if(res.Data){
                _data = res.Data;
                if(_data.Data){
                  _data = _data.Data
                }
              }
              this.setState({
                isLoading: false,
                data: _data,
                error: null,
              })
            },
            errorCallBack: (error, response)=>{
              // MyLoadingApp.Helpers.show({error: error || response.Msg})
              this.setState({
                isLoading: false,
                error: error || response.Msg
              })
            }
          })
        })
      }
    })
  }
  render(){
      let _data = this.state.data;
      let _tab = this.state.tabList;
      let _title = HUtils.Obj.get(this._getConfig(),"GrpMaterial.GrpMaterialName");
      return (
          <div className="spc spc-products">
            <div className="spc-header">
              <h2 className="title">
                <span>{_title}</span>
              </h2>
            </div>
            <div className="spc-body">
              {_tab.map((categoryTab, index)=>{
                  return (
                    <div key={index} className={categoryTab.IsActive?"product-grid active":"product-grid"} data-tabid={categoryTab.Id}>
                        <div className="item-grid">
                          {_data!=null && Array.isArray(_data) && _data.map((product, index)=>{
                            return <MyUI type="sc_card_product" key={index} data={product} from="home_new_coming" onClick={()=>{
                                HGA.trackingEventClick({
                                    action:'click_item_home_product_of_newcoming',
                                    label: product.MaterialName
                                  })
                              }}/>
                          })}
                        </div>
                    </div>
                  )
                })}
                {
                  this.state.isLoading === true &&
                  <div className="ui-loading">
                    <MyUI type="spinner_dots"/>
                  </div>
                }
            </div>
          </div>
      )
  }
}
export default HomeNewComing