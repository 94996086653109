import React from 'react'
import { HConfig } from 'apps/Helpers';
import MyHeader from '../components/MyHeader/Header';

class Header extends React.Component{
    render(){
        const _uiType = HConfig.fnList.getConfig("uiType");
        if(_uiType){
            return <MyHeader type={`header_${_uiType}`}/>
        }
        return <MyHeader type="header_0"/>
    }
}
export default Header