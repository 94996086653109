import React from 'react'
import PageExt from '../../../../../components/MyPage/Types/_ext';
import {HApiAxios, HText, HConfig, HLocalStorage,HUtils, HLink} from '../../../../Helpers';
import MyPageFromConfig from '../../../../../components/MyPage/MyPageFromConfig'
import MyUI from '../../../../../components/MyUI/MyUI'

class SC_Slug extends PageExt {
    constructor(props){
        super(props);
        this.state = {
            data: null,
            error: null,
            isLoading: true
        }
        this.parseParams();
        this._fnList = {
            getParams: ()=>{
                return this._params;
            },
            getConfig: ()=>{
                return this._config;
            },
            changeState:(obj={})=>{
                let _query = Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');
                window.history.pushState({},document.title,window.location.pathname + '?' + _query)
            }
        }
    }
    componentDidMount(){
        this.requestSlug()
    }
    parseParams=()=>{
        this._params = HLink.getAllUrlParams(window.location.href);
        this._config = {};
        if(this._params){
            // console.warn("_params", this._params);
            const _filterRange = HConfig.fnList.getConfig("filterPriceRange") || {min: 0, max: 2000000}
            const _pagesize = HUtils.Obj.get(HConfig.fnList.getConfig("categoryConfig"), "pageSizeOptions");
            const _appName = HConfig.fnList.getConfig("appName");
            this._config.page = this._params.page || 1;
            // this._config.viewMode = this._params.viewMode || 0;
            this._config.pageSize = this._params.pageSize || HLocalStorage.getObject(`${_appName}_pageSize`, _pagesize?.[0].Value);
            this._config.sortType = this._params.sortType || 1;
            this._config.priceMin = this._params.priceMin || _filterRange.min;
            this._config.priceMax = this._params.priceMax || _filterRange.max;                       
        }
    }
    requestSlug=()=>{
        const params = this.props.match && this.props.match.params;
        const _query = {
            Page: parseInt(this._config.page),
            CommandType: parseInt(this._config.sortType),
            PageSize: parseInt(this._config.pageSize),
            PriceMin: parseInt(this._config.priceMin),
            PriceMax: parseInt(this._config.priceMax)
        }
        if(params && params.Slug){
            this.setState({
                isLoading: true
            },()=>{
                HApiAxios.generic({
                    request: {
                        method: 'POST',
                        path: "DashboardForWebsite",
                        name: "GetListDataWithSlug"
                    },
                    data:{
                        Slug: params.Slug,
                        ..._query
                    },
                    successCallBack: (response)=>{
                        if(response.Data){
                            this.setState({
                                data: response.Data,
                                isLoading: false,
                                error:false
                            })
                        }
                    },
                    errorCallBack:(error,response)=>{
                        this.setState({
                            isLoading: false,
                            error: error
                        })
                    }
                })
            })            
        }
    }
    getSlugName=()=>{
        const {data} = this.state;
        return HUtils.Obj.get(data,"ExtraData.SlugInfo.EntityName");
    }
    renderCategory=()=>{
        const {data} = this.state;
        if(data){
            return(
                <MyPageFromConfig
                    configPage={{
                        Data: data,
                        UIType: "SC_Category"
                    }}
                    fnList={this._fnList}
                />
            )
        }
        return(
            <div></div>
        )
    }
    renderProduct=()=>{
        const {data} = this.state;
        if(data){
            return(
                <MyPageFromConfig
                    configPage={{
                        Data: data,
                        UIType: "SC_Product_Detail"
                    }}
                />
            )
        }
        return(
            <div></div>
        )
    }
    renderBlog=()=>{
        const {data} = this.state;
        if(data){
            return(
                <MyPageFromConfig
                    configPage={{
                        Data: data,
                        UIType: "SC_Blog"
                    }}
                />
            )
        }
        return(
            <div></div>
        )
    }
    renderTag=()=>{
        const {data} = this.state;
        if(data){
            return(
                <MyPageFromConfig
                    configPage={{
                        Data: data,
                        UIType: "SC_Tags"
                    }}
                    fnList={this._fnList}
                />
            )
        }
        return(
            <div></div>
        )
    }
    renderPageContent=()=>{
        const {data} = this.state;
        if(data){
            return(
                <MyPageFromConfig
                    configPage={{
                        Data: data,
                        UIType: "Sc_Page_Content"
                    }}
                />
            )
        }
        return(
            <div></div>
        )
    }
    renderQuickOrderGrp=()=>{
        const {data} = this.state;
        if(data){
            return(
                <MyPageFromConfig
                    configPage={{
                        Data: data,
                        UIType: "SC_QuickOrderGroup"
                    }}
                />
            )
        }
        return(
            <div></div>
        )
    }
    renderQuickOrder=()=>{
        const {data} = this.state;
        if(data){
            return(
                <MyPageFromConfig
                    configPage={{
                        Data: data,
                        UIType: "SC_QuickOrder"
                    }}
                />
            )
        }
        return(
            <div></div>
        )
    }
    render(){
        const {isLoading} = this.state;
        const slugName = this.getSlugName();
        // console.warn("render:",isLoading,slugName)
        if(slugName!=null){
            return(
                <div>
                    {
                        slugName == "Material" && this.renderProduct()
                    }
                    {
                        slugName == "GrpMaterial" && this.renderCategory()
                    }
                    {
                        slugName == "BlogPost" && this.renderBlog()
                    }
                    {
                        slugName == "Tag" && this.renderTag()
                    }
                    {
                        slugName == "Topic" && this.renderPageContent()
                    }
                    {
                        slugName == "QuickOrder" && this.renderQuickOrder()
                    }
                    {
                        slugName == "QuickOrderGrp" && this.renderQuickOrderGrp()
                    }
                </div>
            )
        }
        return(
            <div className="master-wrapper-content">
                <div className="master-column-wrapper">
                    <div className="center-1">
                        <div className="section">
                            {
                                isLoading!==false?
                                <MyUI type="sc_loading"/>
                                :
                                <div className="title">{HText.get("text_slug_no_exist")}</div>
                            }                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SC_Slug