import React from 'react'
import {MyModal} from '../../../../Components'
import { HText } from 'apps/Helpers';
import MyIconSvg from '../../MyIconSvg/MyIconSvg';
import './styles/sc_input_quantity.css'

class SC_Input_Quantity extends React.Component{
    constructor(props){
        super(props);
        this.state={
            _value: 1,
            config: {
                maxQuantity: 1000
            }
        }
    }
    componentDidMount(){
        const {value} = this.props;
        if(value!=null){
            this.setState({
                _value: value
            })
        }
    }
    shouldComponentUpdate(nextProps){
        if(this.props.value !== nextProps.value){
            this.setState({
                _value: nextProps.value
            })
        }
        return true;
    }
    checkValidValue=(value)=>{
        const {maxQuantity, onDelete, deleteAtZero} = this.props;
        const {config} = this.state;
        let _maxValue = maxQuantity!=null ? maxQuantity : config.maxQuantity;
        if(Number.isNaN(value)==true || value < 0){
            MyModal.Helpers.showMsg(HText.get("text_cart_invalid_quantity"), {title: HText.get("text_warning_title")})
            return false;
        }
        else if(value == 0){
            if(deleteAtZero!==true){
                if(onDelete){
                    MyModal.Helpers.showConfirm(HText.get("text_cart_delete_baseon_quantity"), {title: HText.get("text_cart_confirm_delete_title"),onOK: (cModal,{fnClose})=>{
                        onDelete();                
                        fnClose && fnClose();
                    }})
                } 
            }
            else{
                MyModal.Helpers.showMsg(HText.get("text_cart_invalid_quantity"), {title: HText.get("text_warning_title")})
            }
            return false;
        }
        else if(value > _maxValue){
            MyModal.Helpers.showMsg(`${HText.get("text_cart_quantity_over")} ${_maxValue}!`, {title: HText.get("text_warning_title")})
            return false;
        }
        return true;
    }
    onChangeValue=(value)=>{
        this.setState({
            _value: value
        })
    }
    updateValue=(value)=>{
        const {onChange, minQuantity} = this.props;
        let _minValue = 1;
        if(minQuantity != null){
            _minValue = minQuantity
        }
        let _valueUpdate = parseFloat(parseFloat(value).toFixed(1));
        if(this.checkValidValue(_valueUpdate) == true){
            this.setState({
                _value: _valueUpdate
            },()=>{
                onChange && onChange(_valueUpdate)
            })
        }
        else if(this.checkValidValue(_valueUpdate) == false){
            this.setState({
                _value: _minValue
            },()=>{
                onChange && onChange(_minValue)
            })
        }
    }
    renderDefault=()=>{
        const {showControlBtn, className, disabled} = this.props;
        const {_value} = this.state;
        return(
            <div className={`sc_input_quantity default ${className}`}>
                {
                    showControlBtn !=false &&
                    <button className="inc" onClick={()=>this.updateValue(_value - 1)} disabled={disabled} style={disabled?{opacity: "70%", cursor: "unset"}:{}}>
                        {/* <i className="fa fa-minus"/> */}
                        <MyIconSvg type="minus" width={13} height={13} fill="var(--color-secondary)"/>
                    </button>
                }
                <input className="qty-input" type="text" style={disabled?{opacity: "70%", margin: '0px 4px'}:{margin: '0px 4px'}} disabled={disabled} value={_value} onChange={(e)=>{this.onChangeValue(e.currentTarget.value)}} onBlur={(e)=>this.updateValue(this.state._value)}/>
                {
                    showControlBtn !=false &&
                    <button className="dec" onClick={()=>this.updateValue(_value + 1)} disabled={disabled} style={disabled?{opacity: "70%", cursor: "unset"}:{}}>
                        {/* <i className="fa fa-plus"/> */}
                        <MyIconSvg type="plus" width={13} height={13} fill="var(--color-secondary)"/>
                    </button>
                }               
            </div>
        )
    }
    renderVertical=()=>{
        const {showControlBtn, className, disabled} = this.props;
        const {_value} = this.state;
        return(
            <div className="sc_input_quantity vertical">
                {
                    showControlBtn !== false &&
                    <div className={className}>
                        <button onClick={()=>this.updateValue(_value + 1)} disabled={disabled} style={disabled?{opacity: "70%", cursor: "unset"}:{}}>
                            <MyIconSvg type="plus" width={13} height={13} fill="var(--color-secondary)"/>
                        </button>
                        <button onClick={()=>this.updateValue(_value - 1)} disabled={disabled} style={disabled?{opacity: "70%", cursor: "unset"}:{}}>
                            <MyIconSvg type="minus" width={13} height={13} fill="var(--color-secondary)"/>
                        </button>
                    </div>
                }
                <input className="qty-input" type="text" style={disabled?{opacity: "70%"}:{}} disabled={disabled} value={_value} onChange={(e)=>{this.onChangeValue(e.currentTarget.value)}}
                 onBlur={(e)=>this.updateValue(this.state._value)} />       
            </div>
        )
    }
    render(){
        const {isBtnVertical} = this.props;
        if(isBtnVertical == true){
            return this.renderVertical()
        }
        return this.renderDefault()
    }
}
export default SC_Input_Quantity