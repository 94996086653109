import React from "react";
import MyUITypes from "../../../../components/MyUI/MyUITypes";
import MyLoadingComponent from "../../../../components/MyLoading/MyLoadingComponent";
import AppMyUI from ".";

/** Home */
import HomeTopSlide from "./Types/home_topslide";
import HomeCatProduct from "./Types/home_cat_product";

/** Common */
import SC_Dropdown from "./Types/sc_dropdown";
import SC_CardProduct from "./Types/sc_card_product";
import SC_Navbar from "./Types/sc_navbar";
import SC_HeaderSearch from "./Types/sc_header_search_animation";
import SC_BreakCumb from "./Types/sc_breakcumb";
import SC_ProductShortDesc from "./Types/sc_product_shortdecs";
import SC_CategoryTop from "./Types/sc_category_top";
import SC_FilterColor from "./Types/sc_filter_color";
import SC_CartAddress from "./Types/sc_cart_address";

const AppMyUIType1 = {
  init() {
    MyUITypes.addTypes("home_topslide", (props) => {
      return <HomeTopSlide {...props} />;
    });
    MyUITypes.addTypes("home_cat_product", (props) => {
      return <HomeCatProduct {...props} />;
    });
    MyUITypes.addTypes("sc_dropdown", (props) => {
      return <SC_Dropdown {...props} />;
    });
    MyUITypes.addTypes("sc_card_product", (props) => {
      return <SC_CardProduct {...props} />;
    });
    MyUITypes.addTypes("sc_navbar", (props) => {
      return <SC_Navbar {...props} />;
    });
    MyUITypes.addTypes("sc_header_search_animation", (props) => {
      return <SC_HeaderSearch {...props} />;
    });
    MyUITypes.addTypes("sc_breakcumb", (props) => {
      return <SC_BreakCumb {...props} />;
    });
    MyUITypes.addTypes("sc_product_shortdesc", (props) => {
      return <SC_ProductShortDesc {...props} />;
    });
    MyUITypes.addTypes("sc_category_top", (props) => {
      return <SC_CategoryTop {...props} />;
    });
    MyUITypes.addTypes("sc_filter_color", (props) => {
      return <SC_FilterColor {...props} />;
    });
    MyUITypes.addTypes("sc_cart_address", (props) => {
      return <SC_CartAddress {...props} />;
    });
    AppMyUI.init();
  },
};
export default AppMyUIType1;
