import React from 'react';
import '../styles.css';
import { useSelector, useDispatch } from 'react-redux'
import {HConfig} from '../../Helpers'
import HGA from '../../../helpers/GA'


const TheFooter = () => {
  const footerLink = useSelector(state => state.shop.footerLink)
  const socialList = HConfig.fnList.getConfig("socialList", [])
  let [collapseMobile, setCollapseMobile] = React.useState({})
  const onChangeCollapse=(index)=>{
    collapseMobile[index] = !collapseMobile[index]
    setCollapseMobile({...collapseMobile})
  }
  let hotline = HConfig.fnList.getConfig('uiHotline');
  return (
    <div className="footer">
      <div className="footer-upper">
        <div className="center">
          {/* <div className="newsletter">
            <div className="title">
              <strong>Đăng kí nhận bản tin</strong>
            </div>
            <div className="newsletter-subscribe">
              <div className="newsletter-email">
                <input className="newsletter-subscribe-text" id="newsletter-email" name="NewsletterEmail" placeholder="Nhập email của bạn ở đây" type="text"/>
                <input className="button-1 newsletter-subscribe-button" type="button" id="newsletter-subscribe-button" value="Subcribe"/>
              </div>
            </div>
          </div> */}
          <ul className="social-sharing">
            {
              socialList.map((v, i)=>{
                return (
                  <li key={i}>
                    <a target="blank" href={v.Link} style={v.Style}><i className={v.Icon}/></a>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
      <div className="footer-middle">
        <div className="center">
          {
            footerLink && footerLink.map((v,i)=>{
              return(
                <div className="footer-block" key={i}>
                  <div className="title" style={{display: 'flex', justifyContent: 'space-between'}} onClick={()=>onChangeCollapse(i)}>
                    <strong>{v.Title}</strong>
                    <div className="footer-collapse">
                      <i className={collapseMobile[i] == true ? "fa fa-sort-asc" : "fa fa-sort-desc"}/>
                      </div>
                  </div>
                  <ul className="list" style={collapseMobile[i] == true ? {display: 'block'} : {display: 'none'}}>
                    {
                      v.Childs && v.Childs.map((m,k)=>{
                        return (
                          <li key={k}>
                            {
                              m.Icon && <i className={m.Icon} style={{ minWidth: 20}}/>
                            }
                            <a href={m.Slug}>{m.Title}</a>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="footer-lower">
        <div className="center">
          <ul className="accepted-payments">
            <li className="method1"></li>
            <li className="method2"></li>
            <li className="method3"></li>
          </ul>
          <div className="footer-disclaimer">
            {
              HConfig.fnList.getConfig('uiCopyRightFooter')
            }
            {/* Copyright © 2020 Ajuma Garden | 31-33 Nguyễn Văn Đậu, Bình Thạnh, TP.HCM. Tất cả quyền được bảo lưu. */}
          </div>
          <div className="footer-store-theme">

          </div>
        </div>
      </div>
      <div className="helper">
            <div id="goToTop-contact">Hotline: <a href={`tel:+84${hotline && hotline.slice(1)}`} onClick={()=>{
              HGA.trackingEventClick({
                label:'item_footer_sdt',
                value: hotline
              })
            }}>{hotline}</a></div>
          </div>
    </div>
  )
}

export default TheFooter