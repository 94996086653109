import React from 'react'
import SC_Product_Detail from 'apps/shoppingCart/components/MyPage/Types/SC_Product_Detail'

class SC_Product_Detail_1 extends SC_Product_Detail{
    render(){
        const {product_detail, grpInfo} = this.state;
        if(product_detail && grpInfo){
            return (
                <div id="detail-page-wrapper" className="master-wrapper-content">
                        {this.renderHelmet()}
                        {this.renderToolbarAdmin()}
                        {this.renderBreakcumb()}
                        <div className="master-column-wrapper">
                            <div className="center-1">
                                <div className="page product-details-page">
                                    <div className="page-body">
                                        <div>
                                            {this.renderProductInfo()}
                                            {this.renderProductTag()}
                                            {this.renderProductRelative()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            );
        }
        return <div></div>
    }
}
export default SC_Product_Detail_1