import React from 'react'
import {CButton} from '../../../../../components/MyCore'
import {HUI, HConfig, HApiAxios, HText, HAuth} from '../../../../Helpers'
import {initFacebookSdk} from '../../../../../libs/FacebookLogin/index';
import GoogleLogin from '../../../../../libs/GoogleLogin';
import HGA from '../../../../../helpers/GA'
import {MyUI} from '../../../../Components';
import MyIconSvg from '../../MyIconSvg/MyIconSvg';

import './styles/sc_login.css'

class SC_Login extends React.Component{
    componentDidMount(){
        // initFacebookSdk().then(()=>{//init trong _config.js
        // });
    }
    onLoginWithFacebook=()=>{
        if(window.FB){
            let _accessToken = null;
            let _userId = null;
            window.FB.getLoginStatus(async({ authResponse }) => {
                // console.warn("authResponse 1:",authResponse);
                if (authResponse) {
                    _accessToken = authResponse.accessToken;
                    _userId = authResponse.userID;
                } else {
                    const { authResponse } = await new Promise(window.FB.login);
                    if (!authResponse){
                        HUI.Toast.showError(HText.get("text_login_facebook_failed"));
                        return;
                    };
                    // console.warn("authResponse 2:",authResponse);
                    _accessToken = authResponse.accessToken;
                    _userId = authResponse.userID;
                }
                // console.warn("_accessTokenFB", _accessToken)            
                this._requestSocialLogin("facebook",_accessToken,_userId);
            });
        }
    }
    _requestSocialLogin=(SocialId, SocialToken, SocialUsername)=>{
        const {fnFomModal} = this.props;
        let _appName = HConfig.fnList.getConfig("appName")
        if(SocialToken){
            HApiAxios.generic({
                request: {
                    method: 'POST',
                    url: 'Account/SocialLogin'
                },
                data: {
                    SocialId: SocialId,
                    SocialToken: SocialToken,
                    SocialUsername: SocialUsername,
                    AppName: _appName,
                },
                successCallBack: (response)=>{
                    if(response.Data && response.Data.Token){
                        HAuth.login(response.Data.Token);
                        window.location.reload()
                    }
                    if(fnFomModal && fnFomModal.fnClose){
                        fnFomModal.fnClose()
                    }                    
                },
                errorCallBack: (error, response)=>{
                    HUI.Toast.showError(error || response.Msg)
                    if(fnFomModal && fnFomModal.fnClose){
                        fnFomModal.fnClose()
                    }
                }
            })
        }
    }
    _responseGoogle=(response)=>{
        let _token = response.accessToken;
        let _profile = response.profileObj;
        this._requestSocialLogin("google",_token,_profile.email);
        HGA.trackingEventClick({
            action:'click_login_google'
        })
    }
    _responseGoogleFail=(response)=>{
        HUI.Toast.showError(HText.get('msg_error_login_google'));    
    }
    renderUIDefault = () =>{
        return(
            <div className="sc_login">
                <div className="title">{HText.get("text_btn_login")}</div>
                <div className="container">
                    <CButton className="lg_fb" onClick={()=>{
                        this.onLoginWithFacebook();
                        HGA.trackingEventClick({
                            action:'click_login_facebook'
                        })
                    }}>
                      {/* <i className="fa fa-facebook"/> */}
                      {HText.get("text_btn_login_facebook")}</CButton>
                    <GoogleLogin
                          clientId={HConfig.fnList.getConfig("googleClientId")}
                          buttonText={HText.get("text_btn_login_google")}
                          onSuccess={this._responseGoogle}
                          onFailure={this._responseGoogleFail}
                        />
                    {/* <CButton className="lg_gg"><i className="fa fa-google"/>Đăng nhập với Google</CButton> */}
                </div>
                {
                    HConfig.fnList.getIsDevMode() == true && // Jira https://allianceitscvn.atlassian.net/browse/AWO-179
                    <MyUI type="common_login" afterLogin={()=>{window.location.reload()}}/>
                }
            </div>
        )
    }
    renderUIType1 = () =>{
        return (
          <div className="sc_login_type1">
            <div className="lg _fb">
              <CButton
                onClick={() => {
                  this.onLoginWithFacebook();
                  HGA.trackingEventClick({
                    action: "click_login_facebook",
                  });
                }}
              >
                <span>
                  <i className="fa fa-facebook" />
                </span>
              </CButton>
            </div>
            <div className="lg _gg">
              <GoogleLogin
                clientId={HConfig.fnList.getConfig("googleClientId")}
                onSuccess={this._responseGoogle}
                onFailure={this._responseGoogleFail}
              >
                <span><MyIconSvg type="google" width={17} height={17}/></span>
              </GoogleLogin>
            </div>
          </div>
        );
    }
    renderUIType2 = () =>{
      return (
        <div className="sc_login_type2">
          <div className="lg _fb">
            <CButton
              onClick={() => {
                this.onLoginWithFacebook();
                HGA.trackingEventClick({
                  action: "click_login_facebook",
                });
              }}
            >
             {HText.get("text_social_login")}
              <span>
                <i className="fa fa-facebook" />
              </span>
            </CButton>
          </div>
          <div className="lg _gg">
            <GoogleLogin
              clientId={HConfig.fnList.getConfig("googleClientId")}
              onSuccess={this._responseGoogle}
              onFailure={this._responseGoogleFail}
            >
              {HText.get("text_social_login")}
              <span><MyIconSvg type="google" width={17} height={17}/></span>
            </GoogleLogin>
          </div>
        </div>
      );
  }
    render(){
        const {uiType} = this.props;
        switch(uiType){
            case 1:
                return this.renderUIType1();
            case 2: 
                return this.renderUIType2();
            default:
                return this.renderUIDefault();
        }
         
    }
}
export default SC_Login