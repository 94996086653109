import React from 'react'
import MyPageTypes from '../../../../components/MyPage/MyPageTypes';
import MyLoadingComponent from '../../../../components/MyLoading/MyLoadingComponent';

import SC_Home from '../../../shoppingCart/components/MyPage/Types/SC_Home'
import SC_Category from '../../../shoppingCart/components/MyPage/Types/SC_Category'
import SC_PageContent from '../../../shoppingCart/components/MyPage/Types/SC_PageContent'
import SC_ProductDetail from '../../../shoppingCart/components/MyPage/Types/SC_Product_Detail'
import SC_Slug from '../../../shoppingCart/components/MyPage/Types/SC_Slug'
import SC_BlogHome from '../../../shoppingCart/components/MyPage/Types/SC_BlogHome'
import SC_Blog from '../../../shoppingCart/components/MyPage/Types/SC_Blog'
import SC_Search from '../../../shoppingCart/components/MyPage/Types/SC_Search'
import SC_Bill_Detail from '../../../shoppingCart/components/MyPage/Types/SC_Bill_Detail'
import ScBillPublic from '../../../shoppingCart/components/MyPage/Types/SC_Bill_Public'
import SC_Tags from '../../../shoppingCart/components/MyPage/Types/SC_Tags'
import SC_Admin from '../../../shoppingCart/components/MyPage/Types/SC_Admin'
import SC_QuickOrder from '../../../shoppingCart/components/MyPage/Types/SC_QuickOrder'
import SC_QuickOrderGroup from '../../../shoppingCart/components/MyPage/Types/SC_QuickOrderGroup'
import SC_QuickOrderUser from '../../../shoppingCart/components/MyPage/Types/SC_QuickOrderUser'
import SC_Wishlist from '../../../shoppingCart/components/MyPage/Types/SC_WishList'

const SC_ProductWishlist = React.lazy(() => import('../../../shoppingCart/components/MyPage/Types/SC_Product_Wishlist'));
const SC_Cart = React.lazy(() => import('../../../shoppingCart/components/MyPage/Types/SC_Cart'));
const SC_AddressBook = React.lazy(() => import('../../../shoppingCart/components/MyPage/Types/SC_AddressBook'));
const SC_HistoryBill = React.lazy(() => import('../../../shoppingCart/components/MyPage/Types/SC_HistoryBill'));

const AppMyPage = {
  init(){
    MyPageTypes.addTypes('sc_home',(props)=>{
      return ( <SC_Home {...props}/> )
    });
    MyPageTypes.addTypes('sc_category',(props)=>{
      return ( <SC_Category {...props}/> )
    });
    MyPageTypes.addTypes('sc_page_content',(props)=>{
      return ( <SC_PageContent {...props}/> )
    });
    MyPageTypes.addTypes('sc_product_detail',(props)=>{
      return ( <SC_ProductDetail {...props}/> )
    });
    MyPageTypes.addTypes('sc_product_wishlist',(props)=>{
      return ( <SC_ProductWishlist {...props}/> )
    });
    MyPageTypes.addTypes('sc_cart',(props)=>{
      return ( <SC_Cart {...props}/> )
    });
    MyPageTypes.addTypes('sc_slug',(props)=>{
      return ( <SC_Slug {...props}/> )
    });
    MyPageTypes.addTypes('sc_blog_home',(props)=>{
      return ( <SC_BlogHome {...props}/> )
    });
    MyPageTypes.addTypes('sc_blog',(props)=>{
      return ( <SC_Blog {...props}/> )
    });
    MyPageTypes.addTypes('sc_addressbook',(props)=>{
      return ( <SC_AddressBook {...props}/> )
    });
    MyPageTypes.addTypes('sc_history_bill',(props)=>{
      return ( <SC_HistoryBill {...props}/> )
    });
    MyPageTypes.addTypes('sc_search',(props)=>{
      return ( <SC_Search {...props}/> )
    });
    MyPageTypes.addTypes('sc_quickorderuser',(props)=>{
      return ( <SC_QuickOrderUser {...props}/> )
    });
    MyPageTypes.addTypes('sc_bill_detail',(props)=>{ return ( <SC_Bill_Detail {...props}/> ) });
    MyPageTypes.addTypes('sc_bill_public',(props)=>{ return ( <ScBillPublic {...props}/> ) });
    MyPageTypes.addTypes('sc_tags',(props)=>{ return ( <SC_Tags {...props}/> ) });
    MyPageTypes.addTypes('sc_admin',(props)=>{ return ( <SC_Admin {...props}/> ) });
    MyPageTypes.addTypes('sc_quickorder',(props)=>{ return ( <SC_QuickOrder {...props}/> ) });
    MyPageTypes.addTypes('sc_quickordergroup',(props)=>{ return ( <SC_QuickOrderGroup {...props}/> ) });
    MyPageTypes.addTypes('sc_wishlist',(props)=>{ return ( <SC_Wishlist {...props}/> ) });
  }
}
export default AppMyPage;
