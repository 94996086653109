import React from 'react'
import { HConfig } from 'apps/Helpers';
import MyFooter from '../components/MyFooter/Footer';
class Footer extends React.Component{
    render(){
        const _uiType = HConfig.fnList.getConfig("uiType");
        if(_uiType){
            return <MyFooter type={`footer_${_uiType}`}/>
        }
        return <MyFooter type="footer_0"/>
    }
}
export default Footer;