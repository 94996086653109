import React from 'react'
import classnames from 'classnames';
import ReactPaginationBox from '../../../libs/ReactPaginate/PaginationBoxView'
class MyUI_ReactPaginationBox extends React.Component {    
  constructor(props){
    super(props);
    this.state={
    }
  }
  render(){    
    const {containerClassName,subContainerClassName,activeClassName,onPageChange,pageCount,pageSize,pageTotal,hrefBuilder,useLink,forcePage} = this.props;
    let _pageCount = pageCount || 0;
    if(pageSize && pageTotal && pageTotal>0){
      _pageCount = Math.ceil(pageTotal / pageSize); 
    }
    return (
      <ReactPaginationBox
        useLink={useLink}
        forcePage={forcePage}
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={_pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={({ selected })=>{
          console.warn("selected: ",selected);
          if(onPageChange){
            onPageChange({selected});
          }           
        }}
        hrefBuilder={hrefBuilder}
        containerClassName={classnames('pagination',containerClassName)}
        subContainerClassName={classnames('pages pagination',subContainerClassName)}
        activeClassName={classnames('active',activeClassName)}
      />
    )
  } 
}

export default MyUI_ReactPaginationBox