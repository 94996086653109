import React from 'react'
import {
  TheFooter,
  TheHeader,
  TheContent
} from './index'
import {MyUI} from '../../Components'
import {HApiAxios, HStoreRedux, HConfig,HLocalStorage} from '../../Helpers'
import HGA from '../../../helpers/GA'
import MessengerCustomerChat from '../../../libs/FacebookMessenger'
import FB_ChatMessenger from '../../../components/MyUI/TypesMore/fb_chatmessenger';
import {connect} from 'react-redux'

class TheLayout extends React.Component {
  componentDidMount(){
    this.requestDataLayout();
    HGA.trackingPageView({
      page: window.location.pathname + window.location.search,
      hideUser: true
    })
  }
  requestDataLayout=()=>{
    HApiAxios.generic({
      request: {
        method: 'POST',
        path: 'DashboardForWebsite',
        name: 'GetMenuPage'
      },
      data: {},
      successCallBack: (response)=>{
        if(response.Data){
          const _dataLayout = response.Data;
          if(_dataLayout){
            const _menus = _dataLayout.Menu;
            const _header = _dataLayout.Header;
            const _footer = _dataLayout.Footer;
            const _adminButtons = _dataLayout.Admin;
            const _banner = _dataLayout.Banner;
            const _optionList = _dataLayout.OptionList;
            const _appLogo = _dataLayout.AppLogo;
            let _overlaySeason = _dataLayout.Config; 
            let _objUpdate = {
              isLoadedGetMenuPage: true
            };
            if(_menus){ _objUpdate.categoryMenu= _menus; }
            if(_header){ _objUpdate.headerLink= _header; }
            if(_adminButtons){ _objUpdate.adminButtons= _adminButtons; }
            if(_footer){
              // const _clientFooter = [//ko su dung nua //AWO-208
              //   {
              //     Title: 'LIÊN HỆ VỚI CHÚNG TÔI',
              //     Slug: null,
              //     Childs: [
              //       {
              //         Icon: 'fa fa-map-marker',
              //         Title: '31-33 Nguyễn Văn Đậu, Bình Thạnh, TP. HCM'
              //       },
              //       {
              //         Icon: 'fa fa-envelope-o',
              //         Title: 'Ajumagarden@gmail.com'
              //       },
              //       {
              //         Icon: 'fa fa-phone',
              //         Title: '0978487735'
              //       },
              //     ]
              //   }
              // ]
              _objUpdate.footerLink = _footer;
            }
            if(_banner){_objUpdate.banner= _banner;}
            if(_appLogo){_objUpdate.appLogo = _appLogo}
            if(typeof(_overlaySeason) === "string"){
              try {
                _overlaySeason = JSON.parse(_overlaySeason);
                _objUpdate.overlaySeasonConfig = _overlaySeason;

              } catch (error) {
                console.log("Parse error", error);
              }              
            }
            if(_optionList){_objUpdate.optionList = _optionList}
            console.warn("dispatch", _objUpdate);              
            HStoreRedux.dispatch({type: 'setObjInShop', ..._objUpdate})
          }
        }
      },
      errorCallBack: (error, response)=>{
      }
    })
  }
  render(){
    const {overlayseason} = this.props;
    return (
        <div className="c-app c-default-layout">
          {
            overlayseason && HConfig.fnList.getIsDevMode() == true &&
            <MyUI type="myui_overlay_season" configOverlay={overlayseason}/>
          }
          <div className="c-wrapper">
            <TheHeader/>
            <div className="c-body">
              <TheContent/>
            </div>
            <TheFooter/>
          </div>
          <MyUI type="sc_gotop"/>
          <FB_ChatMessenger 
            pageId={`${HConfig.fnList.getConfig('facebookPageId')}`}
            appId={`${HConfig.fnList.getConfig('facebookAppId')}`}
            htmlRef={HConfig.fnList.getConfig('appName')}
            timeout={3000}
          />
        </div>
      )
  }
}
const mapStateToProps = state =>{
  return{
    overlayseason: state.shop.overlaySeasonConfig
  }
}
export default connect(mapStateToProps,null)(TheLayout)