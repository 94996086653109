import { createStore, combineReducers} from 'redux';

const initialState = {
  home: null,  
  headerLink: [],
  footerLink: [],
  categoryMenu: [],
  cartProducts: [],
  wishlist: [],
  recent: [],
  total: 0,
  adminButtons:null,
  isLoadedGetMenuPage: false,
  banner: [],
  overlaySeasonConfig: null,
  optionList: null,
}

export const shop = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'setObjInShop':
      if(rest["cartProducts"]){
        let _sum = 0;
        if(rest.cartProducts && rest.cartProducts.length>0){
          for(let i=0; i<rest.cartProducts.length; i++){
            _sum = _sum + parseFloat(rest.cartProducts[i].SellPrice)*parseFloat(rest.cartProducts[i].Quantity)
          }
        }
        state.total = _sum
      }
      return {...state, ...rest }
    default:
      return state
  }
}