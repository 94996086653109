import React from "react";
import MyLibTypes from "../../../../components/MyLib/MyLibTypes";

import ImageZoom from './Types/imagezoom';

const AppMyLib = {
  init() {
    MyLibTypes.addTypes("imagezoom", (props) => {
      return <ImageZoom {...props}/>
    });
  },
};
AppMyLib.init();
export default AppMyLib;
