import React from 'react';
import {MyUI} from '../../../../Components';
import PageExt from '../../../../../components/MyPage/Types/_ext';
import {HApiAxios, HLink, HUtils, HLocalStorage, HConfig} from '../../../../Helpers'
import './styles/common.css'

class SC_Category extends PageExt{
    constructor(props){
        super(props);
        this.state={
            viewMode: 0,
            pageSize: 6,
            sortType: 1,
            category: [],
            categoryDetail: null,
            filterPrice: {},
            total: 0,
            tagList: [],
        }
        this.parseConfig();
    }
    componentDidMount(){
        const {configPage} = this.props;
        if(configPage && configPage.Data){
            const _grpInfo = HUtils.Obj.get(configPage, "Data.ExtraData.GrpMaterial");
            const _slugInfo = HUtils.Obj.get(configPage, "Data.ExtraData.SlugInfo")
            const _data = HUtils.Obj.get(configPage, "Data.Data.MaterialForWebInfo");
            this.setState({
                category: _data,
                categoryDetail: {..._grpInfo, ..._slugInfo}
            },()=>{
                if(configPage.Data.Total){
                    this.setState({
                        total: configPage.Data.Total
                    })
                }
            })
        }
        else{
            this.getCategory();
        }
        this.getTagList()        
    }
    getFnList=()=>{
        const {fnList} = this.props;
        if(fnList){
            return fnList;
        }
        else if(this._fnList){
            return this._fnList;
        }
        return {};
    }
    parseConfig=()=>{
        const fnList = this.getFnList();
        this._config = {};
        if(fnList && fnList.getConfig){
            this._config = fnList.getConfig();
            // console.warn("config:",this._config,fnList);
            this.state.viewMode = this.getConfigFromLocalStorage("viewMode", 0)
            this.state.pageSize = this.getConfigFromLocalStorage("pageSize", this._config.pageSize)
            this.state.sortType = this._config.sortType;
            this.state.filterPrice = {
                    min: this._config.priceMin,
                    max: this._config.priceMax
                };
            console.warn("this._config", this._config)
        }
        
    }
    getConfigFromLocalStorage=(key, df)=>{
        let _config = HLocalStorage.getObject(`awo_${key}`);
        if(_config !== undefined && _config !== null){
            return _config;
        }
        return df;
    }
    getSlug=()=>{
        const {configPage} = this.props;
        const params = this.props.match && this.props.match.params;

        if(configPage && configPage.Data){
            const _slug = HUtils.Obj.get(configPage,"Data.ExtraData.SlugInfo.Slug")
            return _slug
        }
        else if(params && params.Slug){
            return params.Slug
        }
    }
    getFilterQuery=()=>{
        return {
            Page: parseInt(this._config.page),
            CommandType: parseInt(this._config.sortType),
            PageSize: parseInt(this._config.pageSize),
            PriceMin: parseInt(this._config.priceMin),
            PriceMax: parseInt(this._config.priceMax)
        }
    }
    getCategory=({customQuery, customApi}={})=>{
        const slug = this.getSlug();        
        if(slug){
            this.showLoadingApp()
            HApiAxios.generic({
                request: {
                    method: 'POST',
                    url: customApi || 'DashboardForWebsite/GetListDataWithSlug'
                },
                data: {
                    Slug: slug,
                    ...this.getFilterQuery(),
                    ...customQuery
                },
                successCallBack: (response)=>{
                    if(response.Data){
                        const _data = HUtils.Obj.get(response, "Data.Data.MaterialForWebInfo");
                        const _extra = HUtils.Obj.get(response, "Data.ExtraData");
                        const _total = HUtils.Obj.get(response, "Data.Total");
                        if(_data){
                            this.setState({
                                category: _data
                            })
                        }
                        if(_extra ){
                            const _grpInfo = HUtils.Obj.get(_extra, "GrpMaterial");
                            const _slugInfo = HUtils.Obj.get(_extra, "SlugInfo")
                            this.setState({
                                categoryDetail: {..._grpInfo, ..._slugInfo}
                            })
                        }
                        if(_total!=null){
                            this.setState({
                                total: _total
                            })
                        }
                        this.hideLoadingApp()
                    }
                },
                errorCallBack: (err, response)=>{
                    this.hideLoadingApp()
                }
            })
        }
    }
    getTagList=()=>{
        const params = this.getSlug();
        if(params){
            HApiAxios.generic({
                request: {
                    method: 'POST',
                    url: 'EzyWeb_ListMaterialTag4Web/List'
                },
                data: {
                    Slug: params,
                },
                successCallBack: (response)=>{
                    if(response.Data){
                        const _data = response.Data;
                        if(_data.Data){
                            this.setState({
                                tagList: _data.Data
                            })
                        }
                    }
                },
                errorCallBack: (err, response)=>{}
            })
        }
    }
    updateURL = (field, value) => {
      const fnList = this.getFnList();
      if (this._config) {
        this._config[field] = value;
        fnList && fnList.changeState && fnList.changeState(this._config);
      }

      if(field === "viewMode" || field === "pageSize"){
        const appName = HConfig.fnList.getConfig("appName");
        HLocalStorage.setObject(`${appName}_${field}`, value)
      }

    };
    updateHref=(p)=>{
        let _params = HLink.getAllUrlParams(window.location.href);
        _params.page = p;
        let _query = Object.keys(_params).map(key => `${key}=${_params[key]}`).join('&');
        return window.location.pathname + '?' + _query;
    }
    changeViewMode=(value)=>{
        this.setState({
            viewMode: value
        },()=>{
            this.updateURL("viewMode", value)
        })
    }
    changePageSize=(value)=>{
        this.setState({
            pageSize: value
        },()=>{
            this.updateURL("pageSize", value);
            this.getCategory()
        })
    }
    changeTypeSort=(value)=>{
        this.setState({
            sortType: value
        },()=>{
            this.updateURL("sortType", value)
            this.getCategory()
        })
    }
    onFilter=(value)=>{
        if(value){
            this.updateURL("priceMin", value.min);
            this.updateURL("priceMax", value.max);
            this.getCategory()
        }
    }
    renderBreakcumb=()=>{
        const {categoryDetail} = this.state;
        if(categoryDetail){
            return (<MyUI type="sc_breakcumb" data={[{Title: categoryDetail.GrpMaterialName}]}/>)
        }
        return <div></div>
    }
    renderHelmet=()=>{
        const {categoryDetail} = this.state;
        const meta = {
            MetaTitle: categoryDetail.MetaKeyTitle || `${HConfig.fnList.getConfig("metaName")} - ${categoryDetail.GrpMaterialName}`,
            MetaKeyWords: categoryDetail.MetaKeyWords || `${HConfig.fnList.getConfig("metaName")} - ${categoryDetail.GrpMaterialName}`,
            MetaDescription: categoryDetail.MetaKeyDescription || `${HConfig.fnList.getConfig("metaName")} - ${categoryDetail.GrpMaterialName}`
        }
        return <MyUI type="sc_helmet" data = {meta}/>
    }
    renderPagination=()=>{
        const {categoryDetail, pageSize, total} = this.state;
        // console.warn("total",total)
        if(categoryDetail){
            if(total > pageSize){
                return (
                    <MyUI type='react_pagination_box' 
                        useLink={true}
                        forcePage={this._config.page}
                        pageSize={this.state.pageSize} 
                        pageTotal={this.state.total||0}
                        containerClassName="pager"
                        hrefBuilder={(p)=>{
                            return this.updateHref(p)
                        }}
                        onPageChange={({selected})=>{
                            // this.changePage(selected + 1)
                        }}/>
                )
            }
        }
        return <div></div>
    }
    renderFilterTop = () =>{
        const {categoryDetail, viewMode, sortType, pageSize, filterPrice} = this.state;
        if(categoryDetail){
            return (
                <div>
                    {
                        categoryDetail.Note &&
                        <p>{categoryDetail.Note}</p>
                    }
                    {
                        this.renderSearch && this.renderSearch()
                    }
                    <div className="filter-price-mobile">
                        <MyUI 
                            type="sc_filter"
                            defaultValue={filterPrice}
                            fnList={{
                                onFilter: (value)=>{
                                    this.setState({
                                        filterPrice: value
                                    },()=>{
                                        this.onFilter(value)
                                    })
                            }}} 
                        />
                    </div>
                    <MyUI
                        type="sc_category_top" 
                        viewMode={viewMode}
                        sortType={sortType} 
                        numberInPage={pageSize} 
                        changeViewMode={(mode)=>this.changeViewMode(mode)} 
                        changeNumberInPage={(event)=>this.changePageSize(event.target.value)}
                        changeTypeSort={(type)=>this.changeTypeSort(type)}
                    />
                </div>
            )
        }
    }
    renderFilterSide = () =>{
        const {categoryDetail, filterPrice, tagList} = this.state;
        if(categoryDetail){
            return(
                <MyUI 
                    type="sc_category_left" 
                    data={categoryDetail} 
                    filter={filterPrice} 
                    tagList={tagList} 
                    fnList={{
                        onFilter: (value)=>{
                            this.setState({
                                filterPrice: value
                            },()=>{
                                this.onFilter(value)
                            })
                        }
                    }
                }/>
            )
        }
    }
    renderContent = () =>{
        const {categoryDetail, viewMode, category} = this.state;
        if(categoryDetail) {
            return(
                <div>
                    <MyUI 
                        type={(viewMode == 1 && "sc_product_list") || (viewMode == 0 && "sc_product_grid")}
                        data={category}
                    />
                    { this.renderPagination() }   
                </div>
            )
        }
    }
    render(){
        const {categoryDetail} = this.state;

        if(categoryDetail){
            return(
                <div className="master-wrapper-content">
                    {
                        this.renderHelmet()
                    }
                    {
                        this.renderBreakcumb()
                    }
                    <div className="master-column-wrapper">
                        <div className="center-2">
                            <div className="page category-page">
                                <div className="page-title">
                                    <h1>{categoryDetail.GrpMaterialName}</h1>
                                </div>
                                <div className="page-body">
                                    <div className="category-description">
                                        { this.renderFilterTop() }
                                        { this.renderContent() }                      
                                    </div>
                                </div>
                            </div>
                        </div>
                        { this.renderFilterSide() }
                    </div>
                </div>
            );
        }
        return <div></div>
    }
} 
export default SC_Category