import React from 'react'
import Types from './index';
class Footer extends React.Component {    
  render(){
    const {type} = this.props;
    // console.warn("render MyUI:",this.props,type,MyUITypes.Types);
    if(type && Types.Types[type]){
      return Types.Types[type](this.props);
    }
    console.warn("Footer no type",this.props);
    return (
      <div></div>
    )
  } 
}

export default Footer