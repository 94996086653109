import React from 'react'
import {useEffect, useRef } from 'react'
import {MyModal, MyUI} from 'apps/Components'
import { useSelector } from 'react-redux'
import {CButton} from 'components/MyCore'
import {HText, HAuth, HConfig, HFormat, HLink, HApiAxios} from 'apps/Helpers'
import HGA from 'helpers/GA'
import 'apps/shoppingCart/styles.css'
import './styles/header_1.css'

const TheHeader = () => {
  const ref = useRef();
  const [open, setOpen] = React.useState(false);
  const [showAccount, setShowAccount] = React.useState(false);
  const [showMiniCart, setShowMiniCart] = React.useState(false);
  const [showSidebar, setShowSidebar] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);

  const appLogo = useSelector(state => state.shop.appLogo);
  const categoryMenu = useSelector(state => state.shop.categoryMenu)
  const adminButtons = useSelector(state => state.shop.adminButtons)
  const wishlist = useSelector(state => state.shop.wishlist)
  const productInCart = useSelector(state => state.shop.cartProducts)
  const sumCart = useSelector(state => state.shop.total)
  const isLoggedIn = HAuth.isLoggedIn();
  const name = HConfig.fnList.getAuthName();
  
  const collapseClick=()=>{
    setOpen(!open)
  }
  const showHoverAccount=(value)=>{
    setShowAccount(value)
  }
  const showHoverMiniCart = (value) => {
    if (value === true) {
      setTimeout(() => setShowMiniCart(value), 500);
    } else {
      setShowMiniCart(value);
    }
  };
  const handleClickOutside = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      showHoverAccount(false)
      showHoverMiniCart(false)
      setShowSidebar(false)
      setShowSearch(false)
    }
  };
  const showMenu=()=>{
    if(window.location.pathname == '/'){
      return true;
    }
    else if(open == true){
      return true;
    }
    return false;
  }
  const showMobileMenu=(menu, value)=>{
    if(menu == 'account'){
      setShowAccount(value);
      setShowSearch(!value);
      setShowMiniCart(!value);
      setShowSidebar(!value)
    }
    else if(menu == 'cart'){
      setShowAccount(!value);
      setShowSearch(!value);
      // setShowMiniCart(value);
      setShowSidebar(!value)
    }
    else if(menu == 'sidebar'){
      setShowAccount(!value);
      setShowSearch(!value);
      setShowMiniCart(!value);
      setShowSidebar(value)
    }
    else if(menu == 'search'){
      setShowAccount(!value);
      setShowSearch(value);
      setShowMiniCart(!value);
      setShowSidebar(!value)
    }
  }
  const refreshCache =()=>{
    HApiAxios.generic({
      request:{
        method: 'POST',
        url: 'DashboardForWebsite/RefreshCache'
      },
      data:{
        Id: 'ALL'
      },
      successCallBack: (response)=>{
        window.location.reload()
      },
      errorCallBack: (error, response)=>{
        
      }
    })
  }
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  return (
    <div className="header header_1">
        <div className="header-upper">
            <div className="center">
                <div className="header-left">
                {
                  (adminButtons?.ShowBtnRefreshCache == true) &&
                  <div className="header-left-item">
                    <CButton color="secondary" onClick={()=>refreshCache()}><i className="fa fa-refresh"/></CButton>
                  </div>
                }
                {
                  (adminButtons?.ShowBtnAdmin == true) &&
                  <div className="header-left-item">
                    <CButton color="secondary" onClick={()=>HLink.push('/admin-content-material')}><i className="fa fa-pencil-square"/></CButton>
                  </div>
                }
                {
                  HConfig.fnList.getIsDevMode() == true &&
                  <div className="header-left-item">
                    <CButton color="secondary" onClick={()=>{
                      MyModal.Helpers.showMyUI("sc_editor_testapi",{},{
                        title: 'Test api',
                        closeButton: true,
                        closeOnBackdrop: false,
                      })
                    }}>Test API</CButton>
                  </div>
                }
                </div>
                <div className="header-right">
                  {!HAuth.isLoggedIn() && <div className="header-right-item dFaCjC"><MyUI type="sc_login" uiType={1}/></div>}
                  <div className="header-links-wrapper" 
                  >
                      <MyUI
                        type="sc_dropdown" 
                        renderToggle={({showDropdown}={})=>{
                          return <label onMouseEnter={()=>showDropdown && showDropdown()}>{isLoggedIn ? `${HText.get("text_account_user")} ${name}` : HText.get("text_account")}</label>
                        }}
                        renderContent={()=>{
                          return(
                            <div ref={ref} className="header-links" style={{display: "block"}} >
                              <ul>
                                {
                                  isLoggedIn == false &&
                                  <li><a className="ico-login modal-login" onClick={()=>{
                                    MyModal.Helpers.showMyUI("sc_login",{},{
                                      title: HText.get("text_welcom_login"),
                                      closeButton: true,
                                      size: 'lg'
                                    });
                                    HGA.trackingEventClick({
                                      action:'click_header_login'
                                    })
                                  }}>{HText.get("text_menu_login")}</a></li>
                                }
                                {
                                  isLoggedIn == true &&
                                  <li>
                                    <a href="/account-bills" style={{backgroundPosition:'left -392px'}} onClick={()=>{
                                      HGA.trackingEventClick({
                                        action:'click_history_bill'
                                      })
                                    }}>
                                      <i className="fa fa-tasks" style={{marginLeft: -23, marginRight: 10, color: '#898989'}}/>
                                      <span>{HText.get("text_menu_bills")}</span>
                                    </a>
                                  </li>
                                }
                                {
                                  isLoggedIn == true &&
                                  <li><a className="ico-logout" onClick={()=>HAuth.logOut()}>{HText.get("text_menu_logout")}</a></li>
                                }
                              </ul>
                            </div>
                          )
                        }}
                      />
                  </div>                                 
                  <div className="header-right-item" 
                        style={{
                            position: "relative"
                        }}
                  >
                      <MyUI
                        onShow = {showMiniCart} 
                        type="sc_dropdown" 
                        renderToggle={({showDropdown}={})=>{
                          return(
                            <CButton onClick={()=>{HLink.push("/cart")}} style={{position: "relative", height: 40, padding: "0.7rem 0.5rem"}} onMouseEnter={()=>showDropdown && showDropdown()}>
                              <i className="fa fa-shopping-bag"/>
                              <span style={{marginLeft: 5}}>{HText.get("text_menu_bag")}</span>
                              {
                                  productInCart?.length>0 && 
                                  <span className="cart-qty">
                                      ({productInCart.length})
                                  </span>
                              }
                            </CButton>
                          )}
                        }
                        renderContent={()=>{
                          return(
                            <MyUI 
                              type="sc_modal_mini_shoppingcart" 
                              items={productInCart} 
                              total={sumCart}
                              style={(productInCart?.length>0) ? {display: "block"} : {display: 'none'}} 
                            />
                          )
                        }}
                      />
                  </div>
                  <div className="header-right-item" style={{marginLeft: "2rem", marginRight: "1rem"}}>
                      <CButton
                          title={HText.get("text_menu_wishlist")}
                          style={{padding: 0}}
                          onClick={()=>{
                              HGA.trackingEventClick({action:'click_header_cart'})
                              HLink.push("/wishlist")
                          }}
                      >
                          <i className="fa fa-heart" style={{marginTop: 7}}/>
                      </CButton>
                  </div>
                </div>
            </div>
        </div>
        <div className="header-middle">
          <div className="center">
            <div className="header-logo">
              <a href="/" className="logo">
                <img src={appLogo}/>
              </a>
            </div>
            <div className="header-menu-parent">
              <div className={`header-menu categories-in-side-panel ${showSidebar ? 'open': ''}`}>
                {/* <div className="close-menu" onClick={()=>setShowSidebar(false)}>
                  <span>Close</span>
                </div> 
                
                <ul className="mega-menu">
                  {
                    categoryMenu && categoryMenu.map((v,i)=>{
                      return(
                        <li className="mega-menu-categories root-category-items" key={i} onClick={()=>{
                          HGA.trackingEventClick({
                            action:'click_item_menu_category_mobile',
                            label: v.Name
                          })
                        }}>
                          <a href={v.APIUrl}><span>{v.Name}</span></a>
                        </li>
                      )
                    })
                  }
                </ul> */}
                <MyUI 
                  type="sc_menu_mobile" 
                  headerList={[]}
                  categoryList={categoryMenu}
                  showSidebar={showSidebar}
                  fnList={{
                    fnSetShowSidebar: (value)=>{
                      setShowSidebar(value)
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div id="header-sticky">
          <div className="header-lower">
            <MyUI type="sc_navbar" data={categoryMenu}/>
            <MyUI type="sc_header_search_animation"/>
          </div>
        </div>
        <div className={`overlayOffCanvas ${(showSidebar || showMiniCart || showAccount || showSearch)? 'show' : ''}`} onClick={()=>{
          showHoverAccount(false)
          showHoverMiniCart(false)
          setShowSidebar(false)
          setShowSearch(false)
        }}></div>
        <div className="responsive-nav-wrapper-parent" ref={ref} style={showSidebar ? {zIndex: '1020'} : {}}>
          <div className="responsive-nav-wrapper stick">
            <div className="menu-title" onClick={()=>showMobileMenu("sidebar", true)}>
              <span>Menu</span>
            </div>
            <div className="shopping-cart-link" onClick={()=> {
              showMobileMenu("cart", true) ;
              setShowMiniCart(!showMiniCart)
            }}>
              <span>Giỏ hàng</span>
              {productInCart?.length > 0 && <span className="cart-badge">{productInCart.length}</span>}
              <div className="flyout-cart-wrapper" style={showMiniCart ? {display: "block"} : {display: 'none'}}>
                <MyUI type="sc_modal_mini_shoppingcart" items={productInCart} total={sumCart}/>
              </div>
            </div>
            <div className="personal-button"  onClick={()=>showMobileMenu("account", true)}>
              <span>Personal menu</span>
              <div className="header-links" style={showAccount ? {display: "block"} : {display: 'none'}}>
                <ul>
                  {
                    isLoggedIn == false &&
                    <li><a className="ico-login modal-login" onClick={()=>{
                      MyModal.Helpers.showMyUI("sc_login",{},{
                        title: HText.get("text_welcom_login"),
                        closeButton: true,
                        size: 'lg'
                      });
                      HGA.trackingEventClick({
                        action:'click_header_login_mobile'
                      })
                    }}>{HText.get("text_menu_login")}</a></li>
                  }
                  <li>
                    <a href="/wishlist" className="ico-wishlist" onClick={()=>{
                      HGA.trackingEventClick({
                        action:'click_header_wish_mobile'
                      })
                    }}>
                      <span className="wishlist-label">{HText.get("text_menu_wishlist")}</span>
                      <span className="wishlist-qty">({wishlist && wishlist.length})</span>
                    </a>
                  </li>
                  {
                    isLoggedIn == true &&
                    <li>
                      <a href="/account-address" style={{backgroundPosition:'left -392px'}} onClick={()=>{
                        HGA.trackingEventClick({
                          action:'click_header_address_mobile'
                        })
                      }}>
                        <i className="fa fa-address-book" style={{marginLeft: -30, marginRight: 15, color: '#898989'}}/>
                        <span>{HText.get("text_menu_address_book")}</span>
                      </a>
                    </li>
                  }
                  {
                    // (isLoggedIn == true && HConfig.fnList.getIsDevMode() == true) &&
                    isLoggedIn == true &&
                    <li>
                      <a href="/account-bills" style={{backgroundPosition:'left -392px'}} onClick={()=>{
                        HGA.trackingEventClick({
                          action:'click_history_bill_mobile'
                        })
                      }}>
                        <i className="fa fa-tasks" style={{marginLeft: -30, marginRight: 15, color: '#898989'}}/>
                        <span>{HText.get("text_menu_bills")}</span>
                      </a>
                    </li>
                  }
                  {
                    isLoggedIn == true &&
                    <li><a className="ico-logout" onClick={()=>HAuth.logOut()}>{HText.get("text_menu_logout")}</a></li>
                  }
                </ul>
              </div>
            </div>
            <div className="search-wrap" onClick={()=>showMobileMenu("search", true)}>
              <span>Tìm kiếm</span>
              <MyUI type="sc_header_search" style={showSearch ? {display: "block"} : {display: 'none'}}/>
            </div>
          </div>
        </div>
    </div>
  )
}

export default TheHeader