import React from 'react'
import './styles/sc_admin_list.css'
import {HApiAxios, HUtils, HConfig, HFormat} from '../../../../Helpers'
import {MyUI, MyModal} from '../../../../Components'
import {CButton} from '../../../../../components/MyCore'
 
class SC_AdminList extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            data: null,
            total: 0,
            pageSize: 10,
            currentPage: 1,
            apiUrl: null,
            filter: {
                TextSearch: "",
            }
        }
    }
    componentDidMount(){
        this.requestList()
    }
    requestList=({customQuery}={})=>{
        const {APIUrl, tab} = this.props;
        const {currentPage} = this.state;
        if(APIUrl){
            this.setState({
                isLoading: true
            },()=>{
                HApiAxios.generic({
                    request:{
                        method: 'POST',
                        url: APIUrl
                    },
                    data: {
                        PageSize: 10,
                        Page: currentPage,
                        // OrderByJson: '[{"FieldName":"Title","Method":"ascending","OrderNo":1}]',//descending
                        ...customQuery
                    },
                    successCallBack: (response)=>{
                        const _data = HUtils.Obj.get(response, "Data.Data");
                        const _total = HUtils.Obj.get(response, "Data.Total");
                        if(_data){
                            this.setState({
                                data: _data,
                                total: _total
                            })
                        }
                        if(tab == 3){
                            const _options = HUtils.Obj.get(response, "Data.ExtraData.Option");
                            if(_options){
                                this.setState({
                                    options: _options
                                })
                            }
                        }
                        this.setState({
                            isLoading: false
                        })
                    },
                    errorCallBack: (error, response)=>{
                        this.setState({
                            isLoading: false
                        })
                    }
                })
            })
        }
    }
    requestActive=(item)=>{
        const {title} = this.props;
        const {filter} = this.state;
        if(item){
            MyModal.Helpers.showConfirm(`Bạn có chắc muốn${item.IsActive==true?' ngừng ':' '}đăng ${title} này?`, {title:'Xác nhận',onOK: (cModal, {fnClose})=>{
                HApiAxios.generic({
                    request: {
                        method: 'POST',
                        url: 'Dashboard/UpdateActiveWithSlug'
                    },
                    data: {
                        Slug: item.Slug,
                        IsActive: !item.IsActive
                    },
                    successCallBack: (response)=>{
                        this.requestList({customQuery: filter})
                        fnClose && fnClose()
                    },
                    errorCallBack: (error, response)=>{
                        fnClose && fnClose()
                    }
                })
            }})
        }
    }
    requestSetHeader=(item)=>{ // for topic management
        if(item){
            MyModal.Helpers.showConfirm(`Bạn có chắc muốn hiển thị topic này ở header?`, {title:'Xác nhận',onOK: (cModal, {fnClose})=>{
                HApiAxios.generic({
                    request: {
                        method: 'POST',
                        url: 'Dashboard/UpdateDataWithSlug'
                    },
                    data: {
                        Slug: item.Slug,
                        Values: [{FieldName: 'IsHeader', NewValue: !item.IsHeader}]
                    },
                    successCallBack: (response)=>{
                        window.location.reload()
                        fnClose && fnClose()
                    },
                    errorCallBack: (error, response)=>{
                        fnClose && fnClose()
                    }
                })
            }})
        }
    }
    requestSetFooter=(item)=>{ // for topic management
        if(item){
            MyModal.Helpers.showConfirm(`Bạn có chắc muốn hiển thị topic này ở footer?`, {title:'Xác nhận',onOK: (cModal, {fnClose})=>{
                HApiAxios.generic({
                    request: {
                        method: 'POST',
                        url: 'Dashboard/UpdateDataWithSlug'
                    },
                    data: {
                        Slug: item.Slug,
                        Values: [{FieldName: 'IsFooter', NewValue: !item.IsFooter}]
                    },
                    successCallBack: (response)=>{
                        window.location.reload()
                        fnClose && fnClose()
                    },
                    errorCallBack: (error, response)=>{
                        fnClose && fnClose()
                    }
                })
            }})
        }
    }
    getOptionsParent=({exception}={})=>{// for topic management
        // const {data} = this.state;
        // let _options = []

        // if(data){
        //     let _dataFilter = [];
        //     if(exception){
        //         _dataFilter = data.filter((i)=>i.ParentId == null && i.Id != exception.Id)
        //     }
        //     else{
        //         _dataFilter = data.filter((i)=>i.ParentId == null)
        //     }
        //     if(_dataFilter && _dataFilter.length>0){
        //         for(let i=0; i<_dataFilter.length; i++){
        //             const _item = _dataFilter[i]
        //             _options.push({Value: _item.Id, Text: _item.Title})
        //         }
        //     }
        // }
        return this.state.options
    }
    onChangePage=(index)=>{
        const {filter} = this.state;
        this.setState({
            currentPage: index
        },()=>{
            this.requestList({customQuery: filter})
        })
    }
    onEdit=(item)=>{
        const {tab} = this.props;
        let _modalType = null;
        let _propsModal = null;
        if(tab == 0){
            _modalType = "sc_editor_product";
        }
        else if(tab == 1){
            _modalType = "sc_editor_blog";
        }
        else if(tab == 3){
            _modalType = "sc_editor_pagecontent";
            _propsModal = {
                options:  this.getOptionsParent({exception: item})
            }
        }

        if(item){
            MyModal.Helpers.showMyUI(_modalType,{
                data: item,
                noRefreshWenClose: true,
                ... _propsModal && _propsModal,
                fnList:{
                    updateProductInfo: (newData)=>{
                        if(newData){                            
                            HUtils.updateDataWithNewData(item,newData);//chhi update nhung filed o newData
                            this.forceUpdate();
                        }
                    },
                    updateBlog: (newData)=>{
                        if(newData){                            
                            HUtils.updateDataWithNewData(item,newData);//chhi update nhung filed o newData
                            this.forceUpdate();
                        }
                    },
                    updateContent: (newData)=>{
                        if(newData){                            
                            HUtils.updateDataWithNewData(item,newData);//chhi update nhung filed o newData
                            this.forceUpdate();
                        }
                    },
                }
            },{
                title: `Chỉnh sửa - ${item.MaterialName || item.Title}`,
                className: 'sc_modal_editor',
                closeOnBackdrop: false,
                closeButton: true
            })
        }
    }
    onDelete=(item)=>{
        const {tab, title} = this.props;
        if(item){
            let _url = null;
            if(tab == 0){
                _url = `AlliancePosMaterial/Delete/${item.MaterialId}`;
            }
            else if(tab == 1){
                _url = `EzyWeb_BlogPost/Delete/${item.Id}`;
            }
            else if(tab == 3){
                _url = `EzyWeb_Topic/Delete/${item.Id}`;
            }
            MyModal.Helpers.showConfirm(`Bạn có chắc muốn xóa ${title} này?`, {title: "Xác nhận xóa", onOK: (cModal, {fnClose})=>{
                HApiAxios.generic({
                    request:{
                        method: 'POST',
                        url: _url
                    },
                    data: {},
                    successCallBack: (response)=>{
                        fnClose && fnClose();
                        window.location.reload();
                    },
                    errorCallBack: (error, response)=>{
                        fnClose && fnClose()
                    }
                })
            }})
        }
    }
    onAddNew=()=>{
        const {tab} = this.props;
        if(tab == 0){
            MyModal.Helpers.showMyUI("sc_editor_product_full",{
                isNew: true
            },{
                title: "Thêm mới sản phẩm",
                closeOnBackdrop: false,
                closeButton: true
            })
        }
        else if(tab == 1){
            MyModal.Helpers.showMyUI("sc_editor_blog",{
                isNew: true
            },{
                title: "Thêm mới bài viết",
                closeOnBackdrop: false,
                closeButton: true
            })
        }
        else if(tab == 3){
            MyModal.Helpers.showMyUI("sc_editor_pagecontent",{
                isNew: true,
                options: this.getOptionsParent()
            },{
                title: "Thêm mới topic",
                closeOnBackdrop: false,
                closeButton: true
            })
        }
    }
    onChangeFilter=(field, value)=>{
        let {filter} = this.state;
        if(value != filter[field]){
            if(field === "IsActive" && value === "ALL"){
                delete filter[field]
            }
            else{
                filter[field] = value;
            }
            this.setState({
                filter: {...filter}
            },()=>{
                this.requestList({customQuery: filter})
            })
        }
    }
    renderTable=()=>{
        const {data} = this.state;
        const {title, tab} = this.props;
        let _ui=[]
        if(data && data.length>0){
            for(let i=0; i<data.length; i++){
                const _item = data[i];
                const _img = _item.ImageUrlThumnail || HUtils.Obj.get(_item, "Files_0.Photos[0].FileUrl") ||  HConfig.fnList.getConfig("imageProductDefault");
                _ui.push(
                    <tr key={i}>
                        <td style={{width: '100%'}}>
                            <div style={{width: '100%',display:'flex'}}>
                                <a href={_item.Slug} className="product">
                                    <img src={_img} loading="lazy" />
                                </a>
                                <div className="name">
                                    <a href={_item.Slug}>{_item.MaterialName || _item.Title}</a>
                                    <div>{_item.MaterialCode}</div>
                                    {
                                        tab == 3 &&
                                        <div>
                                            <CButton className={_item.IsHeader == true ? 'active' : 'unactive'} onClick={()=>{this.requestSetHeader(_item)}}>Header</CButton>
                                            <CButton className={_item.IsFooter == true ? 'active' : 'unactive'} onClick={()=>{this.requestSetFooter(_item)}}>Footer</CButton>
                                        </div>    
                                    }
                                </div>   
                            </div>                         
                        </td>
                        <td style={{textAlign: 'center'}}>{HFormat.Date.formatDate(_item.LastUpdated)}</td>
                        <td className="action-button">
                            <CButton className="edit" onClick={()=>this.onEdit(_item)}>Chỉnh sửa</CButton>
                            <CButton className={_item.IsActive == true ? 'cancel' : 'publish'} onClick={()=>this.requestActive(_item)}>{_item.IsActive == true ? 'Hủy đăng' : 'Đăng'}</CButton>                            
                            <CButton className="delete btn btn-outline-danger" onClick={()=>this.onDelete(_item)}><i className="fa fa-trash"/></CButton>
                        </td>
                    </tr>
                )
            }
        }
        else{
            _ui.push(
                <div className="section" key="nodata">
                    <div className="title">Danh sách rỗng!</div>
                </div>
            )
            return _ui;
        }
        return(
            <table className="admin-list">
                <colgroup>
                    <col width="100%"/>
                    <col width="1"/>
                    <col width="1"/>
                </colgroup>
                <thead>
                    <tr>
                        <th>{title}</th>
                        <th style={{textAlign: 'center'}}>Ngày chỉnh sửa</th> 
                        <th style={{textAlign: 'center'}}>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        _ui
                    }
                </tbody>
            </table>
        )
    }
    renderPagination=()=>{
        const {data, total, pageSize, currentPage} = this.state;
        if(data){
            if(total > pageSize){
                return (
                    <MyUI type='react_pagination_box' 
                        forcePage={currentPage}
                        pageSize={pageSize} 
                        pageTotal={total||0}
                        containerClassName="pager"
                        hrefBuilder={(p)=>{
                            // return this.updateHref(p)
                        }}
                        onPageChange={({selected})=>{
                            this.onChangePage(selected + 1)
                        }}/>
                )
            }
        }
        return <div></div>
    }
    renderFilter=()=>{
        const {filter} = this.state;
        const {adminButtons} = this.props;
        return(
            <div className="admin-list" style={{marginBottom: 10, padding: 10}}>
                <div className="filter">
                    <div className="search">
                        <MyUI type="input_text" placeholder="Tìm kiếm" onKeyPress={(ev,v)=>{
                            if(ev.key === 'Enter'){
                                this.onChangeFilter("TextSearch", ev.currentTarget.value)
                            }
                        }} defaultValue={filter.TextSearch} onBlur={(e)=>this.onChangeFilter("TextSearch", e.currentTarget.value)}/>
                        <CButton color="primary" onClick={()=>this.requestList({customQuery: filter})}><i className="fa fa-search"/></CButton>
                    </div>
                    <div className="group-control">
                        <CButton color="primary" variant={(filter.IsActive != true && filter.IsActive != false) ? '': 'outline'} onClick={()=>this.onChangeFilter("IsActive", 'ALL')}>Tất cả</CButton>
                        <CButton color="primary" variant={filter.IsActive != true ? 'outline': ''} onClick={()=>this.onChangeFilter("IsActive", true)}>Đã đăng</CButton>
                        <CButton color="primary" variant={filter.IsActive != false ? 'outline': ''} onClick={()=>this.onChangeFilter("IsActive", false)}>Chưa đăng</CButton>
                    </div>
                    {
                        adminButtons && adminButtons.ShowBtnCreatePost &&
                        <div className="add-btn" onClick={()=>this.onAddNew()}>
                            <CButton color="success">Thêm mới</CButton>
                        </div>
                    }
                </div>
            </div>
        )
    }
    render(){
        const {isLoading} = this.state;
        return(
            <div>
                {
                    isLoading == true &&
                    <div className="admin-list" style={{padding: "20px", textAlign: "center"}}>
                        <MyUI type="spinner_dots"/>
                    </div>
                }
                {
                    isLoading!=true && this.renderFilter()
                }
                {
                    isLoading!=true && this.renderTable()
                }
                {
                    isLoading!=true && this.renderPagination()
                }
            </div>
        )
    }
}
export default SC_AdminList