import React from 'react'
import './styles/overlayseason.css'

class MyUI_OverlaySeason extends React.Component{
    componentWillUnmount(){
        this.removeAffectOverlay()
    }
    componentDidMount(){
        this.loadAffectOverlay()
    }
    loadAffectOverlay=()=>{
        const {configOverlay} = this.props;
        const type = configOverlay && configOverlay.overlaySeason;
        if(type){
            const script = document.createElement("script");
            script.src = `/_extra/OverlaySeason/${type}/index.js`;
            script.id = "script-overlayseason"
            document.body.appendChild(script);
        }
    }
    removeAffectOverlay=()=>{
        const script = document.getElementById("script-overlayseason");
        if(script && script.parentNode){
            script.parentNode.removeChild(script);
        }
    }
    render(){
        return(
            <div id="overlay-season">
                <div className="banner-top">
                    <div className="top-left">
                    </div>
                    <div className="top-right">
                    </div>
                </div>
            </div>
        )
    }
}
export default MyUI_OverlaySeason