import React from "react";
import "./styles/sc_navbar.css";

class SC_Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navActive: null,
    };
  }
  isHomePage = () => {
    if (window.location.pathname === "/") {
      return true;
    }
    return false;
  };
  onNavHover = (index) =>{
    this.setState({
        navActive: index
    })
  }
  onNavHoverLeave = () =>{
      this.setState({
          navActive: null
      })
  }
  renderNavbar = (data) => {
    const {navActive} = this.state;
    let _ui = [];
    for (let i = 0; i < data.length; i++) {
      const _item = data[i];
      const _APIUrl = _item.APIUrl;
      const _Name = _item.Name;
      const _Children = _item.Children;
      const _link = this.isHomePage() ? `#${_APIUrl}` : _APIUrl;
      _ui.push(
        <li key={i} onMouseLeave={()=>this.onNavHoverLeave()}>
          <a href={_link} onMouseEnter={()=>this.onNavHover(i)} onClick={()=>this.onNavHoverLeave()}>{_Name}</a>
          {
            _Children?.length > 0 && (
              <div className={ `menu-dropdown${navActive === i ? " show": ""}`}>
                <div className="menu-content">{this.renderNavDropdownContent(_Children)}</div>
            </div>
            )
          }
        </li>
      );
    }
    return <ul className="menu">{_ui}</ul>;
  };
  renderNavDropdownContent = (item)=>{
    let _ui = [];
    for(let i=0; i<item.length; i++){
      const _itm = item[i];
      const _children = _itm.Children;
      const _APIUrl =_itm.APIUrl;
      const _Name = _itm.Name;
      _ui.push(
        <div key={i} className="menu-block">
          <a href={_APIUrl} onClick={()=>this.onNavHoverLeave()}>{_Name}</a>
          {
            _children?.length>0 && (
              <ul className="sub-menu">
                {
                  _children.map((v,k)=>{
                    return(
                      <li key={k}>
                        <a href={v.APIUrl} onClick={()=>this.onNavHoverLeave()}>{v.Name}</a>
                      </li>
                    )
                  })
                }
              </ul>
            )
          }
        </div>
      )
    }
    return _ui;
  }
  render() {
    // const { data } = this.props;
    const data = [
      {
        APIUrl: 'top-and-bralette',
        Name: 'NEW ARRIVAL',
        Children: [
          {
            APIUrl: 'top-and-bralette',
            Name: 'TOP / BRALETTE',
            Children: [
              {
                APIUrl: 'aurora-shirt',
                Name: 'Aurora Shirt',
              },
              {
                APIUrl: 'lily-top',
                Name: 'Lily Top',
              }
            ]
          }
        ]
      },
      {
        APIUrl: 'dress',
        Name: "BEST SELLER",
        Children: [
          {
            APIUrl: 'dress',
            Name: 'DRESS',
            Children: [
              {
                APIUrl: 'lolita-dress',
                Name: 'Lolita Dress',
              },
              {
                APIUrl: 'malena-dress',
                Name: 'Malena Dress',
              }
            ]
          }
        ]
      },
      {
        APIUrl: "trouser-and-short",
        Name: "CLOTHING",
        Children: [
          {
            APIUrl: 'dress',
            Name: 'DRESS',
            Children: [
              {
                APIUrl: 'lolita-dress',
                Name: 'Lolita Dress',
              },
              {
                APIUrl: 'malena-dress',
                Name: 'Malena Dress',
              }
            ]
          },
          {
            APIUrl: 'top-and-bralette',
            Name: 'TOP / BRALETTE',
            Children: [
              {
                APIUrl: 'aurora-shirt',
                Name: 'Aurora Shirt',
              },
              {
                APIUrl: 'lily-top',
                Name: 'Lily Top',
              }
            ]
          }
        ]
      }
    ]
    if (data) {
      return (
        <div className="sc_navbar">
          {this.renderNavbar(data)}
        </div>
      );
    }
    return <div></div>;
  }
}
export default SC_Navbar;
