import React from 'react'
import {MyUI} from 'apps/Components';
import PageExt from 'components/MyPage/Types/_ext';
import {HApiAxios, HLocalStorage, HUtils, HUI, HConfig, HText} from 'apps/Helpers';
import HShopUtils from 'apps/shoppingCart/helpers/Utils'
import './styles/common.css'

class SC_Wishlist extends PageExt{
    constructor(props){
        super(props);
        this.state = {
            wishlist: [],
            totalCart: 0,
            marterialDiscountList: null
        }
    }
    componentDidMount(){
        const list = HLocalStorage.getObject("wishlist", []);
        this.setState({
            wishlist: list,
        },()=>{
            this.requestCheckInventoryProduct(list);
            this.recalcTotalCartBasedOnIsOrder(list);
            this.requestGetDiscountItem();
        })
    }
    requestCheckInventoryProduct=(data)=>{
        HApiAxios.generic({
            request: {
                method: "POST",
                url: "CacheInventoryAtMoment_HasCheck_QuantityOrder4Website/List"
            },
            data: {
                ListMaterialOrder4Web: this.getRequestDataCheckInventory(data)
            },
            successCallBack: (response)=>{
                const newData = HUtils.Obj.get(response, "Data.Data");
                if(newData){
                    this.updateCartAfterCheckInventory(newData);
                }
            },
            errorCallBack: (error, response)=>{
                console.warn("requestCheckInventoryProduct error, response", error, response)
            }
        })
    }
    requestGetDiscountItem = () =>{
        HApiAxios.generic({
            request: {
                method: "POST",
                url: "DashboardForWebsite/GetDiscountOnItemToday"
            },
            successCallBack: (response)=>{
                const data = HUtils.Obj.get(response, "Data");
                if(data){
                    this.setState({
                        marterialDiscountList: data
                    },()=>{
                        this.updateCartAfterCheckDiscount(data)
                    })
                }
            },
            errorCallBack: (error, response)=>{
                console.warn('requestGetDiscountItem error, response: ', error, response)
            }
        })
    }

    getRequestDataCheckInventory=(data)=>{
        let _arr = [];
        if(data){
            for(let i=0 ;i<data.length; i++){
                const item = data[i];
                _arr.push({MaterialId: item.MaterialId, QuantityOrder: item.Quantity})
            }
        }
        return _arr
    }
    getBillApplyDiscount=()=>{
        const {wishlist} = this.state;
        const {marterialDiscountList} = this.state;
        let _currentCart = wishlist;
        if(marterialDiscountList && Array.isArray(marterialDiscountList)){
            for(let i=0; i<_currentCart.length; i++){
                let _item = _currentCart[i];
                let _item_discount = marterialDiscountList.find((v)=>v.ItemId == _item.MaterialId);
                if(_item_discount){
                    if(_item_discount.DiscountMoney){
                        _item._SellPriceOriginal = _item.SellPrice;
                        _item._Discount_Text = _item_discount.Discount_Text;
                        _item._QuantityMin = _item_discount.QuantityMin
                        if(_item.Quantity>=_item_discount.QuantityMin){
                            _item._SellPriceDiscount = _item._SellPriceOriginal - _item_discount.DiscountMoney;
                        }
                        this.updateWishlistStore(_item, i)
                    }
                }
            }
        }
        return _currentCart;
    }
    getGroupAttribute = (data) =>{
        const _AttributeList = HUtils.Obj.get(data, "AttributeList");
        let _group = [];
        if (_AttributeList) {
          for(let i = 0; i<_AttributeList.length; i++){
              const _item = _AttributeList[i];
              if( _item?.Children.length > 0){
                _group.push(_item.Code)
            }
          }
        }
        return _group;
    }
    checkProducts=(data)=>{
        let _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions");
        let _orderItems = data.filter((v)=> v.IsOrder == true);
        if(_orderItems?.length == 0){
            HUI.Toast.showWarn(HText.get("text_wishlist_warning_noitems_order"));
            return false;
        }
        else{
            for(let i=0; i<_orderItems.length; i++){
                const item = _orderItems[i];
                const _dontCareInventory = HUtils.Obj.get(item,"Inventory.DontCareInventory");
                const _quantityInStock =  HUtils.Obj.get(item,"Inventory.Quantity");
                if( item.Quantity > _quantityInStock && _dontCareInventory !== true){
                    HUI.Toast.showWarn(HText.get("text_wishlist_warning_item_outofstock") && HText.get("text_wishlist_warning_item_outofstock")(item.MaterialName, _quantityInStock));
                    return false;
                }
                if(_haveProductOptions && item.AttributeList && !item.OrderDetail_AttributeList){
                    HUI.Toast.showWarn(HText.get("text_wishlist_warning_item_miss_field") && HText.get("text_wishlist_warning_item_miss_field")(item.MaterialName));
                    return false;
                }
            }
        }
        return true
    }
    recalcTotalCartBasedOnIsOrder=()=>{
        const {wishlist} = this.state;
        let _currentTotalCart = 0;
        if(wishlist){
            for(let i=0; i<wishlist.length;i++){
                const item = wishlist[i];
                if(item.IsOrder === false){
                    _currentTotalCart = _currentTotalCart -  (item.Quantity * item.SellPrice)
                }
                else{
                    if(item._QuantityMin && item.Quantity>=item._QuantityMin){
                       
                        _currentTotalCart = _currentTotalCart -  ((item.Quantity * item.SellPrice)-(item.Quantity * item._SellPriceDiscount))
                    }
                }
            }
        }
        this.setState({
            totalCart: _currentTotalCart
        })
    }
    refreshList = () =>{
        const list = HLocalStorage.getObject("wishlist", []);
        this.setState({
            wishlist: list
        })
    }
    updateCartAfterCheckInventory=(data)=>{
        const {wishlist} = this.state;
        let _currentWishList = wishlist;
        if(_currentWishList.length > 0 && data?.length > 0){
            for(let i=0; i<_currentWishList.length; i++){
                 for(let j=0; j<data.length; j++){
                     if(_currentWishList[i].MaterialId === data[j].MaterialId){
                        _currentWishList[i].Inventory = data[j];
                        this.updateWishlistStore(_currentWishList[i], i)
                     }
                 }
            }
            this.setState({
                wishlist: _currentWishList
            })
        }
    }
    updateCartAfterCheckDiscount=()=>{
        this.setState({
            wishlist: this.getBillApplyDiscount()
        },()=>this.recalcTotalCartBasedOnIsOrder())
    }
    updateWishlistStore = (product, index) =>{
        HShopUtils.updateProductToWishList({product: {...product, Quantity: 1, IsOrder: false}, index})
    }
    onAddToCart = () =>{
        const {wishlist} = this.state;
        if(this.checkProducts(wishlist)){
            this.setState({isAdding: true})
            for(let i=0; i<wishlist.length;i++){
                const item = wishlist[i];
                if(item.IsOrder == true){
                    HShopUtils.addProductToCart({
                        product: item,
                        productUpdate: item,
                        attributes: this.getGroupAttribute(item)
                    })
                }
            }
            setTimeout(()=>this.setState({isAdding: false},()=>HUI.Toast.showSuccess(HText.get("text_wishlist_add_to_bag_success"))), 500);
            this.refreshList();
        }
    }
    onChangeField=(field, value, index)=>{
        let {wishlist} = this.state;
        wishlist[index][field] = value;
        this.setState({
            wishlist: wishlist
        },()=>{
            if(field == "Quantity"){
                this.updateCartAfterCheckDiscount()
            }
            if(field == "OrderDetail_AttributeList"){
                this.updateWishlistStore(wishlist[index], index)
            }
        })
    }
    onRemoveItem = (index) =>{
        let {wishlist} = this.state;
        HShopUtils.removeProductWishList({product: wishlist[index]})
        wishlist = wishlist.filter((v, i)=>i!=index);
        this.setState({
            wishlist: wishlist
        })
    }
    renderHelmet = () =>{
        const meta = {
            MetaTitle: HText.get("text_wishlist_title"),
            MetaKeyWords: HText.get("meta_keywords_default_wishlist"),
            MetaDescription: HText.get("meta_description_default_wishlist")
        }
        return <MyUI type="sc_helmet" data = {meta} noindex={true}/>
    }
    renderContent = () =>{
        const {wishlist} = this.state;
        console.warn("wishlist", wishlist)
        return <MyUI type="sc_wishlist_table" data={wishlist} fnList = {{
            fnOnChangeField: (field, value, index)=>{
                this.onChangeField(field, value, index)
            },
            fnOnRemoveItem: (index)=>{
                this.onRemoveItem(index)
            }
        }}/>
    }
    renderAction = () =>{
        const {isAdding} = this.state;
        return(
            <div className="buttons">
                <button
                    className={`wishlist-add-to-cart-button ${isAdding ? " process": ""}`} 
                    onClick={()=>this.onAddToCart()}
                >
                    {HText.get("text_wishlist_btn_add_bag")} <i className="fa fa-shopping-basket"/>
                </button>
            </div>
        )
    }
    render(){
        const {wishlist} = this.state;
        if(wishlist){
            return(
                <div className="master-wrapper-content">
                    { this.renderHelmet() }
                    <div className="master-column-wrapper">
                        <div className="center-1 wishlist">
                            <div className="page-title"><h1>{HText.get("text_wishlist_title")}</h1></div>
                            <div className="page-body">
                                {
                                    wishlist.length>0 &&
                                    <div className="wishlist-content">
                                        {
                                            this.renderContent()
                                        }
                                        {
                                            this.renderAction()
                                        }
                                    </div>
                                }
                                {
                                    wishlist.length == 0 && 
                                    <div className="section">
                                        <div className="title">{HText.get("text_wishlist_no_items")}</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return <div></div>
    }
}
export default SC_Wishlist