import React from "react";
import SC_ProductShortDesc from "apps/shoppingCart/components/MyUI/Types/sc_product_shortdesc";
import { HFormat, HText, HUtils, HConfig } from "apps/Helpers";
import { MyUI } from "apps/Components";
import HShopUtils from "apps/shoppingCart/helpers/Utils";
import "./styles/sc_product_shortdesc.css"

class SC_ProductShortDesc_1 extends SC_ProductShortDesc {
  renderBasicInformationProduct = (data) => {
    const _Name = data.MaterialName;
    const _MaterialCode = data.MaterialCode;
    const _Price = data.SellPriceText;
    const _SellPrice = data.SellPrice;
    const _Description = data.ShortDescription;
    const _IsCall4WebOrder = data.IsCall4WebOrder;
    const _Discount_Text = HUtils.Obj.get(data, "DiscountInfo.Discount_Text");
    const _QuantityMin = HUtils.Obj.get(data, "DiscountInfo.QuantityMin");
    const _DiscountMoney = HUtils.Obj.get(data, "DiscountInfo.DiscountMoney");
    const _PriceAfterDiscount = _SellPrice - _DiscountMoney;
    return (
      <>
        {_Name && (
          <div className="product-name">
            <h1 style={{ marginBottom: 0 }}>{_Name}</h1>
          </div>
        )}
        <div
          className="product-sku"
          style={{ padding: "5px 0px", fontSize: 15 }}
        >
          {_MaterialCode}
        </div>
        {_Description && (
          <div className="short-description">{_Description}</div>
        )}
        {_IsCall4WebOrder !== true && (
          <div className="prices">
            {_Price && (
              <div className="product-price">
                {_QuantityMin <= 1 && (
                  <span
                    content={_PriceAfterDiscount}
                    className="price-value-coupon"
                  >
                    {HFormat.Money.formatMoneyForAjuma(_PriceAfterDiscount)}
                  </span>
                )}
                <span
                  content={_Price}
                  className={`price-value${_QuantityMin <= 1 ? " origin" : ""}`}
                >
                  {_Price}
                </span>
              </div>
            )}
            {_QuantityMin > 1 && _Discount_Text && (
              <div className="product-coupon">
                <div>{_Discount_Text}</div>
              </div>
            )}
            {_QuantityMin > 1 && _Discount_Text && <br className="space" />}
          </div>
        )}
        {_IsCall4WebOrder === true && (
          <div className="product-price">
            <span
              className="price-value"
              style={{ color: "var(--color-danger)" }}
            >
              {HText.get("text_detail_contact_order")}
            </span>
          </div>
        )}
      </>
    );
  };
  renderProductAction = (data) => {
    const { product, isValidAttributeProduct } = this.state;
    const { Quantity } = product;
    const _disableAddCart = !isValidAttributeProduct;
    let _isInWishList = HShopUtils.checkItemInStore({
      store: "wishlist",
      item: data,
      isHaveAttribute: false,
    });
    return (
      <div className="add-to-cart sevenspikes-ajaxcart">
        <div className="add-to-cart-panel">
          {this.checkShowAddCartArea(data) == true && (
            <div style={{ display: "flex" }}>
              <MyUI
                type="sc_input_quantity"
                isBtnVertical={true}
                value={Quantity}
                className="quantity-control"
                onChange={(value) => {
                  this.onChangeQuantity(value);
                }}
              />
              <div className="ajax-cart-button-wrapper">
                <button
                  disabled={_disableAddCart}
                  className="button-1 add-to-cart-button"
                  onClick={() => this.onAddCart(data)}
                >
                  <i className="fa fa-shopping-bag" />
                  <span>{HText.get("text_product_add_cart")}</span>
                </button>
              </div>
            </div>
          )}
          <div className="add-to-wishlist" style={{ margin: "5px" }}>
            {/* <input type="button" className={`button-2 add-to-wishlist-button${_isInWishList ? " active": ""}`} title="Yêu thích" onClick={()=>this.onAddWishList(data)}/> */}
            <button
              title={HText.get("text_product_add_wishlist")}
              className={`add-to-wishlist-button${
                _isInWishList ? " active" : ""
              }`}
              onClick={() => this.onAddWishList(data)}
            >
              <i className="fa fa-heart" />
            </button>
          </div>
        </div>
      </div>
    );
  };
  renderSocialMedia = () =>{
    const socialList = HConfig.fnList.getConfig("socialList", []);
    if(socialList?.length>0){
        return(
            <div className="shop-social">
                {
                    socialList.map((v, i)=>{
                        return <a key={i} href={v.Link}><i className={v.Icon}/></a>
                    })
                }
            </div>
        )
    }
  }
  render() {
    const { data } = this.props;
    this.initProductAttributeFromData(data);
    if (data) {
      const _InventoryQuantity = HUtils.Obj.get(data, "Inventory.Quantity");
      const _ContentDisplayOnWeb = HUtils.Obj.get(
        data,
        "Inventory.ContentDisplayOnWeb"
      );
      const _IsCall4WebOrder = data.IsCall4WebOrder;
      return (
        <div className="overview">
          {this.renderBasicInformationProduct(data)}
          {this.renderAttributeProduct(data)}
          {this.renderProductAction(data)}
          {_InventoryQuantity <= 0 && _IsCall4WebOrder !== true && (
            <div style={{ color: "var(--color-danger)", fontWeight: "bold" }}>
              *{_ContentDisplayOnWeb}
            </div>
          )}
          {this.renderSocialMedia()}
        </div>
      );
    }
    return <div></div>;
  }
}
export default SC_ProductShortDesc_1;
