import React from 'react'
import { HText } from 'apps/Helpers'
import './styles/sc_filter_color.css'

class SC_FilterColor extends React.Component{
    render(){
        const {options} = this.props;
        if(options?.length>0){
            return (
                <div className="sc_filter_color">
                    <div className="filter-title">{HText.get("text_filter_color")}</div>
                    <div className="filter-range">
                        {
                            options.map((v,i)=>{
                                return(
                                    <div key={i} className="filter-value"><div style={{backgroundColor: v.Value}} title={v.Text}></div></div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }
        return <div></div>
    }
}
export default SC_FilterColor