import React from 'react'
import { MyUI } from 'apps/Components'
import { HText } from 'apps/Helpers'

class sc_product_list extends React.Component{
    render(){
        const {data} = this.props;
        if(data && data.length>0){
            return(
                <div className="product-list" style={{position: "relative"}}>
                    <div className="item-grid">
                        {data.map((product,index)=>{
                            return <MyUI type="sc_card_product" key={index} data={product}/>
                        })} 
                    </div>
                    {/* <div className="productPanelAjaxBusy" style={{display: "none"}}></div>
                    <div className="clear"></div>
                    <div className="infinite-scroll-loader">Loading more products ...</div> */}
                </div>
            )
        }
        return (<div className="no-data">{HText.get("text_category_no_items")}</div>)
    }
}
export default sc_product_list