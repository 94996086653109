import React from "react";
import { HApiAxios, HUtils, HLink, HUI, HConfig } from "../../../../Helpers";
import { MyModal, MyUI } from "../../../../Components";
import View from "../../../../../libs/CKEditorUI/ckeditor5-ui/src/view";
import { CButton } from "../../../../../components/MyCore";
import PaginationWithData from "../../../../../libs/ReactPaginate/PaginationWithData";
import MyTableFilterData from "../../../../../components/MyTableFilter/MyTableFilterData";
import MyTableFilter from "../../../../../components/MyTableFilter/MyTableFilter";
import MyIconSvg from "../../MyIconSvg/MyIconSvg";
import HShopUtils from "../../../helpers/Utils";
import "./styles/sc_editor_blog.css"

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

class ImageAvailableButton extends View {
  constructor(locale) {
    super(locale);
    const bind = this.bindTemplate;
    this.set("elementClass", "ck-button");
    this.setTemplate({
      tag: "button",
      children: ["Chọn ảnh có sẵn"],
      attributes: {
        class: ["ck", bind.to("elementClass")],
      },
      on: {
        click: bind.to("clicked"),
      },
    });
  }
  render() {
    super.render();
  }
}
class InsertImageAvailable {
  constructor(editor, { data } = {}) {
    this._editor = editor;
    this.data = data;
  }
  init() {
    const editor = this._editor;

    editor.ui.componentFactory.add("insertImage", (locale) => {
      const view = new ImageAvailableButton(locale);
      view.on("clicked", () => {
        return MyModal.Helpers.showMyUI2(
          "sc_modal_select_photos",
          {
            data: this.data,
            textSubmit: "Chèn ảnh",
            submit: (items) => {
              if (Array.isArray(items)) {
                for (let i = 0; i < items.length; i++) {
                  setTimeout(() => {
                    editor.model.change((writer) => {
                      const imageElement = writer.createElement("image", {
                        src: items[i],
                      });
                      editor.model.insertContent(
                        imageElement,
                        editor.model.document.selection,
                        "after"
                      );
                    });
                  }, 1000);
                }
              }
            },
          },
          {
            title: "Chọn ảnh",
            closeButton: true,
            className: "mmui2",
            closeOnBackdrop: false,
          }
        );
      });
      return view;
    });
  }
}
class SC_Editor_Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataProduct: {},
      tabIndex: 0,
      dataWillUpdate: {},
      isChangeImage: false,
      dataSeperateDidUpdate: {},
      imageFnStyle: 1,
      reviewMaterialChecked: false,
      currentPageMaterialRelative: 1,
    };
  }
  componentDidMount() {
    const { data } = this.props;
    if (data) {
      // if (data.ImageUrlList && data.ImageUrlList.length > 0) {
      //   if (data.ImageUrlList[0]._IsAvatar == null) {
      //     data.ImageUrlList[0]._IsAvatar = true;
      //   }
      // }
      // dataProduct.MaterialName = data.MaterialName;
      // dataProduct.MaterialId = data.MaterialId;
      // dataProduct.ShortDescription = data.ShortDescription;
      // dataProduct.FullDescription = data.FullDescription || "";
      // dataProduct.MetaTitle = data.MetaTitle || "";
      // dataProduct.MetaKeyWords = data.MetaKeyWords || "";
      // dataProduct.MetaDescription = data.MetaDescription || "";
      // dataProduct.Slug = data.Slug || "";
      this.setState({
        ImageUrl: data.ImageUrl,
        ImageUrlList: data.ImageUrlList,
        dataProduct: {...data}
      },()=>{
        this.requestHeaderProduct();
        this.requestRelatedProduct();
      });
    }
  }
  requestUpdateMaterial = () => {
    const { data, fnList, isNew } = this.props;
    const { dataWillUpdate} = this.state;

    let _values = [];
    let _keys = Object.keys(dataWillUpdate);
    for (let i = 0; i < _keys.length; i++) {
      const k = _keys[i];
      if (isNew) {
        _values.push({ FieldName: k, NewValue: dataWillUpdate[k] });
      } else if (dataWillUpdate[k] !== data[k]) {
        _values.push({ FieldName: k, NewValue: dataWillUpdate[k] });
      }
    }
    if(this.checkIsUpdate(_values)){
      const _url =
        isNew == true
          ? "Dashboard/CreateDataWithSlug"
          : "Dashboard/UpdateDataWithSlug";
      const _query =
        isNew == true
          ? { Values: _values, Type: "Material" }
          : { Values: _values, Slug: data.Slug };
      this.setState(
        {
          isSubmiting: true,
        },
        () => {
          HApiAxios.generic({
            request: {
              method: "POST",
              url: _url,
            },
            data: _query,
            successCallBack: (response) => {
              HUI.Toast.showSuccess(response.Msg);
              const _slug = HUtils.Obj.get(response, "Data.Slug");
              if (isNew || _slug != data.Slug) {
                if (_slug) {
                  HLink.push(_slug);
                }
              } else {
                fnList && fnList.updateProductInfo(response.Data);
              }
              this.setState(
                {
                  isSubmiting: false,
                },
                () => {
                  MyModal.Helpers.hide();
                }
              );
            },
            errorCallBack: (error, response) => {
              this.setState({
                isSubmiting: false,
              });
            },
          });
        }
      );
    }
  };
  requestUpdaterMaterialHeader = (_query, index) => {
    const { fnList } = this.props;
    if (_query) {
      HApiAxios.generic({
        request: {
          method: "POST",
          url: "Material_Page_Content_Info/UpdateFields",
        },
        data: _query,
        successCallBack: (response) => {
          HUI.Toast.showSuccess(response.Msg);
          const _newData = HUtils.Obj.get(response, "Data");
          if (_newData) {
            let { dataProduct } = this.state;
            dataProduct.HeaderList[index] = { ..._newData };
            this.setState(
              {
                dataProduct: JSON.parse(JSON.stringify(dataProduct)),
              },
              () => {
                this.forceUpdate();
              }
            );
          }
        },
        errorCallBack: (error, response) => {
          // MyLoadingApp.Helpers.show({error: error||response.Msg});
        },
      });
    }
  };
  requestHeaderProduct=()=>{
    const { data } = this.props;
    if(data?.MaterialId){
        HApiAxios.generic({
            request: {
                method: 'POST',
                url: `Material_Page_Content_Info/Options`
            },
            data: {
                MaterialId: data.MaterialId
            },
            successCallBack: (response)=>{
                if(response.Data){
                    let {dataProduct} = this.state;
                    dataProduct.HeaderAttributeList = HUtils.Obj.get(response, "Data.AttributeList")
                    this.setState({
                      dataProduct: dataProduct,
                    },()=>{
                        HApiAxios.generic({
                            request: {
                                method: 'POST',
                                url: `Material_Page_Content_Info/List`
                            },
                            data: {
                                MaterialId: data.MaterialId
                            },
                            successCallBack: (response)=>{
                                if(response.Data){
                                  dataProduct.HeaderList = HUtils.Obj.get(response, "Data.Data")
                                    this.setState({
                                      dataProduct: dataProduct,
                                    })
                                }
                            },
                            errorCallBack: (err, response)=>{
                              console.warn("requestHeaderProduct err, response: ",err, response)
                            }
                        })
                    })
                }
            },
            errorCallBack: (err, response)=>{
                console.warn("requestHeaderProduct err, response: ",err, response)
            }
        })
    }
  }
  requestGetRelativeMaterialList = () =>{
    HApiAxios.generic({
      request: {
        method: "POST",
        url: "GrpMaterial_Material_Mapping_ByMaterial/Options"
      },
      successCallBack: (response)=>{
        const materialList = HUtils.Obj.get(response, "Data.MaterialOnWebList");
        if(materialList){
            let {dataProduct} = this.state;
            dataProduct.MaterialOnWebList = this.getInitMaterialOnWebList(materialList);
            this.setState({
              dataProduct: dataProduct,
            })
        }
      },
      errorCallBack: (error, response)=>{
        console.warn("requestGetRelativeMaterialList error, response", error, response);
      }
    })
  }
  requestRelatedProduct=()=>{
    const { data } = this.props;
    if(data?.MaterialId){
      HApiAxios.generic({
        request: {
          method: 'POST',
          url: `DashboardForWebsite/GetDataListMaterialRelation4Website/${data.MaterialId}`
        },
        data: {},
        successCallBack: (response)=>{
          if(response.Data){
            this.setState({
                related_products: response.Data
            },()=>this.requestGetRelativeMaterialList())
          }
        },
        errorCallBack: (err, response)=>{
          console.warn("requestRelatedProduct err, response:", err, response)
        }
      })
    }
  }  
  requestUpload = (files, {cbSuccess}={}) => {
    const { data, fnList } = this.props;
    const _numFiles = files?.length
    // console.warn("UpdateFile:", files);
    HApiAxios.generic({
      request: {
        method: "UPLOAD",
        path: "ProjectPhoto",
        name: "Upload",
      },
      files: files,
      customHeader: {
        ProjectId: "",
        ScreenCode: "MATERIAL_EXT_4_WEBSITE",
        Id: data.Material_ExtId,
        FieldName: "Files_0",
      },
      successCallBack: (response) => {
        // this.setState({
        //     ImageUrl: URL.createObjectURL(files[0])
        // })
        HUI.Toast.showSuccess(response.Msg);
        if (response?.Data?.Photos?.length > 0) {
          let {ImageUrlList} = this.state;

          const v = response.Data.Photos.slice(response.Data.Photos.length - _numFiles, response.Data.Photos.length);  
          let _imgs = [];
          if(v?.length>0){
            v.forEach((a)=>{
              _imgs.push({
                ImageGuid: a.Id,
                ImageUrlOrigin: a.FileUrl_Download,
                ImageUrlThumnail: a.FileUrl_Download,
                UpdatedDate: a.CreatedAt,
                OrderNo: 0,
              })
            })
          }
          ImageUrlList = [...ImageUrlList, ..._imgs]
          this.setState(
            {
              ImageUrlList: ImageUrlList,
              isChangeImage: true,
            },
            () => {
              fnList &&
                fnList.updateProductInfo({
                  ImageUrlList: ImageUrlList,
                });
            }
          );
        }
        cbSuccess && cbSuccess()
      },
      errorCallBack: (error, response) => {
        cbSuccess && cbSuccess()
      },
    });
  };
  requestAvatar = (file) => {
    HApiAxios.generic({
      request: {
        method: "POST",
        path: "Dashboard",
        name: `UpdateImageHomePage/${file.ImageGuid}`,
      },
      successCallBack: (response) => {
        let _imgs = this.state.ImageUrlList;
        HUI.Toast.showSuccess("Thay đổi ảnh đại diện thành công");
        if (_imgs) {
          for (let i = 0; i < _imgs.length; i++) {
            let _img = _imgs[i];
            if (file.ImageGuid == _img.ImageGuid) {
              _img.ShowOnHomePage = true;
            } else {
              _img.ShowOnHomePage = false;
            }
          }
          this.forceUpdate();
        }
        HUI.Toast.showSuccess(response.Msg);
        this.setState({
          isChangeImage: true,
        });
      },
      errorCallBack: (error, response) => {},
    });
  };
  requestDelete = (file) => {
    const { fnList } = this.props;
    HApiAxios.generic({
      request: {
        method: "POST",
        path: "Dashboard",
        name: `DeleteImageWeb/${file.ImageGuid}`,
      },
      successCallBack: (response) => {
        let _imgs = this.state.ImageUrlList;
        if (_imgs) {
          for (let i = 0; i < _imgs.length; i++) {
            let _img = _imgs[i];
            if (file.ImageGuid == _img.ImageGuid) {
              _imgs.splice(i, 1);
            }
          }
          fnList &&
            fnList.updateProductInfo({
              ImageUrlList: _imgs,
            });
          this.forceUpdate();
        }
        HUI.Toast.showSuccess(response.Msg);
        this.setState({
          isChangeImage: true,
        });
      },
      errorCallBack: (error, response) => {},
    });
  };
  requestAddAtribute = (item, index) => {
    if (item && item.TextAtt) {
      HApiAxios.generic({
        request: {
          method: "POST",
          path: "Material_Page_Content_Info",
          name: "AddAttributeDetail",
        },
        data: {
          AttributeId: item.AttributeId,
          Id: item.Id,
          Description: item.TextAtt,
        },
        successCallBack: (response) => {
          HUI.Toast.showSuccess(response.Msg);
          const _newData = HUtils.Obj.get(response, "Data");
          if (_newData) {
            let { dataProduct } = this.state;
            dataProduct.HeaderList[index] = { ..._newData };
            this.setState(
              {
                dataProduct: JSON.parse(JSON.stringify(dataProduct)),
              },
              () => {
                this.forceUpdate();
              }
            );
          }
        },
        errorCallBack: (error, response) => {
          console.warn("SC_Editor_Product error, response:", error, response);
        },
      });
    }
  };
  requestAddMaterialRelation = (item) => {
    const { dataProduct } = this.state;
    const { fnList } = this.props;
    if (item) {
      let _query = {
        MaterialId1: dataProduct.MaterialId,
        MaterialIds_2: [item.Value],
      };
      HApiAxios.generic({
        request: {
          method: "POST",
          url: "GrpMaterial_Material_Mapping_ByMaterial/AddMaterialRelation",
        },
        data: _query,
        successCallBack: (response) => {
          HUI.Toast.showSuccess(response.Msg);
          if(fnList?.requestUpdateMaterialRelative){
            fnList.requestUpdateMaterialRelative({
              cbSuccess: (response) => {
                dataProduct.MaterialOnWebList = response;
                this.setState({
                  dataProduct: dataProduct,
                });
              },
            });
          }
          else{
            this.requestRelatedProduct()
          }
        },
        errorCallBack: (error, response) => {
          console.warn("SC_Editor_Product error, response:", error, response);
        },
      });
    }
    /** MaterialId1, MaterialIds_2*/
  };
  requestRemoveMaterialRelation = (item) => {
    const { dataProduct } = this.state;
    const { fnList } = this.props;

    if (item) {
      let _query = {
        MaterialId1: dataProduct.MaterialId,
        MaterialIds_2: [item.Value],
      };
      HApiAxios.generic({
        request: {
          method: "POST",
          url: "GrpMaterial_Material_Mapping_ByMaterial/RemoveMaterialRelation",
        },
        data: _query,
        successCallBack: (response) => {
          HUI.Toast.showSuccess(response.Msg);
          fnList &&
            fnList.requestUpdateMaterialRelative &&
            fnList.requestUpdateMaterialRelative({
              cbSuccess: (response) => {
                dataProduct.MaterialOnWebList = response;
                this.setState({
                  dataProduct: dataProduct,
                });
              },
            });
        },
        errorCallBack: (error, response) => {
          console.warn("SC_Editor_Product error, response:", error, response);
        },
      });
    }
  };
  requestRequestCache = () => {
    HApiAxios.generic({
      request: {
        method: "POST",
        url: "DashboardForWebsite/RefreshCache",
      },
      data: {
        Id: "ALL",
      },
      successCallBack: (response) => {
        window.location.reload();
      },
      errorCallBack: (error, response) => {},
    });
  };
  requestUpdateOrderImage = (query) => {
    if (query && query.Id) {
      HApiAxios.generic({
        request: {
          method: "POST",
          url: "Website_File/UpdateFields",
        },
        data: query,
        successCallBack: (response) => {
          HUI.Toast.showSuccess(response.Msg);
          const _newData = HUtils.Obj.get(response, "Data");
          let { ImageUrlList } = this.state;
          if (_newData) {
            let _item = ImageUrlList.find((v) => v.Id === query.Id);
            _item.OrderNo = _newData.OrderNo;
          }
          this.forceUpdate();
        },
        errorCallBack: (error, response) => {
          console.warn(
            "requestUpdateOrderImage error, response: ",
            error,
            response
          );
        },
      });
    }
  };
  requestUpdateProductAttribute = (_query, index, {cbSuccess}={}) =>{
    if(_query){
      HApiAxios.generic({
        request: {
          method: "POST",
          url: "Dashboard/UpdateImageSubMaterial"
        },
        data: _query,
        successCallBack: (response)=>{
          const _newData = HUtils.Obj.get(response, "Data");
          HUI.Toast.showSuccess(response.Msg || "Lưu thành công")
          if(_newData){
            let {dataProduct} = this.state;
            dataProduct.ImageUrlList[index] = _newData;
            cbSuccess && cbSuccess(_newData)
            this.setState({dataProduct: dataProduct});
          }
        },
        errorCallBack: (error, response)=>{
          console.warn("requestUpdateProductAttribute error, response: ", error, response)
        }
      })
    }
  }
  getTextFromAttributeList = (value) => {
    const { dataProduct } = this.state;
    const _list = dataProduct.HeaderAttributeList;
    let _text = null;
    if (_list) {
      _text = HUtils.Obj.get(
        _list.find((v) => v.Value === value),
        "Text"
      );
    }
    return _text;
  };
  getCheckedMaterialOnWeb = () => {
    const { dataProduct } = this.state;
    const list = dataProduct.MaterialOnWebList;
    return list.filter((v) => v.checked === true);
  };
  getOptionsImage = () => {
    const { options } = this.props;
    const _optionsImageByAttribute = HUtils.Obj.get(
      options,
      "optionsImageByAttribute"
    );
    if (_optionsImageByAttribute) {
      let _opts = [];
      for (let i = 0; i < _optionsImageByAttribute.length; i++) {
        _opts.push({
          Value: _optionsImageByAttribute[i]?.MaterialId,
          Text: _optionsImageByAttribute[i]?.MaterialName,
        });
      }
      return _opts;
    }
    return null;
  };
  getInitMaterialOnWebList = (data) =>{
    const {related_products} = this.state;
    if(data && related_products?.length > 0){
        for(let i=0; i<related_products.length; i++){
            const _rlp = related_products[i];
            const _mit = data.find((v)=>v.Value == _rlp.MaterialId);
            if(_mit){
                _mit.checked = true;
            }
        }
    }
    return data;
  }
  insertImageAvailablePlugin = (editor) => {
    const { dataProduct } = this.state;
    const { data } = this.props;
    let _query = {
      MaterialId: HUtils.Obj.get(data, "MaterialId"),
      MaterialName: HUtils.Obj.get(dataProduct, "MaterialName"),
    };
    return new InsertImageAvailable(editor, { data: _query });
  };
  checkIsUpdate = (_values) =>{
    const { isNew } = this.props;
    const { dataWillUpdate, isChangeImage, dataSeperateDidUpdate } = this.state;
    if (
      _values.length == 0 &&
      isChangeImage == false &&
      Object.keys(dataSeperateDidUpdate).length === 0
    ) {
      HUI.Toast.showWarn("Sản phẩm chưa có chỉnh sửa nào!");
      return false;
    } else if (
      _values.length == 0 &&
      (isChangeImage == true || Object.keys(dataSeperateDidUpdate).length > 0)
    ) {
      MyModal.Helpers.hide();
      window.location.reload();
      return false;
    }
    if (
      (dataWillUpdate.hasOwnProperty("Slug") == true &&
        (dataWillUpdate.Slug == "" || dataWillUpdate.Slug == null)) ||
      (isNew == true && dataWillUpdate.hasOwnProperty("Slug") == false)
    ) {
      HUI.Toast.showWarn("Bạn chưa nhập link cho bài viết này!");
      return false;
    }
    return true;
  }
  onChangeValue = (field, value) => {
    const { isNew } = this.props;
    let { dataProduct, dataWillUpdate } = this.state;
    if (field == "MaterialName" && isNew == true) {
      const _slugGeneration = HShopUtils.slugGeneration(value);
      dataProduct.Slug = _slugGeneration;
      dataWillUpdate.Slug = _slugGeneration;
    }
    dataProduct[field] = value;
    dataWillUpdate[field] = value;
    // console.warn("dataProduct:", dataProduct, dataWillUpdate);
    this.setState({
      dataProduct: dataProduct,
      dataWillUpdate: dataWillUpdate,
    });
  };
  onChangeHeader = (field, value, index) => {
    let { dataProduct, dataSeperateDidUpdate } = this.state;
    let _headerList = dataProduct && dataProduct.HeaderList;
    let _item = _headerList[index];
    if (value !== null && value !== _item[field]) {
      _item[field] = value;
      if (field !== "TextAtt") {
        dataSeperateDidUpdate[field] = value;
      }
      this.setState(
        {
          dataProduct: dataProduct,
          dataSeperateDidUpdate: dataSeperateDidUpdate,
        },
        () => {
          if (field !== "TextAtt") {
            let _query = {
              Id: _item.Id,
              Values: [{ FieldName: field, NewValue: value }],
            };
            this.requestUpdaterMaterialHeader(_query, index);
          }
        }
      );
    }
  };
  onChangeTab = (index) => {
    console.warn("_onChangeTab:", index);
    this.setState({
      tabIndex: index,
    });
  };
  onChangeRelativeMaterial = (item, value) => {
    let { dataSeperateDidUpdate } = this.state;
    if (!dataSeperateDidUpdate.RelativeMaterials) {
      dataSeperateDidUpdate.RelativeMaterials = [];
    }
    if (
      dataSeperateDidUpdate.RelativeMaterials &&
      !dataSeperateDidUpdate.RelativeMaterials.includes(item.Value)
    ) {
      dataSeperateDidUpdate.RelativeMaterials.push(item.Value);
    }
    this.setState(
      {
        dataSeperateDidUpdate: dataSeperateDidUpdate,
      },
      () => {
        if (value === false) {
          this.requestRemoveMaterialRelation(item);
        } else {
          this.requestAddMaterialRelation(item);
        }
      }
    );
  };
  onChangeOrderImage = (field, value, item) => {
    let { dataSeperateDidUpdate } = this.state;
    dataSeperateDidUpdate[field] = value;
    this.setState(
      {
        dataSeperateDidUpdate: dataSeperateDidUpdate,
      },
      () => {
        let _query = {
          Id: item.Id,
          Values: [{ FieldName: field, NewValue: value }],
        };
        this.requestUpdateOrderImage(_query);
      }
    );
  };
  onChangeProductAttribute = (field, value, item, index, {cbSuccess}={})=>{
    let { dataSeperateDidUpdate } = this.state;
    dataSeperateDidUpdate[field] = value;
    this.setState(
      {
        dataSeperateDidUpdate: dataSeperateDidUpdate,
      },
      () => {
        let _query = {
          MaterialId: value,
          Guid: item.ImageGuid,
        };
        this.requestUpdateProductAttribute(_query, index, {cbSuccess})
      }
    );
  }
  onSave = () => {
    if (this._editor_html && this._editor_html.getData) {
      let _content = this._editor_html.getData();
      let {dataProduct, dataWillUpdate} = this.state;
      if (
        dataProduct.FullDescription !== _content &&
        _content != null
      ) {
        dataProduct.FullDescription = _content;
        dataWillUpdate.FullDescription = _content;
        this.setState({
          dataProduct: {...dataProduct},
          dataWillUpdate: dataWillUpdate
        })
      }
    }
      this.requestUpdateMaterial();
  };
  onClose = () => {
    const { fnFomModal, noRefreshWenClose } = this.props;
    const { dataSeperateDidUpdate } = this.state;
    if (fnFomModal?.fnClose) {
      if (Object.keys(dataSeperateDidUpdate).length > 0 && noRefreshWenClose !== true) {
        this.requestRequestCache();
      }
      fnFomModal.fnClose();
    }
  };
  renderImageFunction = (v, index) => {
    const { imageFnStyle } = this.state;
    if (v) {
      return (
        <MyUI
          type="sc_editor_product_image"
          uiType={imageFnStyle}
          optionsImage={this.getOptionsImage()}
          data={v}
          fnList={{
            fnOnChangeOrderImage: (field, value, item) => {
              return this.onChangeOrderImage(field, value, item);
            },
            fnOnChangeImageAttribute: (field, value, {cbSuccess}={}) => {
              return this.onChangeProductAttribute(field, value, v, index, {cbSuccess})
            },
            fnRequestDelete: (item) => {
              return this.requestDelete(item);
            },
            fnRequestAvatar: (item) => {
              return this.requestAvatar(item);
            },
          }}
        />
      );
    }
  };
  renderTab = () => {
    const { tabIndex } = this.state;

    return (
      <div>
        <div
          className={`label ${tabIndex == 0 ? "" : "inactive"}`}
          onClick={() => {
            this.onChangeTab(0);
          }}
        >
          Thông tin
        </div>
        <div
          className={`label ${tabIndex == 1 ? "" : "inactive"}`}
          onClick={() => {
            this.onChangeTab(1);
          }}
        >
          Hình ảnh
        </div>
        <div
          className={`label ${tabIndex == 2 ? "" : "inactive"}`}
          onClick={() => {
            this.onChangeTab(2);
          }}
        >
          Mô tả chung
        </div>
        <div
          className={`label ${tabIndex == 3 ? "" : "inactive"}`}
          onClick={() => {
            this.onChangeTab(3);
          }}
        >
          SP liên quan
        </div>
        <div
            className={`label ${tabIndex == 6 ? "" : "inactive"}`}
            onClick={() => {
              this.onChangeTab(6);
            }}
          >
            Nội dung
          </div>
      </div>
    );
  };
  renderRelativeMaterialListTab = () => {
    const { dataProduct, reviewMaterialChecked, currentPageMaterialRelative } =
      this.state;
    const list = reviewMaterialChecked
      ? this.getCheckedMaterialOnWeb()
      : dataProduct.MaterialOnWebList;

    return (
      <div className="relative_tab">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "100%" }}>
            <React.Suspense fallback={loading}>
              <MyTableFilter
                contents={[
                  {
                    type: "search",
                    more: '{"ui":{"showSuffix":false, "showPre":false}, "search":{"instant":true}, "placeholder": "Tìm kiếm"}',
                  },
                ]}
                dataList={list}
                onChangeFilter={(filter) => {
                  this._currentFilter = filter;
                  this.forceUpdate();
                }}
              />
            </React.Suspense>
          </div>
          <div style={{ width: "18%" }}>
            <button
              onClick={() => {
                this.setState({
                  reviewMaterialChecked: !reviewMaterialChecked,
                });
              }}
              className="btn-review"
              style={
                reviewMaterialChecked ? {} : { backgroundColor: "#bcbcbc" }
              }
            >
              Xem danh sách chọn
            </button>
          </div>
        </div>
        <MyTableFilterData data={list} currentFilter={this._currentFilter}>
          {({ dataFiltered }) => {
            return (
              <PaginationWithData
                containerClassName={"paper"}
                sizePerPage={10}
                data={dataFiltered}
                hideWhenOnePage
                forcePage={
                  reviewMaterialChecked ? false : currentPageMaterialRelative
                }
                onPageChange={(p) => {
                  this.setState({ currentPageMaterialRelative: p });
                }}
              >
                {({ dataPagination }) => {
                  return this.renderRelativeMaterialListContent(dataPagination);
                }}
              </PaginationWithData>
            );
          }}
        </MyTableFilterData>
      </div>
    );
  };
  renderRelativeMaterialListContent = (data) => {
    if (data) {
      return (
        <div
          style={{
            backgroundColor: "#fff",
            marginBottom: "1rem",
            boxShadow: "rgb(37 37 37 / 20%) 0px 1px 2px 0px",
          }}
        >
          {data.map((v, i) => {
            return (
              <div
                key={`${i} - ${v.Value}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  borderBottom: "1px solid #efeff0",
                }}
              >
                <div style={{ width: "90%" }}>{v.Text}</div>
                <div style={{ width: "10%", textAlign: "center" }}>
                  <MyUI
                    type="input_check"
                    defaultValue={v.checked}
                    onChange={(e, newValue) => {
                      this.onChangeRelativeMaterial(v, newValue);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return <div>Không có dữ liệu</div>;
  };
  renderUploadImageContent = (data) =>{
    const _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions");

    return(
      <div className="dropzone" style={{minHeight: 525}}>
        {data != null &&
          data.map((v, i) => {
            return (
              <div
                key={`${i}_${v.ImageGuid}`}
                style={{
                  float: "left",
                  position: "relative",
                  marginRight: "0.5rem",
                }}
              >
                <img src={v.ImageUrlThumnail} />
                {!_haveProductOptions && (
                  <div
                    className="group-input"
                    style={{
                      maxWidth: "150px",
                      marginBottom: 5,
                      marginTop: 5,
                    }}
                  >
                    <MyUI
                      type="input_text"
                      defaultValue={v.OrderNo}
                      onBlur={(e, value) => {
                        if (value != v.OrderNo) {
                          this.onChangeOrderImage("OrderNo", value, v);
                        }
                      }}
                    />
                    <label className={v.OrderNo != null ? "active" : ""}>
                      OrderNo
                    </label>
                  </div>
                )}
                {this.renderImageFunction(v, i)}
              </div>
            );
          })}
          {
            HConfig.fnList.getIsDevMode() == true ? 
            (
              <MyUI 
                type="sc_editor_upload_image"
                fnList={{
                  fnRequestUpload: (file, {cbSuccess}={})=>{
                    this.requestUpload(file, {cbSuccess})
                  }
                }}
              />
            ) : 
            (
              <>
                <label
                  htmlFor="blog-file"
                  className="btn-upload"
                  style={{ clear: "both" }}
                  title="Upload"
                >
                  <MyIconSvg type="plus" width={20} height={20} fill={"var(--color-secondary)"}/>
                </label>
                <input
                  id="blog-file"
                  type="file"
                  accept="*"
                  onChange={(e) => this.requestUpload(e.target.files)}
                  style={{ display: "none" }}
                />
              </>
            )
          }
      </div>
    
    )
  }
  renderContent = () => {
    const { dataProduct, ImageUrlList, tabIndex } = this.state;
    const { isNew } = this.props;
    return (
      <div style={{ minHeight: "525px", width: "100%", marginLeft: "0.5rem" }}>
        {tabIndex == 0 && (
          <div>
            <div className="group-input">
              <MyUI
                type="input_text"
                style={{ marginBottom: "1rem" }}
                placeholder="Tên"
                defaultValue={dataProduct.MaterialName}
                onBlur={(e, newValue) =>
                  this.onChangeValue("MaterialName", newValue)
                }
              />
              <label className={dataProduct.MaterialName ? "active" : ""}>
                Tên
              </label>
            </div>
            {
              // isNew == true &&
              <div className="group-input">
                <MyUI
                  type="input_text"
                  style={{ marginBottom: "1rem" }}
                  placeholder="Link"
                  defaultValue={dataProduct.Slug}
                  onBlur={(e, newValue) => this.onChangeValue("Slug", newValue)}
                />
                <label className={dataProduct.Slug ? "active" : ""}>Link</label>
              </div>
            }

            <div className="group-input">
              <MyUI
                type="input_text"
                style={{ marginBottom: "1rem" }}
                placeholder="Meta Title"
                defaultValue={dataProduct.MetaTitle}
                onBlur={(e, newValue) =>
                  this.onChangeValue("MetaTitle", newValue)
                }
              />
              <label className={dataProduct.MetaTitle ? "active" : ""}>
                Meta Title
              </label>
            </div>
            <div className="group-input">
              <MyUI
                type="input_text"
                style={{ marginBottom: "1rem" }}
                placeholder="Meta KeyWords"
                defaultValue={dataProduct.MetaKeyWords}
                onBlur={(e, newValue) =>
                  this.onChangeValue("MetaKeyWords", newValue)
                }
              />
              <label className={dataProduct.MetaKeyWords ? "active" : ""}>
                Meta KeyWords
              </label>
            </div>
            <div className="group-input">
              <MyUI
                type="input_textarea"
                style={{ marginBottom: "1rem" }}
                placeholder="Meta Description"
                defaultValue={dataProduct.MetaDescription}
                onBlur={(e, newValue) =>
                  this.onChangeValue("MetaDescription", newValue)
                }
              />
              <label className={dataProduct.MetaDescription ? "active" : ""}>
                Meta Description
              </label>
            </div>
            <div className="group-input">
              <MyUI
                type="input_textarea"
                defaultValue={dataProduct.ShortDescription}
                placeholder="Tóm tắt"
                onBlur={(e, newValue) =>
                  this.onChangeValue("ShortDescription", newValue)
                }
              />
              <label className={dataProduct.ShortDescription ? "active" : ""}>
                Tóm tắt
              </label>
            </div>
          </div>
        )}
        {tabIndex == 1 && isNew != true && this.renderUploadImageContent(ImageUrlList)}
        {isNew != true && tabIndex == 2 && (
          <div>
            {dataProduct.HeaderAttributeList &&
              dataProduct.HeaderList &&
              dataProduct.HeaderList.map((v, i) => {
                return (
                  <div className="group-input" key={i}>
                    <div
                      style={{
                        color: "var(--color-secondary)",
                        marginBottom: 5,
                      }}
                    >
                      {this.getTextFromAttributeList(v.AttributeId)}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <MyUI
                        type="select2"
                        style={{ marginBottom: "1rem" }}
                        placeholder="Mô tả"
                        value={v.ConfigSimpleId}
                        multi={false}
                        simpleValue={true}
                        options={v.DescriptionList}
                        handleClearValue={(newValue) => {
                          this.onChangeHeader("ConfigSimpleId", newValue, i);
                        }}
                        onChange={(newValue) => {
                          this.onChangeHeader("ConfigSimpleId", newValue, i);
                        }}
                      />
                      <MyUI
                        type="popover"
                        showButton
                        overlay={
                          <MyUI
                            type="input_textarea"
                            placeholder="Mô tả"
                            onBlur={(e, newValue) => {
                              this.onChangeHeader("TextAtt", newValue, i);
                            }}
                          />
                        }
                        onOK={({ fnHide } = {}) => {
                          this.requestAddAtribute(v, i);
                          fnHide && fnHide();
                        }}
                      >
                        <CButton color="primary" style={{ height: 36 }}>
                          {v.form_AddNewAttribut}
                        </CButton>
                      </MyUI>
                      <MyUI
                        type="input_text"
                        style={{ marginBottom: "1rem" }}
                        placeholder="Mô tả khác"
                        defaultValue={v.Description}
                        onBlur={(e, newValue) => {
                          if((v.Description && newValue!=v.Description) || (newValue && newValue!=v.Description)){
                            this.onChangeHeader("Description", newValue, i);
                          }
                        }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        )}
        {tabIndex == 3 && this.renderRelativeMaterialListTab()}
        {
          dataProduct.hasOwnProperty("FullDescription") && 
          <div style={{ display: `${tabIndex == 6 ? "block" : "none"}` }}>
            <MyUI
              type="html_editor_ckeditor"
              html={dataProduct.FullDescription}
              pluginsMore={{
                components: [this.insertImageAvailablePlugin],
                toolbar: ["insertImage"],
              }}
              style={{ width: "100%" }}
              className="html-content"
              innerRef={(r) => {
                this._editor_html = r;
              }}
            />
          </div>
        }
      </div>
    );
  };
  renderAction = () =>{
    const {isSubmiting } = this.state;
    return(
      <div className="blog-row" style={{justifyContent: "flex-end"}}>
        <div>
          <button
            disabled={isSubmiting}
            onClick={() => this.onClose()}
            className="btn-save-edit"
            style={{ backgroundColor: "#ced2d8", color: "#777" }}
          >
            Đóng
          </button>
          <button
            disabled={isSubmiting}
            onClick={() => this.onSave()}
            className="btn-save-edit"
            style={{ float: "right", marginLeft: 10 }}
          >
            {isSubmiting === true && (
              <i className="fa fa-spinner fa-spin" />
            )}
            Cập nhật
          </button>
        </div>
      </div>
    )
  }
  render() {
    const { isSubmiting } = this.state;
    return (
      <div className="sc_editor_blog">
        <div className="blog-row">
          {this.renderTab()}
          {this.renderContent()}
        </div>
        {this.renderAction()}
        {isSubmiting === true && (
          <div className="pAFull-loading">
            <i className="fa fa-spinner fa-spin" />
          </div>
        )}
      </div>
    );
  }
}
export default SC_Editor_Product;
