import React, { Component } from 'react';
import { CFade } from '../components/MyCore';
import { Route } from 'react-router-dom';
import MyPageFromConfig from '../components/MyPage/MyPageFromConfig';

const Router = {
  listRoutesClient: [],
  listRoutesServer: [],
  listRoutesSimulate: [],
  listRoutesSimulatePublic: [],
  listRoutesPrivate: [],
  HConfig: null,
  initRouter(HConfig){
    Router.HConfig = HConfig;
    if(HConfig.configBuild && HConfig.configBuild.Routers){
      Router.listRoutesClient = HConfig.configBuild.Routers.routes;
      Router.listRoutesSimulate = HConfig.configBuild.Routers.routes_simulate;
      Router.listRoutesSimulatePublic = HConfig.configBuild.Routers.routes_simulate_public;
      Router.listRoutesPrivate = HConfig.configBuild.Routers.routes_private;      
    }
  },
  renderRouter(){
    let _ui = [];    
    if(Router.listRoutesSimulatePublic){
      for(let i=0;i<Router.listRoutesSimulatePublic.length;i++){
        let _item = Router.listRoutesSimulatePublic[i];
        _ui.push(
          <Route key={`simulatepublic_${i}`} 
            exact={true} 
            path={_item.UIUrl} name={_item.Title} render={(props) => {
              return (
                <CFade>
                  <MyPageFromConfig configPage={_item} {...props}/>
                </CFade>                
              )
            }} 
          />
        )
      }
    }
    if(Router.listRoutesClient){
      for(let i=0;i<Router.listRoutesClient.length;i++){
        let _item = Router.listRoutesClient[i];
        _ui.push(
          <Route key={i} 
            exact={_item.exact!=null?_item.exact:false} 
            path={_item.path} name={_item.name} render={props => <_item.component {...props}/>} 
          />
        )
      }
    }
    
    console.warn("renderRouter:",Router,_ui);
    return _ui;
  },
  renderRouterInLayout(){
    let _ui = [];
    if(Router.listRoutesPrivate){
      for(let i=0;i<Router.listRoutesPrivate.length;i++){
        let _item = Router.listRoutesPrivate[i];
        _ui.push(
          <Route
            key={`private_${i}`}
            path={_item.path} exact={_item.exact} name={_item.name}
            render={props => (
              <CFade>
                <_item.component {...props} />
              </CFade>
            )} />
        )
      }
    }
    if(Router.listRoutesSimulate){
      for(let i=0;i<Router.listRoutesSimulate.length;i++){
        let _item = Router.listRoutesSimulate[i];
        _ui.push(
          <Route key={`simulate_${i}`} 
            exact={true} 
            path={_item.UIUrl} name={_item.Title} render={(props) => {
              return (
                <CFade>
                  <MyPageFromConfig configPage={_item} {...props}/>
                </CFade>                
              )
            }} 
          />
        )
      }
    }
    if(Router.listRoutesServer){
      // console.warn("renderRouterInLayout:",Router.listRoutesServer);
      for(let i=0;i<Router.listRoutesServer.length;i++){
        let _item = Router.listRoutesServer[i];
        _ui.push(
          <Route key={`server_${i}`} 
            exact={true} 
            path={_item.UIUrl} name={_item.Title} render={(props) => {
              return (
                <CFade>
                  <MyPageFromConfig configPage={_item} {...props}/>
                </CFade>                
              )
            }} 
          />
        )
      }
    }
    return _ui;
  }
}

export default Router;