import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { HConfig } from "apps/Helpers";
import HGA from "helpers/GA";
import "./styles/footer_1.css";

const TheFooter = () => {
  const footerLink = useSelector((state) => state.shop.footerLink);
  const socialList = HConfig.fnList.getConfig("socialList", []);
  const hotline = HConfig.fnList.getConfig("uiHotline");
  const bio = HConfig.fnList.getConfig("uiBrandBio")
  const logoApp = useSelector((state) => state.shop.appLogo);

  let [collapseMobile, setCollapseMobile] = React.useState({});
  const onChangeCollapse = (index) => {
    collapseMobile[index] = !collapseMobile[index];
    setCollapseMobile({ ...collapseMobile });
  };
  return (
    <div className="footer">
      <div className="footer-middle">
        <div className="center">
          {footerLink &&
            footerLink.map((v, i) => {
              return (
                <div className="footer-block" key={i}>
                  <div
                    className="title"
                    style={{ display: "flex", justifyContent: "space-between" }}
                    onClick={() => onChangeCollapse(i)}
                  >
                    <strong>{v.Title}</strong>
                    <div className="footer-collapse">
                      <i
                        className={
                          collapseMobile[i] == true
                            ? "fa fa-angle-up"
                            : "fa fa-angle-down"
                        }
                      />
                    </div>
                  </div>
                  <ul
                    className="list"
                    style={
                      collapseMobile[i] == true
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    {v.Childs &&
                      v.Childs.map((m, k) => {
                        return (
                          <li key={k}>
                            {m.Icon && (
                              <i className={m.Icon} style={{ minWidth: 20 }} />
                            )}
                            <a href={m.Slug}>{m.Title}</a>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              );
            })}
        </div>
      </div>
      <div className="footer-lower">
        <div className="center">
          <div className="bio">
            <a href="/" className="brand">
              <img src={logoApp} alt={HConfig.fnList.getConfig("appName")} />
            </a>
            {
                bio && <p>{bio}</p>
            }
          </div>
          <ul className="social-sharing">
            {socialList.map((v, i) => {
              return (
                <li key={i}>
                  <a target="blank" href={v.Link} style={v.Style}>
                    <i className={v.Icon} />
                  </a>
                </li>
              );
            })}
          </ul>
          <div className="footer-disclaimer">
            {HConfig.fnList.getConfig("uiCopyRightFooter")}
          </div>
        </div>
      </div>
      <div className="helper">
        <div id="goToTop-contact">
          Hotline:
          <a
            href={`tel:+84${hotline && hotline.slice(1)}`}
            onClick={() => {
              HGA.trackingEventClick({
                label: "item_footer_sdt",
                value: hotline,
              });
            }}
          >
            {hotline}
          </a>
        </div>
      </div>
    </div>
  );
};

export default TheFooter;
