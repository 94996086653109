import React from 'react'
import './styles/sc_dropdown.css'

class SC_Dropdown extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      showDropdown: false
    }
  }
  onshowDropDown = (value) =>{
    this.setState({
      showDropdown: value
    })
  }
  render(){
    const {renderToggle, renderContent} = this.props;
    const {showDropdown} = this.state;
    return (
        <div className="dropdown"  onMouseLeave={()=>this.onshowDropDown(false)}>
          <div className="dropdown-tgl">
            {renderToggle && renderToggle({
              showDropdown: ()=>{
                this.onshowDropDown(true)
              }
            })}
          </div>
          <div className={`dropdown-content${showDropdown ? " show": ""}`}>
            {renderContent && renderContent()}
          </div> 
        </div>
      )
  }
}

export default SC_Dropdown
