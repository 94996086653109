import React from 'react'
import Footer_0 from './Types/footer_0'
import Footer_1 from './Types/footer_1'

const FooterTypes = {
  Types:{
    footer_0: (props)=>{ return ( <Footer_0 {...props}/> ) },    
    footer_1: (props)=>{ return ( <Footer_1 {...props}/> ) },    
  }
}

export default FooterTypes;
