import React from 'react'
import Header_0 from './Types/header_0'
import Header_1 from './Types/header_1'

const HeaderTypes = {
  Types:{
    header_0: (props)=>{ return ( <Header_0 {...props}/> ) },    
    header_1: (props)=>{ return ( <Header_1 {...props}/> ) },    
  }
}

export default HeaderTypes;
