import React from 'react'
import {MyUI} from '../../../../Components'

class SC_ProductRelative extends React.Component{
    render(){
        const {data} = this.props;
        if(data && data.length > 0){
            return(
                <div className="product-grid">
                    <div className="title">Sản phẩm liên quan</div>
                    <div className="item-grid">
                        {
                            data && data.length>0 && data.map((v, i)=>{
                                return <MyUI type="sc_card_product" data={v} key={i}/>
                            })
                        }
                    </div>
                </div>
            )
        }
        return <div></div>
    }
}
export default SC_ProductRelative