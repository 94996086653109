import React from "react";
import "./styles/imagezoom.css";

class MyLib_ImageZoom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundImage: "",
      backgroundPosition: "0% 0%",
    };
  }
  componentDidMount() {
    const { imgOrigin } = this.props;
    if (imgOrigin) {
      this.setState({ backgroundImage: `url(${imgOrigin.src})` });
    }
  }
  shouldComponentUpdate(nextProps) {
    if (this.props.imgOrigin != nextProps.imgOrigin) {
      this.setState({ backgroundImage: `url(${nextProps.imgOrigin.src})` });
    }
    return true;
  }
  handleMouseMove = (e) => {
    const { width, height } = e.target.getBoundingClientRect();
    const { imgOrigin } = this.props;
    const img = document.getElementById(imgOrigin?.id);
    const img_position = img.getBoundingClientRect();
    const body = document.body.getBoundingClientRect();
    let x = ((e.pageX - img_position.left) / width) * 100;
    let y = ((e.pageY - Math.abs(img_position.top - body.top)) / height) * 100;

    this.setState({ backgroundPosition: `${x}% ${y}%` });
  };

  render() {
    const { imgOrigin } = this.props;
    if (imgOrigin) {
      return (
        <figure
          className="image-zoom"
          id={imgOrigin?.id}
          onMouseMove={(e) => this.handleMouseMove(e)}
          style={this.state}
        >
          <img src={imgOrigin.src} />
        </figure>
      );
    }
    return <div></div>;
  }
}
export default MyLib_ImageZoom;
