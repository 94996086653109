import React from 'react'
import PageExt from '../../../../../components/MyPage/Types/_ext';
import {MyUI, MyModal} from '../../../../Components';
import {HUtils} from '../../../../Helpers';
import './styles/common.css'

class SC_PageContent extends PageExt{
    constructor(props){
        super(props);
        this.state = {
            data: null
        }
    }
    componentDidMount(){
        const {configPage} = this.props;
        if(configPage && configPage.Data){
            const _data = HUtils.Obj.get(configPage, "Data.Data");
            this.setState({
                data: _data,
            })
        }
    }
    openModalEdit=({isNew}={})=>{
        const {data} = this.state;
        if(data){
            MyModal.Helpers.showMyUI("sc_editor_pagecontent",{
                data: isNew===true?null:data,
                isNew: isNew,
                className: 'html-content',
                fnList: {
                    updateContent: (newData)=>{
                        if(newData){
                            this.setState({
                                data: newData
                            })
                        }
                    }
                }
            },{
                title: "Chỉnh sửa nội dung",
                closeOnBackdrop: false,
                closeButton: true
            })
        }
    }
    renderToolbarAdmin = () =>{
        return(
            <MyUI 
                type="sc_toolbar_admin"
                ShowBtnCreatePost={false}
                fnList={{
                    onEdit: ()=>{
                        this.openModalEdit()
                    },
                    onAddNew: ()=>{
                        this.openModalEdit({isNew: true})
                    }
                }}
            />
        )
    }
    renderTitle=()=>{
        const {data} = this.state;
        if(data){
            return (
                <div className="page-title" style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between'}}>
                    <h1 style={{width: '100%'}}>{data.Title}</h1>
                </div>
            )
        }
        return <div></div>
    }
    renderContent=()=>{
        const {data} = this.state;
        if(data){
            return <MyUI type="html" html={data.Body} className="page-body"/>
        }
        return <div></div>
    }
    renderHelmet=()=>{
        const {data} = this.state;
        return <MyUI type="sc_helmet" data = {data}/>

    }
    render(){
        return(
            <div className="master-wrapper-content">
                { this.renderHelmet() }
                <div className="master-column-wrapper">
                    <div className="center-1">
                        <div className="page topic-page">
                            { this.renderToolbarAdmin() }
                            { this.renderTitle() }
                            { this.renderContent() }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SC_PageContent