import React from 'react'
import PageExt from '../../../../../components/MyPage/Types/_ext';
import {HApiAxios, HFormat, HStoreRedux, HLocalStorage,HUtils, HLink, HUI, HConfig} from '../../../../Helpers';
import MyUI from '../../../../../components/MyUI/MyUI'
import MyTableFilter from '../../../../../components/MyTableFilter/MyTableFilter'
 import MyPageWithDataFilter from '../../../../../components/MyTableFilter/MyTableFilterData'

// Jira https://allianceitscvn.atlassian.net/browse/AWO-187
class SC_QuickOrder extends PageExt {
    constructor(props){
        super(props);
        this.state = {
            category: null,
            ctgDetail: null,
            categories: null,
            isNodata: false,
            isReview: false,
            isLoading: false,
            pageIndex: 1,
            tabIndex: 0,
            // pageSize: 10,
            pageReviewIndex: 1,
            pageInFilter: 1,
            sortType: 0,
            dataFiltered: null,
            selectedArray: null, // lưu lại sản phẩm đã chọn khi filter server không mất
        }
    }
    componentDidMount(){
        const {configPage} = this.props;
       const _data = HUtils.Obj.get(configPage, "Data.Data");

       if(_data){
            this.setState({
                categories: _data
            },()=>{
                this.getData()
            })
            const _extra = HUtils.Obj.get(configPage, "Data.ExtraData");
            if(_extra){
                const _grpInfo = HUtils.Obj.get(_extra, "MetaDataInfo");
                const _slugInfo = HUtils.Obj.get(_extra, "SlugInfo");
                const _pageConfig = HUtils.Obj.get(_extra, "PageConfig")
                this.setState({
                    ctgDetail: {..._grpInfo, ..._slugInfo},
                    pageConfig: _pageConfig
                })
            }
       }
       else {
        this.requestList();

       }
    }
    requestList=({customQuery}={})=>{
        this.setState({
            isLoading: true
        },()=>{
            HApiAxios.generic({
                request: {
                    method: 'POST',
                    url: 'DashboardForWebsite/GetListDataWithSlug'
                },
                data: {
                    Slug: this.getSlug(),
                    PageSize: 1000,
                    ...customQuery
                    // Page: 1,
                },
                successCallBack: (response)=>{
                    if(response.Data){
                        this.setState({
                            categories: HUtils.Obj.get(response, "Data.Data")
                        },()=>{
                           this.getData()
                        })
                        const _extra = HUtils.Obj.get(response, "Data.ExtraData");
                        if(_extra){
                            const _grpInfo = HUtils.Obj.get(_extra, "MetaDataInfo");
                            const _slugInfo = HUtils.Obj.get(_extra, "SlugInfo");
                            const _pageConfig = HUtils.Obj.get(_extra, "PageConfig")
                            this.setState({
                                ctgDetail: {..._grpInfo, ..._slugInfo},
                                pageConfig: _pageConfig
                            })
                        }
                        this.setState({
                            isLoading: false
                        })
                    }
                },
                errorCallBack: (err, response)=>{
                    this.setState({
                        isLoading: false
                    })
                }
            })
        })
        
    }   
    getSlug=()=>{
        const {ctgDetail} = this.state;
        return HUtils.Obj.get(ctgDetail, "Slug", window.location.pathname && window.location.pathname.split("/")?.[1]);
    }
    getDataRender=()=>{
        const {category, isReview, selectedArray} = this.state;
        // console.warn("selectedArray", selectedArray)
        if(isReview){
            return selectedArray
        }
        return category
    }
    getData=()=>{
        const _data = this.getElmTabActiveByKey("MaterialForWebInfo", []);
        if(_data && _data.length>0){
            this.setState({
                category: this.updateQuantity4Data(_data),
            })
        }
        else{
            this.setState({
                isNodata: true
            })
        }
    }
    getElmTabActiveByKey=(key, df)=>{
        const {tabIndex, categories} = this.state;
        let _elm = df;
        let _tabList = HUtils.Obj.get(categories,"Detail");
        // console.warn("getElmTabActiveByKey:",_tabList,categories);
        if(_tabList && _tabList[tabIndex] && _tabList[tabIndex][key]){
            _elm = _tabList[tabIndex][key]
        }
        return _elm
    }
    getData4Paging=()=>{
        const {isReview, category, dataFiltered, selectedArray} = this.state;
        let _data = [];
        if(isReview == true){
            _data = selectedArray;
        }
        else if(isReview == false && dataFiltered && this._currentFilter && Object.keys(this._currentFilter).length !== 0){
            _data = dataFiltered;

        }
        else{
           _data = category;

        }
        return _data;
    }
    getTotalMoneySelected=()=>{
        const {selectedArray} = this.state;
        let _totalMoney = 0;
        let _seletedList = selectedArray;

        if(_seletedList && _seletedList.length>0){
            for(let i=0; i<_seletedList.length; i++){
                _totalMoney += (_seletedList[i].Quantity * _seletedList[i].SellPrice)
            }
        }

        return _totalMoney;
    }
    getTotalData=()=>{
        const _data = this.getData4Paging()
        return _data && _data.length;

    }
    getForcePage=()=>{
        const {isReview, pageIndex, pageReviewIndex, pageInFilter} = this.state;
        if(this.getIsBeingFilter()){
           return pageInFilter
        }
        if(isReview){
            return pageReviewIndex
        }
        return pageIndex
    }
    getIsBeingFilter=()=>{
        if(this._currentFilter && Object.keys(this._currentFilter).length > 0){
            return true;
        }
        return false;
    }
    //AWO-196
    updateQuantity4Data=(data)=>{
        const {selectedArray} = this.state;
        if(selectedArray && data && data.length>0){
            for(let i=0; i<data.length; i++){
                let _item = data[i];
                let _itemSelected = selectedArray.find((v)=> v.MaterialId == _item.MaterialId);
                if(_itemSelected){
                    _item.Quantity = _itemSelected.Quantity
                }
            }
        }
        return data
    }
    addProductsToCart=()=>{
        const {category, selectedArray} = this.state;
        const _productsAddCart = selectedArray;//category && category.filter((i)=>i.Quantity > 0);

        if(_productsAddCart && _productsAddCart.length>0){
            for(let i=0; i<_productsAddCart.length; i++){
                const _product = _productsAddCart[i];
                this.saveToLocalStorage(_product)
            }
            HUI.Toast.showSuccess(`Đã thêm ${_productsAddCart.length} sản phẩm vào giỏ hàng`);
            this.resetQuantityData();
            setTimeout(()=>{HLink.push('/cart')},500)
        }
        else{
            HUI.Toast.showWarn("Bạn chưa chọn sản phẩm nào!")
        }
    }
    saveToLocalStorage=(item)=>{
        let _cart = HLocalStorage.getObject("cart",[]);
        let _item = _cart.find((i)=>i.MaterialId == item.MaterialId);
        if(_item){
            _item.Quantity += item.Quantity;
        }
        else{
            _cart.push(item)
        }
        HLocalStorage.setObject("cart", _cart);
        HStoreRedux.dispatch({type: "setObjInShop", cartProducts: _cart});
    }
    resetQuantityData=()=>{
        let {category, selectedArray} = this.state;
        if(category && category.length){
            for(let i=0; i<category.length; i++){
                let _item = category[i];
                _item.Quantity = 0;
            }
            selectedArray = []
        }
        this.setState({
            category: [...category],
            selectedArray: selectedArray
        })
    }
    findItemInCategories=(item)=>{
        const {categories} = this.state;
        let _result = null;
        if(categories && item){
            const _arrayGroup = categories.Detail;
            if(_arrayGroup && _arrayGroup.length>0){
                for(let i=0; i<_arrayGroup.length; i++){
                     const _matArr = HUtils.Obj.get(categories, `Detail[${i}].MaterialForWebInfo`);
                     if(_matArr){
                         const _item = _matArr.find((v)=>v.MaterialId === item.MaterialId);
                         if(_item){
                             _result = _item
                         }
                     }
                }
            }
        }
        return _result
    }
    paginate=(array, page_number)=> {
        const {pageConfig} = this.state;
        const pageSize = HUtils.Obj.get(pageConfig, "PageSize", 10);
        if(array && array.length>0){
          return array.slice((page_number - 1) * pageSize, page_number * pageSize);
        }
        return [];
    }
    scrollTo=()=>{
        const _el = document.getElementById("quickorder-table");
        if(_el){
            window.scrollTo(0, _el.offsetParent.offsetTop + _el.offsetTop - 60);
        }
    }
    onChangeItemQuantity=(item, value)=>{
        let {selectedArray} = this.state;
        let _itemWillUpdate =  this.findItemInCategories(item);
        let _itemInQuantityArr = selectedArray && selectedArray.find((i)=>i.MaterialId == item.MaterialId);
        if(_itemWillUpdate){
            _itemWillUpdate.Quantity = value;
            if(value>0){
                if(_itemInQuantityArr){
                    _itemInQuantityArr.Quantity = value;
                }
                else if(selectedArray){
                    selectedArray.push({...item, Quantity: value})
                }
                else{
                    selectedArray = [];
                    selectedArray.push({...item, Quantity: value})
                }
            }
            else{
                if(selectedArray){
                    selectedArray = selectedArray.filter((v)=> v.MaterialId !== item.MaterialId)
                }
            }
        }
        
        this.setState({
            selectedArray: selectedArray
        },()=>{
            this.getData()
        })
    }
    onChangeFilter=(value)=>{
        this.setState({
            sortType: value
        },()=>{
            this.requestList({customQuery: {CommandType: value}})
        })
    }
    onChangePage=(value)=>{
        const {isReview} = this.state;
        if(this.getIsBeingFilter()){
            this.setState({
                pageInFilter: value
            })
        }
        else if(isReview){
            this.setState({
                pageReviewIndex: value
            })
        }
        else{
            this.setState({
                pageIndex: value
            })
        }
        this.scrollTo()
    }
    onChangeReview=()=>{
        const {isReview} = this.state;
        if(isReview == false){
            this.setState({
                pageReviewIndex: 1
            })
        }
        this.setState({
            isReview: !isReview
        })
    }
    onChangeTab=(index)=>{
        this.setState({
            tabIndex: index,
            pageIndex: 1
        },()=>{
            this.getData()
        })
    }
    renderHelmet=()=>{
        const {ctgDetail} = this.state;
        const meta = {
            MetaTitle: HUtils.Obj.get(ctgDetail, "MetaKeyTitle", "Ajuma Garden - Đặt hàng nhanh"),
            MetaKeyWords: HUtils.Obj.get(ctgDetail, "MetaKeyword", "Ajuma Garden - Quick Order"),
            MetaDescription: HUtils.Obj.get(ctgDetail, "Description", "Ajuma Garden - Đặt hàng nhanh")
        }
        return <MyUI type="sc_helmet" data = {meta}/>
    }
    renderFilter=()=>{
        const {isReview, sortType} = this.state;
        return(
            <div className="search-box-search" style={{marginBottom: 20}} >
                <MyTableFilter 
                contents={[{type: 'search', more: '{"ui":{"showSuffix":true, "showPre":false}, "search":{"instant":true, "nosymbol":true}, "placeholder": "Tìm kiếm"}'}]} 
                dataList={this.state.category} 
                onChangeFilter={(filter)=>{
                    this._currentFilter = filter;
                    this.setState({
                        pageInFilter: 1
                    })
                    this.forceUpdate()
                }}/>
                 <div className="product-sorting">
                    <span>Sắp xếp theo</span>
                    <select className="sortOptionsDropDown" value={sortType} onChange={(e)=>this.onChangeFilter(e.currentTarget.value)}>
                        <option value="1">Mới nhất</option>
                        <option value="2">Giá: Thấp đến cao</option>
                        <option value="3">Giá: Cao đến thấp</option>
                    </select>
                </div>
                <button className={`review ${isReview && 'active'}`} onClick={()=>this.onChangeReview()}>
                   {isReview ? "Trở về danh sách chọn hàng" : "Xem lại danh sách đã chọn"}
                </button>
            </div>
        )
    }
    renderTab=()=>{
        const {categories, tabIndex, isReview} = this.state;
        const tabList = HUtils.Obj.get(categories,"Detail");
        if(!isReview && tabList && tabList.length>1){
            return(
                <div className="tab-header">
                    {
                        tabList.map((v,i)=>{
                            return (
                                <div key={i} className={`label ${tabIndex === i ? "" : "inactive"}`} onClick={()=>this.onChangeTab(i)}>
                                    {HUtils.Obj.get(v,"Description")}
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
    }
    renderTableHeader=()=>{
        return(
            <thead>
                <tr className="cart-header-row">
                    <th className="item-count" style={{display: 'table-cell'}}>
                        {/* <span className="item-count">#</span> */}
                    </th>
                    <th className="product-picture">Hình ảnh</th>
                    <th className="product">Sản phẩm</th>
                    <th className="unit-price">Giá</th>
                    <th className="quantity">Số lượng </th>
                    <th className="subtotal footable-last-column">Tổng tiền</th>
                </tr>
            </thead>
        )
    }
    renderTableContent=(dataList)=>{
        let _ui = [];
        const data = this.paginate(dataList, this.getForcePage())
        const {tabIndex} = this.state;
        if(data && data.length > 0){
            for(let i=0; i<data.length; i++){
                let _item = data[i];
                if(_item.hasOwnProperty("Quantity") != true){
                    _item.Quantity = 0;
                }
                _ui.push(
                    <tr key={`${i}_p_${tabIndex}`} className="cart-item-row row-product-name">
                        <td className="item-count">                        
                        </td>
                        <td className="product" colSpan={5} style={{padding: "10px 10px",paddingBottom: "0px", display: 'table-cell'}}>
                            <a href={_item.Slug} className="product-name">{_item.MaterialCode} - {_item.MaterialName}</a>
                        </td>
                    </tr>
                )
                _ui.push(
                    <tr key={`${i}_${_item.MaterialId}_${tabIndex}`} className="cart-item-row" >
                        <td className="item-count" style={{display: 'table-cell'}}>
                        </td>
                        <td className="product-picture">
                            <a href={_item.Slug}><img alt={`Hình ảnh của ${_item.MaterialName}`} src={_item.ImageUrlThumnail ||  HConfig.fnList.getConfig("imageProductDefault")} title={`Hiển thị chi tiết ${_item.MaterialName}`}/></a>
                        </td>
                        <td className="product">
                            <a href={_item.Slug} className="product-name">{_item.MaterialCode}<br/>{_item.MaterialName}</a>
                        </td>
                        <td className="unit-price">
                            <span className="product-unit-price" style={_item.IsCall4WebOrder ? {color: "var(--color-danger)"} : {}}>{_item.IsCall4WebOrder ? "Liên hệ" : _item.SellPriceText}</span>
                        </td>
                        <td className="quantity">
                            <div className="dFfdRaiCjcC">
                                <MyUI type="sc_input_quantity" className = "quantity-control" minQuantity={0} disabled={_item.IsCall4WebOrder} value={_item.Quantity} onChange={(value)=>{
                                    this.onChangeItemQuantity(_item, value)
                                }}/>
                            </div>
                        </td>
                        <td className="subtotal footable-last-column">
                            <span className="product-subtotal">{HFormat.Money.formatMoneyForAjuma(_item.SellPrice * _item.Quantity)}</span>
                        </td>
                    </tr>
                        
                )
            }
            return(
                <tbody>
                    {_ui}
                </tbody>
            )
        }
    }
    renderTable=(data)=>{
        if(data && data.length>0){
            return(
                <table className="cart" id="quickorder-table">   
                    {
                        this.renderTableHeader()
                    }
                    {
                        this.renderTableContent(data)
                    }
                </table>
            )
        }
        return <div className="section"><div className="title">Danh sách rỗng!</div></div>
    }
    renderContent = () =>{
        const data = this.getDataRender();
        return(
            <MyPageWithDataFilter data={data} currentFilter={this._currentFilter} getDataFilter={(data)=>this.state.dataFiltered = data}>
                {
                    ({dataFiltered})=>{
                        return this.renderTable(dataFiltered)
                    }
                }
            </MyPageWithDataFilter>
        )
    }
    renderPagination=()=>{
        const {pageConfig} = this.state;
        const total = this.getTotalData();
        const pageSize = HUtils.Obj.get(pageConfig, "PageSize", 10)
        if(total > pageSize){
            return (
                <MyUI type='react_pagination_box' 
                    useLink={false}
                    forcePage={this.getForcePage()}
                    pageSize={pageSize} 
                    pageTotal={total}
                    containerClassName="pagination_style"
                    onPageChange={({selected})=>{
                        this.onChangePage(selected + 1)
                    }}/>
            )
        }
        return <div></div>
    }
    renderFooter=()=>{
        return(
            <div className="footer">
                <div className="total-money">
                    Tổng tiền đã chọn: <strong style={{float: "right"}}>{HFormat.Money.formatMoneyForAjuma(this.getTotalMoneySelected())}</strong>
                </div>
                <button className="add-to-cart-button" onClick={()=>this.addProductsToCart()}>Thêm vào giỏ hàng</button>
            </div>
        )
    }
    render(){
        const {isNodata, isLoading} = this.state;
        const titleHeader = HUtils.Obj.get(this.state.categories,"Description", "Đặt hàng nhanh");//this.getElmTabActiveByKey("QuickOrderInfo",null)
        return(
            <div className="master-wrapper-content">
                {
                    this.renderHelmet()
                }
                <div className="master-column-wrapper">
                    <div className="center-1 quick-order">
                        <div className="page-title">
                            <h1>{ titleHeader }</h1>
                        </div>
                        { this.renderFilter() }

                        {
                            isLoading ==false && isNodata == false &&
                            <div className="page-body">
                                { this.renderTab() }
                                { this.renderContent() }
                                { this.renderPagination() }
                                { this.renderFooter() }
                            </div>
                        }
                        {
                            isLoading == false && isNodata == true &&
                            <div className="section"><div className="title">Không có dữ liệu!</div></div>
                        }
                        {
                            isLoading == true &&
                            <MyUI type="sc_loading"/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default SC_QuickOrder