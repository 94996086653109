import React from 'react'
import SC_Category from 'apps/shoppingCart/components/MyPage/Types/SC_Category'
import {MyUI} from 'apps/Components'

class SC_Category_1 extends SC_Category{
    renderFilterTop = () =>{
        const {categoryDetail, sortType, pageSize, filterPrice} = this.state;
        if(categoryDetail){
            return (
                <div>
                    {
                        categoryDetail.Note &&
                        <p>{categoryDetail.Note}</p>
                    }
                    {
                        this.renderSearch && this.renderSearch()
                    }
                    <MyUI
                        type="sc_category_top" 
                        filterPrice={filterPrice}
                        sortType={sortType} 
                        numberInPage={pageSize} 
                        onChangeFilterPrice={(value)=>{
                            this.setState({
                                filterPrice: value
                            },()=>{
                                this.onFilter(value)
                            })
                        }}
                        changeNumberInPage={(value)=>this.changePageSize(value)}
                        changeTypeSort={(type)=>this.changeTypeSort(type)}
                    />
                </div>
            )
        }
    }
    render(){
        const {categoryDetail} = this.state;

        if(categoryDetail){
            return(
                <div className="master-wrapper-content">
                    {
                        this.renderHelmet()
                    }
                    {
                        this.renderBreakcumb()
                    }
                    <div className="master-column-wrapper">
                        <div className="center-1">
                            <div className="page category-page">
                                {/* <div className="page-title">
                                    <h1>{categoryDetail.GrpMaterialName}</h1>
                                </div> */}
                                <div className="page-body">
                                    <div className="category-description">
                                        { this.renderFilterTop() }
                                        { this.renderContent() }                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        return <div></div>
    }
}
export default SC_Category_1