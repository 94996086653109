import React from "react";
import SC_CartAddress from "apps/shoppingCart/components/MyUI/Types/sc_cart_address";
import { HUtils, HAuth } from "apps/Helpers";
import { MyUI } from "apps/Components";

class SC_CartAddress_1 extends SC_CartAddress {
  renderHeader = () => {
    const { renderHeader, config } = this.props;
    const _isLoggedIn = HAuth.isLoggedIn();
    const _showSignIn = HUtils.Obj.get(config, "showSignIn", true);
    if (renderHeader) {
      return <div className="address-grid">{renderHeader()}</div>;
    }
    return (
      <div className="address-grid">
        {_isLoggedIn == false && _showSignIn && (
          <div className="dFaCjCtaCw100">
            <MyUI type="sc_login" uiType={2} />
          </div>
        )}
      </div>
    );
  };
}
export default SC_CartAddress_1;
