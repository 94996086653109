import React from "react";
import { HApiAxios, HLink, HText } from "apps/Helpers";
import HGA from "helpers/GA";
import "./styles/sc_header_search.css";

class SC_HeaderSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textSearch: "",
      active: false,
      resultSearch: [],
    };
  }

  requestSearch = (textSearch) => {
    HApiAxios.generic({
      request: {
        method: "POST",
        url: "DashboardForWebsite/MaterialSearchList",
      },
      data: {
        FullTextSearch: textSearch,
        PageSize: 8,
      },
      successCallBack: (response) => {
        this.setState({
          resultSearch: response.Data,
        });
      },
      errorCallBack: (err, response) => {},
    });
  };

  onChangeTextSearch = (value) => {
    const { textSearch } = this.state;
    this.setState(
      {
        textSearch: value,
        active: true,
      },
      () => {
        if (this.timer != null) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          if (textSearch?.length > 1) {
            this.requestSearch(textSearch);
          }
        }, 1000);
      }
    );
  };

  gotoSearchPage = () => {
    HLink.push(`/search?query=${this.state.textSearch}`);
  };
  render() {
    const { resultSearch, textSearch, active } = this.state;
    return (
      <div className="search-bar">
        <div
          className="searchBox"
          onMouseEnter={()=>this.setState({active: true})}
          onMouseLeave={() => this.setState({ active: false })}
        >
          <input
            className="searchInput"
            type="text"
            placeholder={HText.get("text_placeholder_search")}
            value={textSearch}
            onChange={(e) => {
              this.onChangeTextSearch(e.currentTarget.value);
            }}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                this.gotoSearchPage();
              }
            }}
          />
          <button
            className="searchButton"
            onClick={(ev) => {
              this.gotoSearchPage();
              HGA.trackingEventClick({
                action: "click_btn_search_header",
              });
            }}
          >
            <i className="fa fa-search" />
          </button>
          <ul
            className="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content ui-corner-all"
            style={(textSearch && active) ? { display: "block" } : { display: "none" }}
          >
            {resultSearch?.length > 0 &&
              resultSearch.map((v, i) => {
                return (
                  <li className="ui-menu-item" key={i}>
                    <a
                      className="ui-corner-all"
                      href={v.Link}
                      onClick={() => {
                        HGA.trackingEventClick({
                          action: "click_item_search_header",
                          label: v.Text,
                        });
                      }}
                    >
                      <span>{v.Text}</span>
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
  }
}
export default SC_HeaderSearch;
