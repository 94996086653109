import React from 'react'
import {MyUI} from 'apps/Components'
import './styles/home_topslide.css'

class HomeTopSlide extends React.Component{
    _getConfig=()=>{
        return {
            dots: false,
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 10000,
            speed: 2000
        }
    }
    _getData=()=>{
        const {data} = this.props;
        if(data?.length>0){
            return data.map((v,i)=>{
                return {
                    imageUrl: v.ImageUrl,
                    link: v.Link
                }
            })
        }
        return [];
    }
    render(){
        return(
            <div className="home_topslide">
                <MyUI type="m_list_horizontal" cardType="sc_card_banner" data={this._getData()} config={this._getConfig()}/>
            </div>
        )
    }
}
export default HomeTopSlide