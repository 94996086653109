import React from 'react'
import MyUITypes from '../../../../components/MyUI/MyUITypes';
import MyLoadingComponent from '../../../../components/MyLoading/MyLoadingComponent';

/** Control */
import HTML_Editor_CkeditorJS from '../../../../components/MyUI/Types/html_editor_ckeditor';
import InputText from '../../../../components/MyUI/Types/input_text';
import InputTextarea from '../../../../components/MyUI/Types/input_textarea';
import Popover from '../../../../components/MyUI/Types/popover';
import InputCheck from '../../../../components/MyUI/Types/input_check';
import Select2 from '../../../../components/MyUI/Types/select2';
import InputRadio from '../../../../components/MyUI/Types/input_radio'
import PageFilter from '../../../../components/MyUI/Types/page_filter';
import Box from '../../../../components/MyUI/Types/box';
import SpinnerDots from '../../../../components/MyUI/TypesMore/spinner_dots'
import SpinnerRing from '../../../../components/MyUI/TypesMore/spinner_ring'
import MListHorizontal from '../../../../components/MyUI/TypesMobile/m_list_horizontal'
import MyButton from '../../../../components/MyUI/Types/my_button'
import Form from '../../../../components/MyUI/Types/form'
import ReactInputRange from '../../../../components/MyUI/Types/react_input_range';
import ReactPaginationBox from '../../../../components/MyUI/Types/react_pagination_box';
import MyUI_OverlaySeason from '../../../../components/MyUI/TypesMore/overlay_season';

/** Home */
import HomeTopSlide from '../../../shoppingCart/components/MyUI/Types/home_topslide';
import HomeCatProduct from '../../../shoppingCart/components/MyUI/Types/home_cat_product';
import HomeNewComing from '../../../shoppingCart/components/MyUI/Types/home_new_coming';
import HomeRecentBlog from '../../../shoppingCart/components/MyUI/Types/home_recent_blog';
import HomeBestSeller from '../../../shoppingCart/components/MyUI/Types/home_best_seller';
import ScHomeBlogPost from '../../../shoppingCart/components/MyUI/Types/sc_home_blog_post';
import ScEditor_Product from '../../../shoppingCart/components/MyUI/Types/sc_editor_product';
import ScEditor_Blog from '../../../shoppingCart/components/MyUI/Types/sc_editor_blog';
import ScEditorPageContent from '../../../shoppingCart/components/MyUI/Types/sc_editor_pagecontent';
import ScToolbarAdmin from '../../../shoppingCart/components/MyUI/Types/sc_toolbar_admin';
import ScGrid from '../../../shoppingCart/components/MyUI/Types/sc_product_grid';
import ScList from '../../../shoppingCart/components/MyUI/Types/sc_product_list';
import ScTags from '../../../shoppingCart/components/MyUI/Types/sc_tags';
import ScHeaderSearch from '../../../shoppingCart/components/MyUI/Types/sc_header_search';
import ScGoTop from '../../../shoppingCart/components/MyUI/Types/sc_goTop';
import ScGoQuickorderUser from '../../../shoppingCart/components/MyUI/Types/sc_go_quickerorder_user';
import ScCardProductMiniShopping from '../../../shoppingCart/components/MyUI/Types/sc_card_product_minishopping';
import ScModalMiniShoppingCart from '../../../shoppingCart/components/MyUI/Types/sc_modal_mini_shoppingcart';
import ScCardProduct from '../../../shoppingCart/components/MyUI/Types/sc_card_product';
import ScCardBanner from '../../../shoppingCart/components/MyUI/Types/sc_card_banner';
import ScTab from '../../../shoppingCart/components/MyUI/Types/sc_tab';
import ScBreakcumb from '../../../shoppingCart/components/MyUI/Types/sc_breakcumb';
import SC_CategoryLeft from '../../../shoppingCart/components/MyUI/Types/sc_category_left';
import SC_CategoryTop from '../../../shoppingCart/components/MyUI/Types/sc_category_top';
import SC_ProductImages from '../../../shoppingCart/components/MyUI/Types/sc_product_images';
import SC_ProductShortDesc from '../../../shoppingCart/components/MyUI/Types/sc_product_shortdesc';
import SC_AdminList from '../../../shoppingCart/components/MyUI/Types/sc_admin_list';
import SC_Login from '../../../shoppingCart/components/MyUI/Types/sc_login';
import ScLoading from '../../../shoppingCart/components/MyUI/Types/sc_loading';
import SC_Editor_Product_Full from '../../../shoppingCart/components/MyUI/Types/sc_editor_product_full';
import SC_Editor_TestApi from '../../../shoppingCart/components/MyUI/Types/sc_editor_testapi';
import SC_Filter from '../../../shoppingCart/components/MyUI/Types/sc_filter';
import SC_CardProductRecent from '../../../shoppingCart/components/MyUI/Types/sc_card_product_recent';
import SC_ProductRelative from '../../../shoppingCart/components/MyUI/Types/sc_product_relative';
import SC_ProductTag from '../../../shoppingCart/components/MyUI/Types/sc_product_tag';
import SC_BlogPost from '../../../shoppingCart/components/MyUI/Types/sc_blog_post';
import SC_EditorBanner from '../../../shoppingCart/components/MyUI/Types/sc_editor_banner';
import SC_Input_Quantity from '../../../shoppingCart/components/MyUI/Types/sc_input_quantity';
import CommonLogin from '../../../shoppingCart/components/MyUI/Types/sc_login_userpass';
import SC_EditorHomeCat from '../../../shoppingCart/components/MyUI/Types/sc_editor_home_cat';
import SC_ModalSelectPhotos from '../../../shoppingCart/components/MyUI/Types/sc_modal_select_photos';
import SC_EditorFooter from '../../../shoppingCart/components/MyUI/Types/sc_editor_footer';
import SC_Helmet from '../../../shoppingCart/components/MyUI/Types/sc_helmet';
import SC_UserQuickOrder from '../../../shoppingCart/components/MyUI/Types/sc_quickorder_user';
import SC_List from '../../../shoppingCart/components/MyUI/Types/sc_list';
import SC_Project_Attribute from '../../../shoppingCart/components/MyUI/Types/sc_product_attribute'
import SC_Editor_Product_Image from '../../../shoppingCart/components/MyUI/Types/sc_editor_product_image'
import SC_Modal_Editor_Attribute_Image_Product from '../../../shoppingCart/components/MyUI/Types/sc_modal_editor_attribute_image_product'
import SC_WishlistTable from '../../../shoppingCart/components/MyUI/Types/sc_wishlist_table'
import SC_CardProductInCart from '../../../shoppingCart/components/MyUI/Types/sc_card_product_in_cart';
import SC_MenuMobile from '../../../shoppingCart/components/MyUI/Types/sc_menu_mobile';

const SC_ModalAddCart = React.lazy(() => import('../../../shoppingCart/components/MyUI/Types/sc_modal_addcart'));
const SC_ModalAddCartWithOptions = React.lazy(() => import('../../../shoppingCart/components/MyUI/Types/sc_modal_addcart_with_options'));
const SC_ModalUpdateOptions = React.lazy(() => import('../../../shoppingCart/components/MyUI/Types/sc_modal_update_options'));
const SC_CartAddress = React.lazy(() => import('../../../shoppingCart/components/MyUI/Types/sc_cart_address'));
const SC_CartTotal = React.lazy(() => import('../../../shoppingCart/components/MyUI/Types/sc_cart_total'));
const SC_CartBill = React.lazy(() => import('../../../shoppingCart/components/MyUI/Types/sc_cart_bill'));
const SC_PreviewBill = React.lazy(() => import('../../../shoppingCart/components/MyUI/Types/sc_preview_bill'));
const SC_PreviewCart = React.lazy(() => import('../../../shoppingCart/components/MyUI/Types/sc_preview_cart'));
const SC_PreviewShipping = React.lazy(() => import('../../../shoppingCart/components/MyUI/Types/sc_preview_shipping'));
const SC_PreviewTotal = React.lazy(() => import('../../../shoppingCart/components/MyUI/Types/sc_preview_total'));
const SC_Address = React.lazy(() => import('../../../shoppingCart/components/MyUI/Types/sc_address'));
const SC_BillCard = React.lazy(() => import('../../../shoppingCart/components/MyUI/Types/sc_bill_card'));

const AppMyUI = {
  init(){
    /* Control type */
    MyUITypes.addTypes('popover',(props)=>{
      return ( <Popover {...props}/>  )
    });

    /* Shoping Cart Type*/
    MyUITypes.addTypes('home_topslide',(props)=>{
      return ( <HomeTopSlide {...props}/> )
    });
    MyUITypes.addTypes('home_cat_product',(props)=>{
        return ( <HomeCatProduct {...props}/> )
    });
    MyUITypes.addTypes('home_new_coming',(props)=>{
        return ( <HomeNewComing {...props}/> )
    });
    MyUITypes.addTypes('home_best_seller',(props)=>{
        return ( <HomeBestSeller {...props}/> )
    });
    MyUITypes.addTypes('home_recent_blog',(props)=>{
        return ( <HomeRecentBlog {...props}/> )
    });
    MyUITypes.addTypes('sc_card_product',(props)=>{
        return ( <ScCardProduct {...props}/> )
    });
    MyUITypes.addTypes('sc_home_blog_post',(props)=>{
      return ( <ScHomeBlogPost {...props}/> )
    });
    MyUITypes.addTypes('sc_card_banner',(props)=>{
      return ( <ScCardBanner {...props}/> )
    });
    MyUITypes.addTypes('m_list_horizontal',(props)=>{
      return ( <MListHorizontal {...props}/>  )
    });
    MyUITypes.addTypes('sc_category_left',(props)=>{
      return ( <SC_CategoryLeft {...props}/>  )
    });
    MyUITypes.addTypes('sc_category_top',(props)=>{
      return ( <SC_CategoryTop {...props}/>  )
    });
    MyUITypes.addTypes('sc_filter',(props)=>{
      return ( <SC_Filter {...props}/>  )
    });
    MyUITypes.addTypes('sc_product_grid',(props)=>{
      return ( <ScGrid {...props}/>  )
    });
    MyUITypes.addTypes('sc_product_list',(props)=>{
      return ( <ScList {...props}/>  )
    });
    MyUITypes.addTypes('sc_tags',(props)=>{
      return ( <ScTags {...props}/>  )
    });
    MyUITypes.addTypes('sc_card_product_recent',(props)=>{
      return ( <SC_CardProductRecent {...props}/>  )
    });
    MyUITypes.addTypes('sc_login',(props)=>{
      return ( <SC_Login {...props}/> )
    });
    MyUITypes.addTypes('sc_product_images',(props)=>{
      return ( <SC_ProductImages {...props}/>  )
    });
    MyUITypes.addTypes('sc_product_shortdesc',(props)=>{
      return ( <SC_ProductShortDesc {...props}/>  )
    });
    MyUITypes.addTypes('sc_product_relative',(props)=>{
      return ( <SC_ProductRelative {...props}/>  )
    });
    MyUITypes.addTypes('sc_product_tag',(props)=>{
      return ( <SC_ProductTag {...props}/>  )
    });
    MyUITypes.addTypes('sc_product_attribute',(props)=>{
      return ( <SC_Project_Attribute {...props}/>  )
    });
    MyUITypes.addTypes('sc_tab',(props)=>{
      return ( <ScTab {...props}/>  )
    });
    MyUITypes.addTypes('sc_gotop',(props)=>{
      return ( <ScGoTop {...props}/>  )
    });
    MyUITypes.addTypes('sc_go_quickorder_user',(props)=>{
      return ( <ScGoQuickorderUser {...props}/>  )
    });
    MyUITypes.addTypes('sc_modal_mini_shoppingcart',(props)=>{
      return ( <ScModalMiniShoppingCart {...props}/>  )
    });
    MyUITypes.addTypes('sc_card_product_minishopping',(props)=>{
      return ( <ScCardProductMiniShopping {...props}/>  )
    });
    MyUITypes.addTypes('sc_modal_addcart',(props)=>{
      return ( <React.Suspense fallback={<MyLoadingComponent />}> <SC_ModalAddCart {...props}/> </React.Suspense>   )
    });
    MyUITypes.addTypes('sc_modal_addcart_with_options',(props)=>{
      return ( <React.Suspense fallback={<MyLoadingComponent />}> <SC_ModalAddCartWithOptions {...props}/> </React.Suspense>   )
    });
    MyUITypes.addTypes('sc_modal_update_options',(props)=>{
      return ( <React.Suspense fallback={<MyLoadingComponent />}> <SC_ModalUpdateOptions {...props}/> </React.Suspense>   )
    });
    MyUITypes.addTypes('sc_header_search',(props)=>{
      return ( <ScHeaderSearch {...props}/>  )
    });
    MyUITypes.addTypes('sc_cart_address',(props)=>{
      return ( <SC_CartAddress {...props}/>  )
    });
    MyUITypes.addTypes('sc_cart_total',(props)=>{
      return ( <SC_CartTotal {...props}/>  )
    });
    MyUITypes.addTypes('sc_cart_bill',(props)=>{
      return ( <SC_CartBill {...props}/>  )
    });
    MyUITypes.addTypes('sc_preview_bill',(props)=>{
      return ( <SC_PreviewBill {...props}/>  )
    });
    MyUITypes.addTypes('sc_preview_cart',(props)=>{
      return ( <SC_PreviewCart {...props}/>  )
    });
    MyUITypes.addTypes('sc_preview_shipping',(props)=>{
      return ( <SC_PreviewShipping {...props}/>  )
    });
    MyUITypes.addTypes('sc_preview_total',(props)=>{
      return ( <SC_PreviewTotal {...props}/>  )
    });
    MyUITypes.addTypes('sc_breakcumb',(props)=>{
      return ( <ScBreakcumb {...props}/>  )
    });
    MyUITypes.addTypes('sc_blog_post',(props)=>{
      return ( <SC_BlogPost {...props}/>  )
    });
    MyUITypes.addTypes('sc_address',(props)=>{
      return ( <SC_Address {...props}/>  )
    });
    MyUITypes.addTypes('sc_bill_card',(props)=>{
      return ( <SC_BillCard {...props}/>  )
    });
    MyUITypes.addTypes('react_input_range',(props)=>{
      return ( <ReactInputRange {...props}/>  )
    });
    MyUITypes.addTypes('react_pagination_box',(props)=>{
      return ( <React.Suspense fallback={<MyLoadingComponent />}> <ReactPaginationBox {...props}/> </React.Suspense>  )
    });
    MyUITypes.addTypes('spinner_dots',(props)=>{
      return ( <SpinnerDots {...props}/> ) 
    });
    MyUITypes.addTypes('spinner_ring',(props)=>{
      return ( <SpinnerRing {...props}/> ) 
    });
    MyUITypes.addTypes('sc_editor_blog',(props)=>{
      return ( <ScEditor_Blog {...props}/>  )
    });
    MyUITypes.addTypes('sc_editor_pagecontent',(props)=>{
      return ( <ScEditorPageContent {...props}/>  )
    });
    MyUITypes.addTypes('sc_editor_product',(props)=>{
      return ( <ScEditor_Product {...props}/>  )
    });
    MyUITypes.addTypes('html_editor_ckeditor',(props)=>{
      return ( <HTML_Editor_CkeditorJS {...props}/>)
    });
    MyUITypes.addTypes('input_text',(props)=>{
      return ( <InputText {...props}/>  )
    });
    MyUITypes.addTypes('input_textarea',(props)=>{
      return ( <InputTextarea {...props}/>  )
    });
    MyUITypes.addTypes('input_check',(props)=>{
      return ( <InputCheck {...props}/>  )
    });
    MyUITypes.addTypes('page_filter',(props)=>{
      return ( <PageFilter {...props}/>  )
    });
    MyUITypes.addTypes('box',(props)=>{
      return ( <Box {...props}/>  )
    });
    MyUITypes.addTypes('select2',(props)=>{
      return ( <Select2 {...props}/>  )
    });
    MyUITypes.addTypes('sc_toolbar_admin',(props)=>{
      return ( <ScToolbarAdmin {...props}/>  )
    });
    MyUITypes.addTypes('my_button',(props)=>{
      return ( <MyButton {...props}/>  )
    });
    MyUITypes.addTypes('form',(props)=>{
      return ( <React.Suspense fallback={<MyLoadingComponent />}><Form {...props}/></React.Suspense>   )
    });
    MyUITypes.addTypes('sc_admin_list',(props)=>{
      return ( <SC_AdminList {...props}/>  )
    });
    MyUITypes.addTypes('sc_loading',(props)=>{ return ( <ScLoading {...props}/>  ) });
    MyUITypes.addTypes('sc_editor_product_full',(props)=>{
      return ( <SC_Editor_Product_Full {...props}/>  )
    });
    MyUITypes.addTypes('sc_editor_testapi',(props)=>{
      return ( <SC_Editor_TestApi {...props}/>  )
    });
    MyUITypes.addTypes('sc_editor_banner',(props)=>{
      return ( <SC_EditorBanner {...props}/>  )
    });
    MyUITypes.addTypes('sc_input_quantity',(props)=>{
      return ( <SC_Input_Quantity {...props}/>  )
    });
    MyUITypes.addTypes('common_login',(props)=>{
      return ( <React.Suspense fallback={<MyLoadingComponent />}><CommonLogin {...props}/></React.Suspense>   )
    });
    MyUITypes.addTypes('sc_editor_home_cat',(props)=>{
      return ( <SC_EditorHomeCat {...props}/>  )
    });
    MyUITypes.addTypes('myui_overlay_season',(props)=>{
      return ( <MyUI_OverlaySeason {...props}/>  )
    });
    MyUITypes.addTypes('input_radio',(props)=>{
      return ( <InputRadio {...props}/>  )
    });
    MyUITypes.addTypes('sc_modal_select_photos',(props)=>{
      return ( <React.Suspense fallback={<MyLoadingComponent />}><SC_ModalSelectPhotos {...props}/></React.Suspense>   )
    });
    MyUITypes.addTypes('sc_editor_footer',(props)=>{
      return ( <SC_EditorFooter {...props}/>  )
    });
    MyUITypes.addTypes('sc_helmet',(props)=>{
      return ( <SC_Helmet {...props}/>  )
    });
    MyUITypes.addTypes('sc_quickorder_user',(props)=>{
      return ( <SC_UserQuickOrder {...props}/>  )
    });
    MyUITypes.addTypes('sc_list',(props)=>{
      return ( <React.Suspense fallback={<MyLoadingComponent />}> <SC_List {...props}/> </React.Suspense>   )
    });
    MyUITypes.addTypes('sc_editor_product_image',(props)=>{
      return ( <React.Suspense fallback={<MyLoadingComponent />}> <SC_Editor_Product_Image {...props}/> </React.Suspense>   )
    });
    MyUITypes.addTypes('sc_modal_editor_attribute_image_product',(props)=>{
      return ( <React.Suspense fallback={<MyLoadingComponent />}> <SC_Modal_Editor_Attribute_Image_Product {...props}/> </React.Suspense>   )
    });
    MyUITypes.addTypes('sc_wishlist_table',(props)=>{
      return ( <SC_WishlistTable {...props}/>  )
    });
    MyUITypes.addTypes('sc_card_product_in_cart',(props)=>{
      return ( <SC_CardProductInCart {...props}/>  )
    });
    MyUITypes.addTypes('sc_menu_mobile',(props)=>{
      return ( <SC_MenuMobile {...props}/>  )
    }); 
  },
}
export default AppMyUI;