import React from 'react';
import HGA from '../../../../../helpers/GA'
import { HText, HConfig, HUtils } from 'apps/Helpers';

class SC_CategoryTop extends React.Component{
    render(){
        const {viewMode, sortType, numberInPage, changeViewMode, changeNumberInPage, changeTypeSort}=this.props;
        const pageSizeOptions = HUtils.Obj.get(HConfig.fnList.getConfig("categoryConfig"), "pageSizeOptions")
        return(
            <div className="product-selectors">
                <div className="product-viewmode">
                    <a className={`viewmode-icon grid ${viewMode==0?'selected':''}`} title={HText.get("text_grid_view")} data-dropdownoptionvalue="grid" onClick={()=>{
                        changeViewMode(0);
                        HGA.trackingEventClick({
                            action: 'click_grid',
                        }) 
                    }}>{HText.get("text_grid_view")}</a>
                    <a className={`viewmode-icon list ${viewMode==1?'selected':''}`} title={HText.get("text_list_view")} data-dropdownoptionvalue="list" onClick={()=>
                        {
                            changeViewMode(1);
                            HGA.trackingEventClick({
                                action: 'click_list',
                            }) 
                        }
                    }>{HText.get("text_list_view")}</a>
                </div>
                <div className="product-sorting">
                    <span>{HText.get("text_filter_order")}</span>
                    <select id="products-orderby" name="products-orderby" className="sortOptionsDropDown" value={sortType} onChange={(e)=>changeTypeSort(e.currentTarget.value)}>
                        <option value="1">{HText.get("text_order_newest")}</option>
                        <option value="2">{HText.get("text_order_price_low_to_high")}</option>
                        <option value="3">{HText.get("text_order_price_high_to_low")}</option>
                    </select>
                </div>
                <div className="product-page-size">
                    <span>{HText.get("text_pagination_pre")}</span>
                    <select 
                        id="products-pagesize" 
                        name="products-pagesize" 
                        className="productsPageSizeDropDown" 
                        value={numberInPage} 
                        onChange={(e)=>changeNumberInPage(e)}
                    >
                        {
                            pageSizeOptions?.length>0 &&
                            pageSizeOptions.map((v, i)=>{
                                return <option key={i} value={v.Value}>{v.Text}</option>
                            })
                        }
                    </select>
                    <span>{HText.get("text_pagination_suf")}</span>
                </div>
            </div>
        )
    }
}
export default SC_CategoryTop