import React from 'react'
import MyPageTypes from '../../../../components/MyPage/MyPageTypes';
import MyLoadingComponent from '../../../../components/MyLoading/MyLoadingComponent';
import AppMyPage from '.';

import SC_Home from './Types/SC_Home'
import SC_Product_Detail from './Types/SC_Product_Detail';
import SC_Category from './Types/SC_Category';
import SC_Search from './Types/SC_Search';

const AppMyPageType1 = {
  init(){
    MyPageTypes.addTypes('sc_home',(props)=>{
      return ( <SC_Home {...props}/> )
    });
    MyPageTypes.addTypes('sc_product_detail',(props)=>{
      return ( <SC_Product_Detail {...props}/> )
    });
    MyPageTypes.addTypes('sc_category',(props)=>{
      return ( <SC_Category {...props}/> )
    });
    MyPageTypes.addTypes('sc_search',(props)=>{
      return ( <SC_Search {...props}/> )
    });
    AppMyPage.init();  
  }
}
export default AppMyPageType1;
