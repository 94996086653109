import React from 'react'
import {Helmet} from "react-helmet";
import {MyUI} from '../../../../Components'
import {HUtils, HLink} from '../../../../Helpers'
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import './styles/common.css'

class SC_Admin extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            tabIndex: 0
        }
    }
    componentDidMount(){
        const _pathname = window.location.pathname;
        let _tabIndex = 0;
        if(_pathname == "/admin-content-material"){
            _tabIndex = 0
        }
        else if(_pathname == "/admin-content-blog"){
            _tabIndex = 1
        }
        else if(_pathname == "/admin-content-banner"){
            _tabIndex = 2
        }
        else if(_pathname == "/admin-content-topic"){
            _tabIndex = 3
        }
        else if(_pathname == "/admin-home-category"){
            _tabIndex = 4
        }
        else if(_pathname == "/admin-content-footer"){
            _tabIndex = 5
        }
        this.setState({
            tabIndex: _tabIndex
        })
    }
    onChangeTab=(index)=>{
        this.setState({
            tabIndex: index
        })
    }
    renderHelmet = () =>{
        const meta = {
            MetaTitle: 'Quản lý',
            MetaKeyWords: 'Admin',
            MetaDescription: 'Các chức năng quản lý'
        }
        return <MyUI type="sc_helmet" data = {meta} noindex={true}/>
    }
    renderTab=()=>{
        const {tabIndex} = this.state;
        return(
            <div className="tab-header">
                <div className={`label ${tabIndex==0?'':'inactive'}`} onClick={()=>HLink.push("/admin-content-material")}>Sản phẩm</div>
                <div className={`label ${tabIndex==1?'':'inactive'}`} onClick={()=>HLink.push("/admin-content-blog")}>Blog</div>
                <div className={`label ${tabIndex==2?'':'inactive'}`} onClick={()=>HLink.push("/admin-content-banner")}>Banner</div>
                <div className={`label ${tabIndex==3?'':'inactive'}`} onClick={()=>HLink.push("/admin-content-topic")}>Topic</div>
                <div className={`label ${tabIndex==4?'':'inactive'}`} onClick={()=>HLink.push("/admin-home-category")}>Home category</div>
                <div className={`label ${tabIndex==5?'':'inactive'}`} onClick={()=>HLink.push("/admin-content-footer")}>Footer sản phẩm</div>
            </div>
        )
    }
    renderContent = () =>{
        const {adminButtons} = this.props;
        const {tabIndex} = this.state;
        return(
            <div>
                {
                    tabIndex == 0 &&
                    <MyUI type="sc_admin_list" tab={0} title="Sản phẩm" APIUrl="Dashboard/GetListMaterial4Website" adminButtons={adminButtons}/>
                }
                {
                    tabIndex == 1 &&
                    <MyUI type="sc_admin_list" tab={1} title="Bài viết" APIUrl="DashboardForWebsite/GetDataListBlog4Website" adminButtons={adminButtons}/>
                }
                {
                        tabIndex == 2 &&
                        <MyUI type="sc_editor_banner"/>
                }
                {
                    tabIndex == 3 &&
                    <MyUI type="sc_admin_list" tab={3} title="Topic" 
                        APIUrl="Dashboard/GetListTopic4Website" 
                        adminButtons={adminButtons}                                        
                    />
                }
                    {
                    tabIndex == 4 &&
                    <MyUI type="sc_editor_home_cat"/>
                }
                {
                    tabIndex == 5 &&
                    <MyUI type="sc_editor_footer"/>
                }
            </div>
        )
    }
    render(){
        const {adminButtons, isLoadedGetMenuPage} = this.props;
        const ShowBtnAdmin = HUtils.Obj.get(adminButtons, "ShowBtnAdmin")
        // console.warn("adminButtons", adminButtons,ShowBtnAdmin,isLoadedGetMenuPage)
        if(isLoadedGetMenuPage == false){
            return<div></div>
        }
        else if(isLoadedGetMenuPage == true && ShowBtnAdmin!=true){
            return <Redirect to="/"/>
        }
        return(
            <div className="master-wrapper-content">
                { this.renderHelmet() }
                <div className="master-column-wrapper">
                    <div className="center-1">
                        <div className="page admin-page">
                            <div className="page-title">
                                <h1>Quản lý</h1>
                            </div>
                            <div className="page-body">
                                { this.renderTab() }
                                { this.renderContent() }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    if(state.shop && state.shop.adminButtons){
        return {
            adminButtons: state.shop.adminButtons,
            isLoadedGetMenuPage: state.shop.isLoadedGetMenuPage
        }
    }
    return {
        isLoadedGetMenuPage: state.shop.isLoadedGetMenuPage
    };
}
export default connect(mapStateToProps,null)(SC_Admin)