import React from 'react'
import {MyUI} from 'apps/Components';
import MyUITypes from 'components/MyUI/MyUITypes'
import PageExt from 'components/MyPage/Types/_ext';
import SApiAxios from 'helpers/ApiAxios'
import {HStoreRedux, HLocalStorage,HConfig,HUtils} from 'apps/Helpers'
import './styles/common.css'
import './styles/sc_home.css'

class SC_Home extends PageExt {    
    constructor(props){
        super(props);
    }
    componentDidMount(){
        this.requestDashboard();

    }
    requestDashboard=()=>{
        this.setState({
            isLoadingDashboard: true,
            errorDashboard: null,
        },()=>{
            SApiAxios.generic({
                request:{
                    method: 'POST',
                    path: 'DashboardForWebsite',
                    name: 'MyDashboardForWebSite'
                },
                data: {},
                successCallBack:(response)=>{
                    if(response?.Data?.Menus){
                        HLocalStorage.setObject("menus", response.Data.Menus)
                        HStoreRedux.dispatch({type: "setObjInShop", categoryMenu: response.Data.Menus})
                    }
                    this.setState({
                        dataDashboard: response.Data,
                        isLoadingDashboard: false,
                        errorDashboard: null,
                        lastGetDataDashboard: new Date().getTime()
                    },()=>{
                        this.hideLoadingApp();
                    })
                },
                errorCallBack:(error,response)=>{
                    this.setState({
                        isLoadingDashboard: false,
                        errorDashboard: error || response,
                    },()=>{
                        this.hideLoadingApp();
                    })          
                }
            })
        }) 
    } 
    getItemsDashboard=()=>{
        const {dataDashboard} = this.state;
        if(dataDashboard && dataDashboard.Items){
            return dataDashboard.Items;
        }
    }
    getMetaData=()=>{
        const {dataDashboard} = this.state;
        return HUtils.Obj.get(dataDashboard,'MetaData',{});
    }
    buildSnippets=()=>{
        let _snippet = {
            "@context":"http://schema.org",
            "@type":"WebSite",
            "name":HConfig.fnList.getConfig('metaName'),
            "url":HConfig.fnList.getConfig('metaUrl'),
            "potentialAction":{
                "@type":"SearchAction",
                "target":"/search?query={search_term_string}",
                "query-input":"required name=search_term_string"
            },
            "sameAs":[
                "https://www.facebook.com/ajumagarden/",
                "https://www.instagram.com/ajumagarden/",
            ]
        }
        return _snippet;
    }
    renderHelmet = () =>{
        const meta = this.getMetaData();
        return <MyUI type="sc_helmet" data = {meta} buildSnippets={this.buildSnippets}/>
    }
    renderList=()=>{
        let _items = this.getItemsDashboard();
        if(_items && _items.length>0){
            let _ui = [];
            for(let i=0;i<_items.length;i++){
                let _item = _items[i];
                let _type = _item.Type;
                let _slugGrp = HUtils.Obj.get(_item, "Config.GrpMaterial.Link")
                if(MyUITypes.Types[_type]){
                    _ui.push(
                        <section id={_slugGrp}><MyUI type={_type} key={i} data={_item.Data} config={_item.Config}/></section>
                    )
                }                
            }
            return _ui;
        }
    }
    render(){
        const {isLoadingDashboard} = this.state;
        return (
            <div>
                { this.renderHelmet() }
                {
                    isLoadingDashboard !==false &&
                    <MyUI type="sc_loading"/>
                }
                <div className="master-column-wrapper">
                    <div className="center-1">
                        <div className="page home-page">
                            <div className="page-body">
                                {
                                    this.renderList()
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SC_Home