import React from 'react'
import SC_BreakCumb from 'apps/shoppingCart/components/MyUI/Types/sc_breakcumb'
import './styles/sc_breakcumb.css'

class SC_BreakCumb_1 extends SC_BreakCumb{
    render(){
        return(
            <div className="bcb_type_1 breadcrumb">
                <ol itemScope itemType="https://schema.org/BreadcrumbList">
                    <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                        {/* <a itemProp="item" href="/">Trang chủ</a>
                        <span itemProp="name" className="delimiter">Trang chủ</span> */}
                        <meta itemProp="position" content="1" />
                    </li>
                    {
                        this.renderBreakcumb()
                    }
                </ol>
            </div>
        )
    }
}
export default SC_BreakCumb_1