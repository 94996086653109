import React from 'react'
import Slider from '../../../libs/ReactSlick/index'
import './styles/m_list_horizontal.css'
import MyUI from '../MyUI'

class MListHorizontal extends React.Component{
  defaultSettings=()=>{
    return{
      arrows:false,
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5
    }
  }
  renderSlide=()=>{
    const {data, cardType,cardMoreProps, customComponent} = this.props;
    if(data && data.length>0){
      if(customComponent){
        return data.map((item, index)=>{
          return customComponent(item)
        })
      }
      else if(cardType){
        return data.map((item,index)=>{
          return <MyUI key={index} type={cardType} data={item} {...cardMoreProps}/>
        })
      }
    }
    return <div></div>
  }
  render(){
    const {config} = this.props;
    const settings = config || this.defaultSettings();
    return(
      <div className="mui-m_list_horizontal">
          <Slider {...settings}>
            {
              this.renderSlide()
            }
        </Slider>
      </div>
    )
  }
}
export default MListHorizontal