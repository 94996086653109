import React from 'react'
import {MyUI} from '../../../../Components'
import {HFormat, HConfig, HText} from '../../../../Helpers'
import './styles/sc_filter.css'

class SC_Filter extends React.Component{
    render(){
        const {fnList, defaultValue} = this.props;
        const _filterPriceRange = HConfig.fnList.getConfig("filterPriceRange") || {
          min: 0,
          max: 2000000,
          step: 10000
        }
        return (
          <div className="sc_filter nopAjaxFilters7Spikes">
            <div className="filtersPanel">
              <div
                className="block filter-block priceRangeFilterPanel7Spikes"
                data-currentcurrencysymbol="₫"
              >
                <div className="title">
                  <a className="toggleControl">{HText.get("text_filter_price")}</a>
                </div>
                <div className="filtersGroupPanel ">
                  <MyUI
                    type="react_input_range"
                    minValue={_filterPriceRange.min}
                    maxValue={_filterPriceRange.max}
                    defaultValue={defaultValue}
                    showDefaultMinMax={false}
                    step={_filterPriceRange.step}
                    labelInline={true}
                    // configLabel2Line={{
                    //     max: "bottom"
                    // }}
                    formatLabel={(v) => {
                      return HFormat.Money.formatMoneyForAjuma(v);
                    }}
                    onChangeComplete={(v) => {
                      console.warn("onChangeComplete:", v);
                      fnList && fnList.onFilter(v);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        );
    }
}
export default SC_Filter