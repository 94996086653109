import React from 'react'
import {MyModal, MyUI} from 'apps/Components'
import {HUtils, HFormat, HText, HLink} from 'apps/Helpers'
import SCExt from './_ext_add_cart';
import HShopUtils from '../../../helpers/Utils';
import "./styles/sc_product_shortdesc.css"

class SC_ProductShortDesc extends SCExt{
    constructor(props){
        super(props);
    }
    componentDidMount(){
        const {data} = this.props;
        const _attributes = this.getGroupAttribute(data);
        if (_attributes.length == 0) {
          this.setState({isValidAttributeProduct: true})
        }
    }
    shouldComponentUpdate(nextProps){
        if(this.props.data !== nextProps.data){
            const _attributes = this.getGroupAttribute(nextProps.data);
            if(_attributes.length == 0){
                this.setState({isValidAttributeProduct: true});
            }
            else{
                this.setState({isValidAttributeProduct: false});
            }
        }
        return true;
    }
    checkShowAddCartArea=(data)=>{
        const _IsCall4WebOrder = data.IsCall4WebOrder;
        const _Quantity = HUtils.Obj.get(data, "Inventory.Quantity");
        const _DontCareInventory = HUtils.Obj.get(data, "Inventory.DontCareInventory");

        if(_IsCall4WebOrder == true){
            return false;
        }
        else if(_Quantity <=0 && _DontCareInventory !== true){
            return false;
        }
        return true;
    }
    initProductAttributeFromData = (data) =>{
        let {product} = this.state;
        const _OrderDetail_AttributeList = data?.OrderDetail_AttributeList;
        if(!product.OrderDetail_AttributeList && _OrderDetail_AttributeList){
            const _AttributeList = HUtils.Obj.get(data, "AttributeList");
            const _attributeGroup = this.getGroupAttribute(data);
            if(_attributeGroup.length>0){
                let _stateAttributeInit = this.initAttributeState(_AttributeList);
                for(let i=0; i<_attributeGroup.length; i++){
                    const _i = _attributeGroup[i];
                    _stateAttributeInit[_i] = _OrderDetail_AttributeList[_i];      
                }
                product.OrderDetail_AttributeList = _stateAttributeInit;
                this.state.product = {...product}
                this.state.isValidAttributeProduct = this.checkAttributes(data, product.OrderDetail_AttributeList)
            }
        }
    }
    refreshUrlHaveAtttributes = () =>{
        let params = HLink.getAllUrlParams();
        if(Object.keys(params).length>0){
            HLink.setNewUrlWithoutReload(window.location.href.split('?')[0])
        }
    }
    onAddWishList = (data) =>{
        let _isInWishList = HShopUtils.checkItemInStore({store: "wishlist", item: data, isHaveAttribute: false})
        if(_isInWishList){
            HShopUtils.removeProductWishList({product: data})
        }
        else{
            this.addProductToWishList(data);
            MyModal.Helpers.showMyUI(
            "sc_modal_addcart",
            {
                textSubmit: HText.get("text_modal_btn_go_wishlist"),
                product: data,
                link: "/wishlist",
                modalType: "wishlist",
                showFindMore: true
            },
            {
                title: HText.get("text_modal_title_add_wish"),
                closeButton: true,
                size: "lg",
                className: "mmd-card-product"
            }
            );
        }
        this.forceUpdate();
    }
    onAddCart=(data)=>{
        const { product, imageActive } = this.state;
        this.addCart({
            product: data, 
            productUpdate: {
                ...product, 
                ImageCurrentAttribute: imageActive
            }, 
            attributes: this.getGroupAttribute(data)
        });
        MyModal.Helpers.showMyUI(
          "sc_modal_addcart",
          {
            textSubmit: HText.get("text_modal_btn_go_cart"),
            product: {
              ...data,
              ...product,
              ImageCurrentAttribute: imageActive
            },
            link: "/cart",
            modalType: "cart",
            showFindMore: true
          },
          {
            title: HText.get("text_modal_title_add_cart"),
            closeButton: true,
            size: "lg",
            className: "mmd-card-product"
          }
        );
    }
    renderBasicInformationProduct = (data) =>{
        const _Name = data.MaterialName;
        const _MaterialCode = data.MaterialCode;
        const _Price = data.SellPriceText;
        const _SellPrice= data.SellPrice;
        const _Description = data.ShortDescription;
        const _Rating = data.Rating;
        const _IsCall4WebOrder = data.IsCall4WebOrder;
        const _Discount_Text = HUtils.Obj.get(data, 'DiscountInfo.Discount_Text');
        const _QuantityMin = HUtils.Obj.get(data, 'DiscountInfo.QuantityMin');
        const _DiscountMoney = HUtils.Obj.get(data, 'DiscountInfo.DiscountMoney');
        const _PriceAfterDiscount = _SellPrice - _DiscountMoney;
        return(
            <>
                {
                    _Name && 
                    <div className="product-name">
                        <h1 style={{marginBottom: 0}}>
                            {_Name}
                        </h1>
                    </div>
                }
                <div className="product-sku" style={{padding: "5px 0px", fontSize: 15}}>
                    {_MaterialCode}
                </div>
                <div className="product-reviews-overview">
                    <div className="product-review-box">
                        <div className="rating">
                            <div style={{width: `${_Rating*20}%`}}>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    _IsCall4WebOrder !==true &&
                    <div className="prices">
                        {
                            _Price && 
                            <div className="product-price">
                                {
                                    _QuantityMin <= 1 && (
                                        <span content={_PriceAfterDiscount} className="price-value-coupon">
                                            {HFormat.Money.formatMoneyForAjuma(_PriceAfterDiscount)}
                                        </span>
                                    )
                                }
                                <span content={_Price} className={`price-value${_QuantityMin <= 1? " origin" : ""}`}>
                                    {_Price}
                                </span>
                            </div>
                        }
                        {
                                (_QuantityMin >1 && _Discount_Text) &&
                                <div className="product-coupon">
                                    <div>{_Discount_Text}</div>
                                </div>
                            }
                        {
                            (_QuantityMin >1 && _Discount_Text) && <br className="space"/>
                        }
                        <div className="tax-shipping-info">
                            {HText.get("text_detail_shipping_fee_des")}
                        </div>
                    </div>
                }
                {
                        _IsCall4WebOrder ===true && <div className="product-price">
                        <span className="price-value" style={{color: "var(--color-danger)"}}>
                            {HText.get("text_detail_contact_order")}
                        </span>
                    </div>
                }
                {
                    _Description &&
                    <div className="short-description">
                        {_Description}
                    </div>
                }
            </>
        )
    }
    renderAttributeProduct = (data) =>{
        const {fnList} = this.props;
        return this.renderAttributeGroup(data, {
            fnAfterChange: (a)=>{
                fnList?.fnSelectAttribute && fnList.fnSelectAttribute(a);
                this.refreshUrlHaveAtttributes()
            }
        })
    }
    renderProductAction = (data) =>{
        const {product, isValidAttributeProduct} = this.state;
        const {Quantity} = product;
        const _disableAddCart = !isValidAttributeProduct;
        let _isInWishList = HShopUtils.checkItemInStore({store: "wishlist", item: data, isHaveAttribute: false})
        return(
            <div className="add-to-cart sevenspikes-ajaxcart">
                <div className="add-to-cart-panel">
                    {
                        (this.checkShowAddCartArea(data)==true) &&
                        <div style={{display: 'flex'}}>
                            <MyUI type="sc_input_quantity" isBtnVertical={true} value={Quantity} className="quantity-control" onChange={(value)=>{this.onChangeQuantity(value)}}/>
                            <div className="ajax-cart-button-wrapper">
                                <button 
                                    disabled={_disableAddCart} 
                                    className="button-1 add-to-cart-button" 
                                    onClick={()=>this.onAddCart(data)}
                                >
                                    <i className="fa fa-shopping-basket"/>
                                    <span>{HText.get("text_product_add_cart")}</span>
                                </button>
                            </div>
                        </div>
                    }
                    <div className="add-to-wishlist" style={{margin: '5px'}}>
                        {/* <input type="button" className={`button-2 add-to-wishlist-button${_isInWishList ? " active": ""}`} title="Yêu thích" onClick={()=>this.onAddWishList(data)}/> */}
                        <button
                            title={HText.get("text_product_add_wishlist")}
                            className={`add-to-wishlist-button${
                                _isInWishList ? " active" : ""
                            }`}
                            onClick={()=>this.onAddWishList(data)}
                        >
                            <i className="fa fa-heart" />
                        </button>
                    </div>
                </div>
            </div>
        )
    }
    render(){
        const {data} = this.props;
        this.initProductAttributeFromData(data)
        if(data){
            const _InventoryQuantity = HUtils.Obj.get(data, "Inventory.Quantity");
            const _ContentDisplayOnWeb = HUtils.Obj.get(data, "Inventory.ContentDisplayOnWeb");
            const _IsCall4WebOrder = data.IsCall4WebOrder;
            return(
                <div className="overview">
                    {this.renderBasicInformationProduct(data)}
                    {this.renderAttributeProduct(data)}
                    {this.renderProductAction(data)}                 
                    {
                        (_InventoryQuantity <=0 && _IsCall4WebOrder!==true) &&
                        <div style={{color: 'var(--color-danger)', fontWeight: 'bold'}}>*{_ContentDisplayOnWeb}</div>
                    }
                </div>
            )
        }
        return <div></div>
    }
}
export default SC_ProductShortDesc