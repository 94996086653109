import React from "react";
import { MyModal } from "apps/Components";
import { HUtils, HFormat, HConfig, HText } from "apps/Helpers";
import HGA from "helpers/GA";
import HShopUtils from "apps/shoppingCart/helpers/Utils";
import { connect } from "react-redux";
import "./styles/sc_card_product.css";

class SC_CardProduct extends React.Component {
  onClickAddCart = (data) => {
    const _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions");
    if (_haveProductOptions) {
      MyModal.Helpers.showMyUI(
        "sc_modal_addcart_with_options",
        {
          data: data,
          link: "/cart",
        },
        {
          title: data.MaterialName,
          closeButton: true,
          size: "lg",
          className: "mmd-card-product",
        }
      );
    } else {
      let _productUpdate = {
        Quantity: 1,
      };
      HShopUtils.addProductToCart({
        product: data,
        productUpdate: _productUpdate,
      });
      MyModal.Helpers.showMyUI(
        "sc_modal_addcart",
        {
          textSubmit: HText.get("text_modal_btn_go_cart"),
          product: data,
          link: "/cart",
          modalType: "cart",
        },
        {
          title: HText.get("text_modal_title_add_cart"),
          closeButton: true,
          size: "lg",
          className: "mmd-card-product",
        }
      );
    }

    HGA.trackingEventClick({
      action: "click_addcart_product",
      label: data.MaterialName,
    });
  };
  onClickAddWishList = (data) => {
    let _isInWishList = HShopUtils.checkItemInStore({
      store: "wishlist",
      item: data,
      isHaveAttribute: false,
    });
    if (_isInWishList) {
      HShopUtils.removeProductWishList({ product: data });
      HGA.trackingEventClick({
        action: "click_removewish_product",
        label: data.MaterialName,
      });
    } else {
      HShopUtils.addProductToWishList({ product: data });
      MyModal.Helpers.showMyUI(
        "sc_modal_addcart",
        {
          textSubmit: HText.get("text_modal_btn_go_wishlist"),
          product: data,
          link: "/wishlist",
          modalType: "wishlist",
        },
        {
          title: HText.get("text_modal_title_add_wish"),
          closeButton: true,
          size: "lg",
          className: "mmd-card-product",
        }
      );

      HGA.trackingEventClick({
        action: "click_addwish_product",
        label: data.MaterialName,
      });
    }
    this.forceUpdate();
  };
  renderProductPicture = (data) => {
    const { onClick } = this.props;
    const _MaterialName = data.MaterialName;
    const _Slug = data.Slug;
    const _ImageUrlThumnail =
      data.ImageUrlThumnail || HConfig.fnList.getConfig("imageProductDefault");
    return (
      <div className="picture">
        <a
          title={`${HText.get("text_product_title")} ${_MaterialName}`}
          href={_Slug}
          onClick={() => {
            onClick && onClick();
          }}
        >
          <img
            loading="lazy"
            alt={`${HText.get("text_product_title_image")} ${_MaterialName}`}
            title={`${HText.get("text_product_title_image")} ${_MaterialName}`}
            src={_ImageUrlThumnail}
            onClick={() => {
              onClick && onClick();
            }}
          />
        </a>
      </div>
    );
  };
  renderProductDetail = (data) => {
    const { adminButtons } = this.props;
    const _MaterialName = data.MaterialName;
    const _Slug = data.Slug;
    const _Rating = data.Rating;
    const _SellPriceText = data.SellPriceText;
    const _ShortDescription = data.ShortDescription;
    const _IsCall4WebOrder = data.IsCall4WebOrder;
    const _IsActive = data.IsActive;
    const _SellPrice = data.SellPrice;
    const _QuantityMin = HUtils.Obj.get(data, "DiscountInfo.QuantityMin");
    const _DiscountMoney = HUtils.Obj.get(data, "DiscountInfo.DiscountMoney");
    const _PriceAfterDiscount = _SellPrice - _DiscountMoney;
    let _isInWishList = HShopUtils.checkItemInStore({
      store: "wishlist",
      item: data,
      isHaveAttribute: false,
    });
    return (
      <div className="details">
        <h2 className="product-title">
          <a href={_Slug} title={_MaterialName}>
            {_MaterialName}
          </a>
        </h2>
        {/* <div className="product-rating-box" title={_Rating}>
          <div className="rating">
            <div style={{ width: `${_Rating * 20}%` }} />
          </div>
        </div> */}
        <div className="add-info">
          <div className="prices">
            {_QuantityMin <= 1 && (
              <span
                content={_PriceAfterDiscount}
                className="price discount-price"
              >
                {HFormat.Money.formatMoneyForAjuma(_PriceAfterDiscount)}
              </span>
            )}
            <span
              className={`price actual-price${
                _QuantityMin <= 1 ? " origin" : ""
              }`}
              style={_IsCall4WebOrder ? { color: "var(--color-danger)" } : {}}
            >
              {_IsCall4WebOrder ? "Liên hệ" : _SellPriceText}
            </span>
          </div>
          {/* <div className="description">{_ShortDescription}</div> */}
          {/* <div className="buttons-upper">
            {adminButtons && _IsActive !== null && (
              <button
                className={
                  _IsActive === true ? "product-active" : "product-inactive"
                }
              >
                <i className="fa fa-circle" />
              </button>
            )}
            <button
              title={HText.get("text_product_add_wishlist")}
              className={`button-2 add-to-wishlist-button${
                _isInWishList ? " active" : ""
              }`}
              onClick={() => this.onClickAddWishList(data)}
            >
              <i className="fa fa-heart" />
            </button>
          </div> */}
          <div className="buttons-lower">
            {/* {adminButtons && _IsActive !== null && (
              <button
                className={
                  _IsActive === true ? "product-active" : "product-inactive"
                }
              >
                <i className="fa fa-circle" />
              </button>
            )} */}
            <button
              title={HText.get("text_product_add_wishlist")}
              className={`add-to-wishlist-button${
                _isInWishList ? " active" : ""
              }`}
              onClick={() => this.onClickAddWishList(data)}
            >
              <i className="fa fa-heart" />
            </button>
            <div className="ajax-cart-button-wrapper">
              {_IsCall4WebOrder !== true && (
                <button
                  type="button"
                  title={HText.get("text_modal_btn_add_cart")}
                  className="product-box-add-to-cart-button"
                  onClick={() => this.onClickAddCart(data)}
                >
                  <i class="fa fa-shopping-bag"/>
                  <span>{HText.get("text_product_add_cart")}</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    const { data } = this.props;
    if (data) {
      return (
        <div className="product-box">
          <div className="product-item">
            {this.renderProductPicture(data)}
            {this.renderProductDetail(data)}
          </div>
        </div>
      );
    }
    return <div></div>;
  }
}
const mapStateToProps = (state) => {
  if (state.shop && state.shop.adminButtons) {
    return {
      adminButtons: state.shop.adminButtons,
    };
  }
  return {};
};
export default connect(mapStateToProps, null)(SC_CardProduct);
