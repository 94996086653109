import React from 'react'
import HUtils from '../../../helpers/Utils'
import ReactInputRange from '../../../libs/ReactInputRange'
class MyUI_ReactInputRange extends React.Component {    
  constructor(props){
    super(props);
    this.state={
      value: this.props.defaultValue!=null?this.props.defaultValue:0,
      defaultValue: this.props.defaultValue
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.defaultValue !== state.defaultValue) {
      return {
        defaultValue: props.defaultValue,
        value: props.defaultValue!=null?props.defaultValue:0,
      };
    }
    return null;
  }
  render(){        
    //formatLabel,step
    const {minValue,maxValue,step,disabled,onChangeComplete,formatLabel,showDefaultMinMax} = this.props;
    return (
      <ReactInputRange
        // draggableTrack
        disabled={disabled}
        formatLabel={formatLabel}
        showDefaultMinMax={showDefaultMinMax}
        step={step}
        maxValue={maxValue}
        minValue={minValue}
        value={this.state.value}
        onChange={value => this.setState({ value })} 
        onChangeComplete={value => {
          if(onChangeComplete){
            onChangeComplete(value);
          }
        }}
        {...this.props}
      />
    )
  } 
}

export default MyUI_ReactInputRange