import React from 'react'
import {MyUI} from '../../../../Components'

class HomeTopSlide extends React.Component{
    _getConfig=()=>{
        return {
            dots: false,
            arrows: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 10000,
            speed: 2000
        }
    }
    _getData=()=>{
        const {data, config} = this.props;
        if(data && data.length>0){
            return data.map((v,i)=>{
                return {
                    imageUrl: v.ImageUrl,
                    link: v.Link
                }
            })
        }
        return [];
        // return [
        //     {imageUrl: 'https://ajumagarden.com/content/images/thumbs/0011438.png'},
        //     {imageUrl: 'https://ajumagarden.com/content/images/thumbs/0011409.png'},
        // ]
    }
    render(){
        const {data, config} = this.props;
        return(
            <div className="home_topslide">
                <MyUI type="m_list_horizontal" cardType="sc_card_banner" data={this._getData()} config={this._getConfig()}/>
            </div>
        )
    }
}
export default HomeTopSlide