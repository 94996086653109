import React from 'react'
import {MyUI} from '../../../../Components'
import './styles/sc_home.css'
import HGA from '../../../../../helpers/GA'

class HomeBestSeller extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            pageNumber: 1,
            pageSize: 6,
            column: 2,
            row: 3,
            style: {},     
            settings: [
                {width: 1100, column: 3},
                {width: 600, column: 2},
                {width: 0, column: 1}
            ]
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this._resize)
      }
    componentDidMount(){
        this.setWidthItem();
        window.addEventListener('resize', this._resize)
    }
    _resize=()=>{
        this.setWidthItem()
    }
    _getData=(pageNumber)=>{  
        const {data} = this.props;
        const {pageSize} = this.state;
        if(data && data.length>0){
            return data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
        }
        return [];
    }
    _getPageArray=()=>{
        const {data} = this.props;
        const {pageSize} = this.state;
        let _arrTab = [];
        if(data && data.length>0 && pageSize>0){
            let _totalPage = parseInt(data.length / pageSize) +1;
            for(let i=0;i<_totalPage;i++){
                _arrTab.push({
                    data: this._getData(i+1)
                })
            }
        }
        // console.warn("_getPageArray:",_arrTab,data);
        return _arrTab;
    }
    _renderColInTab=(arr)=>{
        const {pageSize, column, row} = this.state;
        let start = 0;
        let end = 0;
        let _ui=[]
        
        if(arr && arr.length>0){
            const _arrSize = arr.length;
            if(_arrSize <= row){
                let _col = [];
                for(let i=0; i<_arrSize; i++){
                    const _item = arr[i]
                    _col.push(
                        <MyUI type="sc_card_product" key={i} data={_item} from="home_best_seller" onClick={()=>{
                            HGA.trackingEventClick({
                                action:'click_item_home_product_of_bestseller',
                                label: _item.MaterialName
                              })
                        }}/>
                    )
                }
                _ui.push(
                    <div className="owl-item active" style={{width: "395px", marginRight: "0px"}}>
                        <div className="three-items-holder" >
                            {_col}
                        </div>
                    </div>
                )
            }
            else if(_arrSize>row && _arrSize<=pageSize){
                for(let i=0; i<column; i++){
                    let _col = [];
                    if(i == 0){
                        start = i;
                        end = row;
                    }
                    else if(end + row > _arrSize){
                        start += row;
                        end += _arrSize;
                    }
                    else{
                        start += row;
                        end += row;
                    }
                    for(let j=start; j<end; j++){
                        const _item  = arr[j]
                        _col.push(
                            <MyUI type="sc_card_product" key={j} data={_item} from="home_best_seller"/>
                        )
                    }
                    _ui.push(
                        <div className="owl-item active" style={{width: "395px", marginRight: "0px"}} key={`col_${i}`}>
                            <div className="three-items-holder" >
                                {_col}
                            </div>
                        </div>
                    )
                }
            }
            else{
                for(let i=0; i<column; i++){
                    let _col = [];
                    if(i == 0){
                        start = i;
                        end = row;
                    }
                    else{
                        start += row;
                        end += row;
                    }
                    for(let j=start; j<end; j++){
                        const _item  = arr[j]
                        _col.push(
                            <MyUI type="sc_card_product" key={j} data={_item} from="home_best_seller"/>
                        )
                    }
                    _ui.push(
                        <div className="owl-item active" style={{width: "395px", marginRight: "0px"}} key={`col_${i}`}>
                            <div className="three-items-holder" >
                                {_col}
                            </div>
                        </div>
                    )
                }
            }
        }
        return _ui
    }
    onPrevious=()=>{
        const {pageNumber, pageSize} = this.state;
        const {data} = this.props;
        let _current = pageNumber;
        let _previous = _current - 1;
        let _maxPage = Math.ceil(data.length/pageSize) || 1
        if(_previous == 0){
            _previous = _maxPage
        }
        this.setState({
            pageNumber: _previous
        },()=>{
            let _stage =document.getElementById("bestseller-stage");
            if(_stage){
                _stage.style.transform = `translate3d(${_previous > 0 ? -this.getWidthItem()*(_previous - 1) : 0}px, 0px, 0px)`
            }
        })
    }
    getWidthItem = () =>{
        const {column} = this.state;
        let _width = 790;
        const _itemArray = document.getElementsByClassName("owl-item active");
        if(_itemArray && _itemArray.length>0){
            _width = _itemArray[0].offsetWidth * column
        }
        return _width;
    }
    setWidthItem = () =>{
        const { settings, row } = this.state;
        let _width = 395;
        const _componentElm = document.getElementsByClassName("owl-stage-outer");
        const _itemWrapper = document.getElementsByClassName("owl-stage-outer");
        const _itemArray = document.getElementsByClassName("owl-item active");
        const _blogHome = document.getElementsByClassName("rich-blog-homepage");
        if(_blogHome.length==0){
            this.setState({style: {width: "100%"}})
        }
        if (
          _componentElm?.length > 0 &&
          _itemWrapper?.length > 0 &&
          _itemArray?.length > 0
        ) {
          let _cpnWidth = _componentElm[0].offsetWidth;
          if (settings) {
            for (let i = 0; i < settings.length; i++) {
              if (_cpnWidth >= settings[i].width) {
                _width = _cpnWidth / settings[i].column;
                this.setState({
                  pageSize: settings[i].column * row,
                  column: settings[i].column,
                });
                break;
              }
            }
          }
        //   console.warn("_width", _width, _cpnWidth);
          for (let i = 0; i < _itemArray.length; i++) {
            _itemArray[i].style.width = `${_width}px`;
          }
        }
    }
    onNext=()=>{
        const {pageNumber, pageSize} = this.state;
        const {data} = this.props;
        let _current = pageNumber;
        let _next = _current + 1;
        let _maxPage = Math.ceil(data.length/pageSize) || 1;
        if(_next > _maxPage){
            _next = 1
        }
        this.setState({
            pageNumber: _next
        },()=>{
            let _stage =document.getElementById("bestseller-stage");
            if(_stage){
                _stage.style.transform = `translate3d(${_next > 0 ? -this.getWidthItem()*(_next - 1) : 0}px, 0px, 0px)`
            }
        })
    }
    render(){
        const {style} = this.state;
        let _arrTab = this._getPageArray();
        return (
          <div className="product-grid bestsellers carousel" style={style}>
            <div className="title">
              <strong>Sản phẩm bán chạy nhất</strong>
            </div>
            <div className="item-grid owl-carousel owl-theme owl-loaded">
              <div className="owl-stage-outer">
                <div
                  id="bestseller-stage"
                  className="owl-stage"
                  style={{
                    transform: "translate3d(0px, 0px, 0px)",
                    transition: " all 0.25s ease 0s",
                    width: "2765px",
                  }}
                >
                  {_arrTab?.length > 0 &&
                    _arrTab.map((tab, index) => {
                      return tab?.data && this._renderColInTab(tab.data);
                    })}
                </div>
              </div>
              <div className="owl-controls">
                <div className="owl-nav">
                  <div
                    className="owl-prev"
                    onClick={() => this.onPrevious()}
                  ></div>
                  <div className="owl-next" onClick={() => this.onNext()}></div>
                </div>
                <div className="owl-dots" style={{ display: "none" }}></div>
              </div>
            </div>
          </div>
        );
    }
}
export default HomeBestSeller