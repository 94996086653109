import React from 'react';
import SC_CategoryTop from 'apps/shoppingCart/components/MyUI/Types/sc_category_top';
import { HText, HConfig, HUtils } from 'apps/Helpers';
import {MyUI} from 'apps/Components';
import './styles/sc_category_top.css'

class SC_CategoryTop_1 extends SC_CategoryTop{
    render(){
        const { sortType, numberInPage, filterPrice, changeNumberInPage, changeTypeSort, onChangeFilterPrice}=this.props;
        const pageSizeOptions = HUtils.Obj.get(HConfig.fnList.getConfig("categoryConfig"), "pageSizeOptions");
        const colorOptions = [
            { Value: "#fff", Text: "White" },
            { Value: "#000", Text: "Black" },
            { Value: "#8b0016", Text: "Noble Red" }
        ]
        const sortOptions = [
            { Value: 1, Text: HText.get("text_order_newest") },
            { Value: 2, Text: HText.get("text_order_price_low_to_high") },
            { Value: 3, Text: HText.get("text_order_price_high_to_low") }
        ]
        console.warn("sortType", sortType)
        return(
            <div className="product-selectors type_1">
                <div className="left">
                    <MyUI 
                        type="sc_filter"
                        defaultValue={filterPrice}
                        fnList={{
                            onFilter: (value)=>{
                                onChangeFilterPrice && onChangeFilterPrice(value)
                        }}} 
                    />
                    <MyUI 
                        type="sc_filter_color"
                        options={colorOptions}
                        // fnList={{
                        //     onFilter: (value)=>{
                        //         this.setState({
                        //             filterPrice: value
                        //         },()=>{
                        //             this.onFilter(value)
                        //         })
                        // }}} 
                    />
                </div>
                <div className="right">
                    <div className="product-sorting">
                        <span>{HText.get("text_filter_order")}</span>
                        <MyUI 
                            type="select2" 
                            id="products-orderby" 
                            name="products-orderby" 
                            className="sortOptionsDropDown" 
                            multi={false}
                            simpleValue
                            clearable={false}
                            value={sortType}
                            options = {sortOptions}
                            onChange={(newValue)=>changeTypeSort(newValue)}
                        />
                    </div>
                    <div className="product-page-size">
                        <span>{HText.get("text_pagination_pre")}</span>
                        <MyUI 
                            type="select2" 
                            id="products-pagesize" 
                            name="products-pagesize" 
                            className="productsPageSizeDropDown"
                            multi={false}
                            simpleValue
                            clearable={false}
                            value={numberInPage}
                            options = {pageSizeOptions}
                            onChange={(newValue)=>changeNumberInPage(newValue)}
                        />
                        <span>{HText.get("text_pagination_suf")}</span>
                    </div>
                </div>
            </div>
        )
    }
}
export default SC_CategoryTop_1