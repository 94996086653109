import * as Routers from './_routes';
import HGA from '../../helpers/GA';

/** Custom Component */
import AppMyUI from './components/MyUI';
import AppMyUIType1 from './components/MyUI/MyUITypes_type1';
import AppMyPage from './components/MyPage';
import AppMyPageType1 from './components/MyPage/MyPageTypes1';
import TheLayout from './containers/TheLayout';//import de khoi tach file 
import AppMyLib from './components/MyLib';

/** Custom store */
import * as CustomStore from './_store';

/** Css */
import './styles.css'

/** Helpers */
import {HText,HLink,HUtils,HAuth,HConfig,HStoreRedux,HLocalStorage} from '../Helpers';
import {SApiAxios} from '../Services';
import {initFacebookSdk} from '../../libs/FacebookLogin'
import BuildVersion from './_version'
import Shop_Language from 'apps/shoppingCart/helpers/Text';

const ConfigBuild = {
  Routers: Routers,
  CustomStore,
  ConfigApp:{
    defaultHomePage: '',
    BaseUrl: 'https://api.nroseclothing.com',
    isUseHash: false,//Ko su dung router Hash,
    listDomainDebug: ['http://dev.nroseclothing.com/'],//Check debug
    facebookAppId: 2606541576307092,
    facebookPageId: 107144590899236,
    linkFB: 'https://www.facebook.com/nroseclothing/',
    googleClientId: "369809599560-sqi4edm2cbp16e3f88pibksqf4v2318j.apps.googleusercontent.com",
    appName: 'nroseclothing',
    GAId: 'UA-64446070-7',
    GAId_Dev: 'UA-156318542-10',
    theme: "theme-nrose",
    metaName: `N'ROSÉ`,
    metaUrl: null,
    uiCopyRightFooter: `Copyright © 2021 N'ROSÉ`,
    uiBrandBio: "Nrosé clothings is the one of your best choices to boost your high fashioned style",
    uiHotline: '0836 333 030',
    uiType: 1,
    tabContact: {
      Title: "Liên hệ - Tư vấn",
      Content: `<div><p>Cảm ơn bạn đã ghé N'ROSÉ. Mọi vấn đề thắc mắc hay cần tư vấn, đừng ngại liên lạc trực tiếp với chúng tôi qua:</p><p>FaceBook: <a href="https://www.facebook.com/nroseclothing/">N'ROSÉ</a></p><p>Website: https://nroseclothing.com</p><p>Email: </p><p>Di động: 0836 333 030</p><p>Liện lạc trực tiếp tại cửa hàng</p><p style="font-size: 16px; font-weight: 500; color: #333">Cửa hàng:</p><p>Địa chỉ</p><li style="margin-left: 1rem">27 Nguyễn Văn Mai, P.8, Q.3, Tp.HCM</li><p style="margin-top: 1rem">Bản đồ</p><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.2734198102676!2d106.68593361535633!3d10.79035846188563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317528cd562fd62f%3A0x4ee7f73176d784!2zMjcgxJDGsOG7nW5nIE5ndXnhu4VuIFbEg24gTWFpLCBQaMaw4budbmcgOCwgUXXhuq1uIDMsIFRow6BuaCBwaOG7kSBI4buTIENow60gTWluaCwgVmlldG5hbQ!5e0!3m2!1sen!2s!4v1623229442370!5m2!1sen!2s" width="600" height="450" style="width: 100%;border:0;" allowfullscreen="" loading="lazy"></iframe></div>`
    },
    imageProductDefault: "https://api.nroseclothing.com/nrose_web.png",
    haveProductOptions: true, //Chọn thuộc tính hàng hóa
    zoomLevelProductPicture: 2,
    socialList: [     
      {
        Icon: "fa fa-facebook-official",
        Link: "https://www.facebook.com/nroseclothing/",
        Style: {fontSize: 18}
      },
      {
        Icon: "fa fa-instagram",
        Link: "https://www.instagram.com/nroseclothing/",
        Style: {fontSize: 20}
      }
    ],
    filterPriceRange: {
      min: 0, 
      max: 5000000,
      step: 10000
    },
    productCartView: 1, // Kiểu hiển thị product trang cart, 0: table, 1: card,
    language: "shop_en",
    categoryConfig: {
      pageSizeOptions: [
        { Value: 12, Text: "12" },
        { Value: 24, Text: "24" }
      ]
    }
  },
  fnList:{
    afterInit(HConfig){
      if(HConfig.fnList.getConfig("uiType") === 1){
        AppMyUIType1.init();
        AppMyPageType1.init();
      }
      else{
        AppMyUI.init(); 
        AppMyPage.init();
      }
      console.warn("RunAfterInit:",Routers);      
      const _cart = HLocalStorage.getObject("cart");
      const _wishlist = HLocalStorage.getObject("wishlist");
      const _recent = HLocalStorage.getObject("recent");
      if(_cart){
        HStoreRedux.dispatch({type: 'setObjInShop', cartProducts: _cart})
      }
      if(_wishlist){
        HStoreRedux.dispatch({type: 'setObjInShop', wishlist: _wishlist})
      }

      /** GA */
      HGA.initialize();
      /** Language */
      HText.addCustomText(Shop_Language.vi, "shop_vi");
      HText.addCustomText(Shop_Language.en, "shop_en");
      //init facebook
      initFacebookSdk();
      if(_recent){
        HStoreRedux.dispatch({type: 'setObjInShop', recent: _recent})
      }
    },
    afterLostSession(){
      console.warn("afterLostSession");
      HAuth.logOut();
    },
    afterLogout(){
      console.warn("afterLogout");
      SApiAxios.updateToken(null);
      HLink.push('/');
      //Khi mat section tu dong logout roi tro ve trang home
    },
    afterLogin(){
      console.warn("afterLogin");
      SApiAxios.updateToken(HAuth.getAuthToken());      
    },
    afterErrorApi(error,opts){
      console.warn("AA:",error,opts);
      let _url = HUtils.Obj.get(opts,"options.options.url") || 'no_url';
      let _errorStatus = HUtils.Obj.get(error,"response.status") || 'no_status';
      let _data = HUtils.Obj.get(opts,"options.data") || {};
      if(_data && typeof _data=='object'){
        let _query = Object.keys(_data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(_data[key])}`).join('&');
        if(_query && typeof _query=="string"){
          _url += '?' + _query;
        }
      }
      let _error_message = HUtils.Obj.get(opts,"error_message") || 'no_message';
      console.warn("LogError:",_url,_error_message,_errorStatus);
      HGA.trackingEventErrorApi({
        apiUrl: _url,
        label: _error_message
      })
    },
  },
}

export default ConfigBuild;