import { HText, HConfig, HLocalStorage, HStoreRedux, HUtils} from '../../Helpers'
const ShopUtils = {
    metaData: {
        MetaTitle: HConfig.fnList.getConfig("metaName"),
        MetaDescription: HConfig.fnList.getConfig("metaDiscription"),
        MetaKeyWords: HConfig.fnList.getConfig("metaKeyWords")
    },
    checkHasValue(value){
      if(value != null && value != undefined){
        return true
      }
      return false
    },
    getDefaultMetaDataByKey({key}={}){
      if(ShopUtils.metaData && ShopUtils.metaData[key]){
          return ShopUtils.metaData[key];
      }
    },
    slugGeneration(value){
      let _value = HText.xoaDauInText(value,{
          hasSpace: true,
          unexpectedRegex: /[^A-Za-z0-9\s]/g
      });
      _value = _value.replace(/\s/g, "-");
      return _value;
    },
    checkAddNewProductToCartBaseOnAttribute({existedProductAttributes, currentProductAttributes, attributes}={}){ // attributes mảng dạng ["Size", "Color"]
      if(attributes){
        for (let i = 0; i < attributes.length; i++) {
          const _field = attributes[i];
          if (existedProductAttributes[_field] != currentProductAttributes[_field]) {
            return true;
          }
        }
      }
      return false;
    },
    checkItemInStore({store, item, isHaveAttribute}={}){
      if(store && item){
        let _list = HLocalStorage.getObject(store, []);
        let _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions");
        if(_haveProductOptions && isHaveAttribute){
          let _item = _list.find((v)=> v.MaterialId == item.MaterialId);
          if(_item?.OrderDetail_AttributeList){
            let _atbProductInstore = _item.OrderDetail_AttributeList;
            let _atbProductNeedCheck = item.OrderDetail_AttributeList;
            let _keys = Object.keys(_atbProductInstore);
            for(let i=0; i<_keys.length; i++){
              let _k = _keys[i];
              if(_atbProductInstore[_k] != _atbProductNeedCheck[_k]){
                return false;
              }
            }
            return true;
          }
        }
        else{
          let _item = _list.find((v)=> v.MaterialId == item.MaterialId);
          if(_item){
            return true;
          }
        }
      }
      return false;
    },
    addProductToCart({product, productUpdate, attributes}={}){ // productUpdate là object gồm Quantity và Attribute
      if(product && productUpdate){
        let _cart = HLocalStorage.getObject("cart", []);
        let _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions");
        if(_haveProductOptions && attributes){
          let _indexs = [];
          let _productSimilarList = _cart.filter((v, i)=>{
            if(v.MaterialId == product.MaterialId){
              _indexs.push(i);
              return v;
            }
          });
          if(_productSimilarList.length>0){
            let _isNew = false;
            let _productSimilar = null;
            let _indexSimilar = null;
            for(let i=0; i<_productSimilarList.length; i++){
              let _itm = _productSimilarList[i];
              _isNew = ShopUtils.checkAddNewProductToCartBaseOnAttribute({
                existedProductAttributes: _itm.OrderDetail_AttributeList,
                currentProductAttributes: productUpdate.OrderDetail_AttributeList,
                attributes: attributes,
              });
              if(_isNew == false){
                _productSimilar = _itm;
                _indexSimilar = i
                break;
              }
            }
            if(_productSimilar){
              let _quantity = _productSimilar.Quantity + productUpdate.Quantity;
              _productSimilar = {..._productSimilar, ...productUpdate};
              _productSimilar.Quantity = _quantity;
              _cart[_indexs[_indexSimilar]] = _productSimilar;
              _cart = [..._cart]; 
            }
            else{
              _cart.unshift({...product, ...productUpdate});
            }
          }
          else{
            _cart.unshift({...product, ...productUpdate});
          }
        }
        else{
          let _existedProductInCart = _cart.find((v)=> v.MaterialId == product.MaterialId);
          if (_existedProductInCart) {
            _existedProductInCart.Quantity += productUpdate.Quantity;
          } else {          
            _cart.unshift({...product, ...productUpdate});
          }
        }
        HLocalStorage.setObject("cart", _cart);
        HStoreRedux.dispatch({
          type: "setObjInShop",
          cartProducts: _cart,
        });
      }
    },
    addProductToWishList({product}={}){
      let _wishlist = HLocalStorage.getObject("wishlist", []);
      if(product){
        let _existedProduct = _wishlist.find((i) => i.MaterialId == product.MaterialId);
        if (!_existedProduct) {
          _wishlist.unshift({ ...product, Quantity: 1 });
          HLocalStorage.setObject("wishlist", _wishlist);
          HStoreRedux.dispatch({ type: "setObjInShop", wishlist: _wishlist });
        }
      }
    },
    removeProductWishList({product}={}){
      let _wishlist = HLocalStorage.getObject("wishlist", []);
      if(product){
        let _index = null;
        let _existedProduct = _wishlist.find((v, i) => {
          if(v.MaterialId == product.MaterialId){
            _index = i;
            return v
          }
        });
        if (_existedProduct) {
          _wishlist.splice(_index, 1);
          HLocalStorage.setObject("wishlist", _wishlist);
          HStoreRedux.dispatch({ type: "setObjInShop", wishlist: _wishlist });
        }
      }
    },
    removeProductInCart({index}={}){
      let _cart = HLocalStorage.getObject("cart", []);
      _cart = _cart.filter((v, i)=>i !== index);
      HLocalStorage.setObject("cart", _cart);
      HStoreRedux.dispatch({type: "setObjInShop", cartProducts: _cart})
    },
    updateProductInCart({product, index}={}){
      let _cart = HLocalStorage.getObject("cart", []);
      const _haveProductOptions = HConfig.fnList.getConfig("haveProductOptions");
      const {OrderDetail_AttributeList} = product;
      if(_haveProductOptions && OrderDetail_AttributeList){ 
        // Merge product giong nhau
        let _productHaveSameIdArray = _cart.filter((v, i)=> (i!==index && v.MaterialId === product.MaterialId));
        if(_productHaveSameIdArray?.length > 0){
          let _productSimilar = null;
          let _keys = Object.keys(OrderDetail_AttributeList);
          for(let j = 0; j<_productHaveSameIdArray.length; j++){
            const _productHaveSameId = _productHaveSameIdArray[j];
            let _isSimilar = true;
            for(let i=0; i<_keys.length; i++){
              let k = _keys[i];
              if(_productHaveSameId.OrderDetail_AttributeList[k] != OrderDetail_AttributeList[k]){
                _isSimilar = false;
              }
            }
            if(_isSimilar){
              _productSimilar = _productHaveSameId;
              break;
            }
          }
          if(_productSimilar){
            _productSimilar.Quantity += product.Quantity;
            _cart.splice(index, 1);
          }
          else{
            _cart[index] = product;
          }
        }
        else{
          _cart[index] = product;
        }
      }
      else{
        _cart[index] = product;
      }
      // console.warn("cart", _cart)
      HLocalStorage.setObject("cart", _cart);
      HStoreRedux.dispatch({type: "setObjInShop", cartProducts: _cart})
    },
    updateProductToWishList({product, index}={}){
      let _wishlist = HLocalStorage.getObject("wishlist", []);
      if(product){
        _wishlist[index] = {...product};
        HLocalStorage.setObject("wishlist", JSON.parse(JSON.stringify(_wishlist)));
        HStoreRedux.dispatch({ type: "setObjInShop", wishlist: JSON.parse(JSON.stringify(_wishlist)) });
      }
    },
    getArrayValueFromArrayObjectByField(array, field){
      let _result = [];
      if(array?.length>0 && field){
        for(let i=0; i<array.length; i++){
            _result.push(array[i][field])
        }
      }
      return _result;
    },
    getArrayDifference (a1, a2) {
      var a = [], diff = [];
      for (var i = 0; i < a1.length; i++) {
          a[a1[i]] = true;
      }
      for (var i = 0; i < a2.length; i++) {
          if (a[a2[i]]) {
              delete a[a2[i]];
          } else {
              a[a2[i]] = true;
          }
      }
      for (var k in a) {
          diff.push(k);
      }
      return diff;
    }
  }
  window.HShopUtils = ShopUtils;
  export default ShopUtils;